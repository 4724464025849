import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};
class CallcenteraddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        queue_name: "Please enter name.",
        queue_strategy: "",
        queue_timeout: "",
        queue_timeout_entry: "",
        queue_enabled: "",
        queue_max_wait_time: "Please enter max wait time.",
      },
      alertMessage: "",
      queue_name: "",
      queue_strategy: "ring-all",
      queue_timeout: "1",
      queue_enabled: true,
      queue_timeout_entry: "hangup:",
      queue_greeting: "0",
      queue_greetingList: [],
      user_name: ["user_1"],
      users: ["0"],
      userPosition: ["0"],
      userPositionName: ["position_1"],
      userList: [],
      timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      queue_max_wait_time: "",
      musicOnHold: "0",
      recordingList: [],
      templateList: [],
      dipsogroupList: [],
      template_uuid: "",
      dispo_group_uuid: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Call Center",
          action: "callcenter",
        },
        {
          label: "Add Call Center Data",
          action: "callcenteradd",
        },
      ],
    };
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        queue_name: this.state.queue_name,
        queue_strategy: this.state.queue_strategy,
        queue_timeout: this.state.queue_timeout,
        queue_timeout_entry: this.state.queue_timeout_entry,
        queue_max_wait_time: this.state.queue_max_wait_time,
        queue_enabled: this.state.queue_enabled,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        domain_name: sessionStorage.getItem("domain_name"),
        users: this.state.users,
        timeOutEntryList: this.state.timeOutEntryList,
        queue_greeting: this.state.queue_greeting,
        userPosition: this.state.userPosition,
        musicOnHold: this.state.musicOnHold,
        template_uuid: this.state.template_uuid,
        dispo_group_uuid: this.state.dispo_group_uuid,
      };
      if (
        this.state.queue_timeout !== "1" &&
        this.state.queue_timeout_entry === "0"
      ) {
        this.setState({ alertMessage: <div>Please select entry.</div> });
      } else {
        let response = await commonPostAPI("addcallcenter", requestparams);
        if (response.queue_name && response.queue_name !== "") {
          this.setState({ alertMessage: <div>Name already exists.</div> });
        } else {
          this.onOpenAction("callcenter");
        }
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  handleChangeMultiple = (e) => {
    const selected = e.target.value;
    this.setState({ users: selected });
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    if (e.target.name === "queue_timeout") {
      this.timeOutActionChange(e);
    }
    let errors = this.state.errors;

    switch (e.target.name) {
      case "queue_name":
        errors.queue_name =
          e.target.value.length < 1 ? "Please enter name." : "";
        break;
      case "queue_strategy":
        errors.queue_strategy =
          e.target.value.length < 1 ? "Please select strategy." : "";
        break;
      case "queue_enabled":
        errors.queue_enabled =
          e.target.value.length < 1 ? "Please select Enabled." : "";
        break;
      case "queue_timeout":
        errors.queue_timeout =
          e.target.value.length < 1 ? "Please select timeout Action." : "";
        break;
      case "queue_timeout_entry":
        errors.queue_timeout_entry =
          e.target.value.length < 1
            ? "Please select timeout action entry."
            : "";
        break;
      case "users":
        errors.users = e.target.value.length < 1 ? "Please select user." : "";
        break;
      case "queue_max_wait_time":
        errors.queue_max_wait_time =
          e.target.value.length < 1 ? "Please enter max wait time." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  timeOutActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({ timeOutEntryList: response, queue_timeout_entry: "0" });
    } else {
      this.setState({
        timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
        queue_timeout_entry: "0",
      });
    }
  };

  componentDidMount = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let response = await commonPostAPI("userslistuuid", {
      domain_uuid: domain_uuid,
    });
    this.setState({ userList: response });

    let responseList = await commonPostAPI("recordinglist", {
      domain_uuid: domain_uuid,
    });
    this.setState({ queue_greetingList: responseList });

    let templateList = await commonPostAPI("templateList", {
      domain_uuid: domain_uuid,
    });
    this.setState({ templateList: templateList });

    let dipsogroupList = await commonPostAPI("dipsogroupList", {
      domain_uuid: domain_uuid,
    });
    this.setState({ dipsogroupList: dipsogroupList });
  };

  onChangeUserOptionAction = async (i, e) => {
    let { users } = this.state;
    let oldUsers = [...users];
    oldUsers[i] = e.target.value;
    this.setState({
      users: oldUsers,
    });
  };

  onChangeUserPositionAction = async (i, e) => {
    let { userPosition } = this.state;
    let oldUserPosition = [...userPosition];
    oldUserPosition[i] = e.target.value;
    this.setState({
      userPosition: oldUserPosition,
    });
  };

  addUserAction = () => {
    let { users, user_name, userPosition, userPositionName } = this.state;
    let userRowCount = users.length;

    let userlabel = `user_${userRowCount}`;

    users.push("0");
    let newUsers = users;
    user_name.push(userlabel);
    let newUser_name = user_name;

    let userPositionlabel = `position_${userRowCount}`;
    let newPositionName = userPositionName;
    newPositionName.push(userPositionlabel);

    let newUserPosition = userPosition;
    newUserPosition.push("0");
    this.setState({
      user_name: newUser_name,
      users: newUsers,
      userPositionName: newPositionName,
      userPosition: newUserPosition,
    });
  };

  removeUserAction = (index) => {
    this.setState({
      users: this.state.users.filter(function (items, idx) {
        return idx !== index;
      }),
      user_name: this.state.user_name.filter(function (items, idx) {
        return idx !== index;
      }),
      userPosition: this.state.userPosition.filter(function (items, idx) {
        return idx !== index;
      }),
      userPositionName: this.state.userPositionName.filter(function (
        items,
        idx
      ) {
        return idx !== index;
      }),
    });
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      queue_name,
      queue_strategy,
      queue_enabled,
      queue_timeout,
      alertMessage,
      user_name,
      users,
      userPosition,
      userPositionName,
      userList,
      queue_timeout_entry,
      timeOutEntryList,
      queue_max_wait_time,
      queue_greeting,
      queue_greetingList,
      musicOnHold,
      templateList,
      dipsogroupList,
      template_uuid,
      dispo_group_uuid,
    } = this.state;

    let userArray = [];
    let index = 0;
    for (var i = 0; i < users.length; i++) {
      index = index + 1;
      let currentIndex = i;
      userArray.push(
        <Grid container xs={12}>
          <Grid item xs={12}>
            <table>
              <tr>
                <td>{index}</td>
                <td>&nbsp;</td>
                <td width={120}>
                  <Select
                    fullWidth
                    labelId={users[i]}
                    value={users[i]}
                    id={user_name[i]}
                    name={user_name[i]}
                    onChange={this.onChangeUserOptionAction.bind(this, i)}
                    size="small"
                    sx={{ height: "40px" }}
                    autoComplete="off"
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {userList.map((item) => {
                      return (
                        <MenuItem
                          key={item.call_center_agent_uuid}
                          value={item.call_center_agent_uuid}
                        >
                          {item.agent_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </td>
                <td>&nbsp;</td>
                <td>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={userPositionName[i]}
                    name={userPositionName[i]}
                    value={userPosition[i]}
                    autoFocus
                    type="number"
                    size="small"
                    autoComplete="off"
                    onChange={this.onChangeUserPositionAction.bind(this, i)}
                    sx={{
                      marginTop: "0",
                      height: "45px",
                      marginBottom: "0",
                    }}
                  />
                </td>
                <td>
                  {i === 0 ? (
                    <Button
                      type="button"
                      fullWidth
                      className="btn btn-primary"
                      sx={{
                        mt: 0,
                        mb: 0,
                        height: "40px",
                        width: "40px",
                        minWidth: "40px",
                        fontSize: "22px",
                      }}
                      size="small"
                      onClick={() => this.addUserAction()}
                    >
                      +
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      fullWidth
                      className="btn btn-outline-white"
                      sx={{
                        mt: 0,
                        mb: 0,
                        height: "40px",
                        width: "40px",
                        minWidth: "40px",
                        fontSize: "22px",
                      }}
                      size="small"
                      onClick={() => this.removeUserAction(currentIndex)}
                    >
                      -
                    </Button>
                  )}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Name</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="queue_name"
                        name="queue_name"
                        value={queue_name ?? ""}
                        onChange={this.onChangeItem}
                        autoFocus
                        type="text"
                        size="small"
                        autoComplete="off"
                      />
                      {errors.queue_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.queue_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Greeting
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="queue_greeting"
                            id="queue_greeting"
                            value={queue_greeting}
                            label="queue_greeting"
                            name="queue_greeting"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {queue_greetingList.map((item) => (
                              <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Strategy
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="queue_strategy"
                            id="queue_strategy"
                            value={queue_strategy}
                            label="queue_strategy"
                            name="queue_strategy"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="ring-all">Ring All</MenuItem>
                            <MenuItem
                              value="longest-idle-agent"
                              selected="selected"
                            >
                              Longest Idle Agent
                            </MenuItem>
                            <MenuItem value="round-robin">Round Robin</MenuItem>
                            <MenuItem value="top-down">Top Down</MenuItem>
                            <MenuItem value="agent-with-least-talk-time">
                              Agent With Least Talk Time
                            </MenuItem>
                            <MenuItem value="agent-with-fewest-calls">
                              Agent With Fewest Calls
                            </MenuItem>
                            <MenuItem value="sequentially-by-agent-order">
                              Sequentially By Agent Order
                            </MenuItem>
                            <MenuItem value="sequentially-by-next-agent-order">
                              Sequentially By Next Agent Order
                            </MenuItem>
                            <MenuItem value="random">Random</MenuItem>
                          </Select>

                          {errors.queue_strategy.length > 0 ? (
                            <Stack className="error-show" spacing={2}>
                              {errors.queue_strategy}
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>

                    </Grid>
                    <Grid item md={3}>
                      <label>Max Wait Time</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="queue_max_wait_time"
                        label=""
                        name="queue_max_wait_time"
                        value={queue_max_wait_time ?? ""}
                        onChange={this.onChangeItem}
                        autoFocus
                        type="number"
                        size="small"
                        autoComplete="off"
                      />
                      {errors.queue_max_wait_time.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.queue_max_wait_time}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>

                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>
                        Timeout Action
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="queue_timeout"
                            id="queue_timeout"
                            value={queue_timeout}
                            label="queue_timeout"
                            name="queue_timeout"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="1">End Call</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
                            <MenuItem value="3">User</MenuItem>
                            <MenuItem value="4">Ringgroup</MenuItem>
                            <MenuItem value="5">IVR Menu</MenuItem>
                            <MenuItem value="6">Callcenter Queue</MenuItem>
                            <MenuItem value="7">Recording</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>

                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Entry
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="queue_timeout_entry"
                            id="queue_timeout_entry"
                            value={queue_timeout_entry}
                            label="queue_timeout_entry"
                            name="queue_timeout_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {timeOutEntryList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          {errors.queue_timeout_entry.length > 0 ? (
                            <Stack className="error-show" spacing={2}>
                              {errors.queue_timeout_entry}
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Music On Hold
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="musicOnHold"
                            id="musicOnHold"
                            value={musicOnHold}
                            label="musicOnHold"
                            name="musicOnHold"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {queue_greetingList.map((item) => (
                              <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Enabled
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="queue_enabled"
                            id="queue_enabled"
                            value={queue_enabled}
                            label="Enabled"
                            name="queue_enabled"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">In Active</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>


                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>
                        CRM Template
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="template_uuid"
                            id="template_uuid"
                            value={template_uuid}
                            label="template_uuid"
                            name="template_uuid"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {templateList.map((item) => (
                              <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Dispo Group
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="dispo_group_uuid"
                            id="dispo_group_uuid"
                            value={dispo_group_uuid}
                            label="dispo_group_uuid"
                            name="dispo_group_uuid"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {dipsogroupList.map((item) => (
                              <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={5}>
                      {userArray}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item xs={4} sx={{ mt: 5 }}>
                      {alertMessage ? (
                        <Stack className="error-show" spacing={2}>
                          {alertMessage}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} justifyContent="flex-end" container>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("callcenter")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withRouter(CallcenteraddData);
