import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};
class DestinationsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        dialplan_name: "Please enter route name.",
        dialplan_enabled: "",
      },
      alertMessage: "",
      dialplan_name: "",
      host: "",
      username: "",
      password: "",
      dialplan_expression: "^(.*)$",
      limit: "",
      outbound_prefix: "",
      dialplan_enabled: true,
      inbound_ip: '',
      route_type: "peer",
      transport: "udp",
      caller_id: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Gateway",
          action: "destinations",
        },
        {
          label: "Add Gateway",
          action: "destinationsadd",
        },
      ],
    };
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        dialplan_name: this.state.dialplan_name,
        dialplan_enabled: this.state.dialplan_enabled,
        host: (this.state.route_type !== 'subscribe') ? this.state.host : "",
        username: (this.state.route_type !== 'peer') ? this.state.username : "",
        password: (this.state.route_type !== 'peer') ? this.state.password : "",
        limit: this.state.limit,
        outbound_prefix: this.state.outbound_prefix,
        dialplan_expression: `^${this.state.outbound_prefix}(.*)$`,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        domain_name: sessionStorage.getItem("domain_name"),
        inbound_ip: this.state.inbound_ip,
        route_type: this.state.route_type,
        transport: this.state.transport,
        caller_id: this.state.caller_id,
        route_extension: "",
      };
      let response = await commonPostAPI("adddistinations", requestparams);
      if (response.dialplan_name && response.dialplan_name !== "") {
        this.setState({ alertMessage: <div>Name already exists.</div> });
      } else {
        this.onOpenAction("destinations");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  handleChangeMultiple = (e) => {
    const selected = e.target.value;
    this.setState({ users: selected });
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;
    switch (e.target.name) {
      case "dialplan_name":
        errors.dialplan_name =
          e.target.value.length < 1 ? "Please enter name." : "";
        break;
      case "dialplan_enabled":
        errors.dialplan_enabled =
          e.target.value.length < 1 ? "Please select status." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  timeOutActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({
        timeOutEntryList: response,
        ring_group_timeout_entry: "0",
      });
    } else {
      this.setState({
        timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
        ring_group_timeout_entry: "0",
      });
    }
  };

  componentDidMount = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let response = await commonPostAPI("userslist", {
      domain_uuid: domain_uuid,
    });
    this.setState({ userList: response });
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      dialplan_name,
      host,
      username,
      password,
      limit,
      outbound_prefix,
      dialplan_enabled,
      alertMessage,
      inbound_ip,
      route_type,
      transport,
      caller_id,
    } = this.state;

    const userStyle = (route_type === 'register') ? '' : 'none';
    const passwordStyle = (route_type === 'peer') ? 'none' : '';
    const hostStyle = (route_type === 'subscribe') ? 'none' : '';
    const transportStyle = (route_type === 'subscribe') ? 'none' : '';

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Route Name</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="dialplan_name"
                        name="dialplan_name"
                        value={dialplan_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                      {errors.dialplan_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.dialplan_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Prefix</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="outbound_prefix"
                        name="outbound_prefix"
                        value={outbound_prefix ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <label>Limit</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="limit"
                        name="limit"
                        value={limit ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="number"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Enabled
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            margin="normal"
                            labelId="dialplan_enabled"
                            id="dialplan_enabled"
                            value={dialplan_enabled}
                            label="Route Enabled"
                            name="dialplan_enabled"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">In Active</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3} >
                      <label>Route Type</label>
                      <Select
                        fullWidth
                        labelId="route_type"
                        id="route_type"
                        value={route_type}
                        label="route_type"
                        name="route_type"
                        onChange={this.onChangeItem}
                        size="small"
                        sx={{ mt: 2 }}
                        autoComplete="off"
                      >
                        <MenuItem value="peer">PEER</MenuItem>
                        <MenuItem value="register">RAGISTER</MenuItem>
                        <MenuItem value="subscribe">SUBSCRIBE</MenuItem>
                      </Select>
                    </Grid>

                    <Grid item md={3} style={{ display: hostStyle }}>
                      <label>Host</label>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="host"
                        name="host"
                        value={host ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} style={{ display: userStyle }}>
                      <label>Username</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="username"
                        name="username"
                        value={username ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} style={{ display: passwordStyle }}>
                      <label>Password</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="password"
                        name="password"
                        value={password ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Inbound IPs</label>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="inbound_ip"
                        name="inbound_ip"
                        value={inbound_ip ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="text"
                        size="small"
                      />
                    </Grid>

                    <Grid item md={3}>
                      <label>Caller ID</label>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="caller_id"
                        name="caller_id"
                        value={caller_id ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="text"
                        size="small"
                      />
                    </Grid>

                    <Grid item md={3} style={{ display: transportStyle }}>
                      <label>Transport</label>
                      <Select
                        fullWidth
                        labelId="transport"
                        id="transport"
                        value={transport}
                        label="transport"
                        name="transport"
                        onChange={this.onChangeItem}
                        size="small"
                        sx={{ mt: 2 }}
                        autoComplete="off"
                      >
                        <MenuItem value="udp">UDP</MenuItem>
                        <MenuItem value="tcp">TCP</MenuItem>
                        <MenuItem value="tls">TLS</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {alertMessage ? (
                      <Stack className="error-show" spacing={2}>
                        {alertMessage}
                      </Stack>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("destinations")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(DestinationsData);
