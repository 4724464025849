import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";


const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  dial_list_uuid: "",
  list_name: "",
  description: "",
  status: "true",
  companginList: [],
};

class CampaignleadeditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        list_name: "Please enter lead name.",
      },
      alertMessage: "",
      list_name: "",
      description: "",
      status: "true",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Lead",
          action: "campaignlead",
        },
        {
          label: "Edit List Data",
          action: "",
        },
      ],
    };
  }

  componentWillMount = async () => {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);

    this.getDataByID(id);
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });

      let requestparams = {
        dial_list_uuid: this.state.dial_list_uuid,
        list_name: this.state.list_name,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        description: this.state.description,
        status: this.state.status,
      };

      let response = await commonPostAPI("editcampaignlist", requestparams);
      if (response.list_name && response.list_name !== "") {
        this.setState({ alertMessage: <div>Name already exists.</div> });
      } else {
        this.onOpenAction("campaignlead");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;

    switch (e.target.name) {
      case "list_name":
        errors.list_name =
          e.target.value.length < 1 ? "Please enter lead name." : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  getDataByID = async (id) => {
    let response = await commonPostAPI("getCampaignleadByID", { id: id });

    let errors = this.state.errors;

    Object.keys(response).forEach(function (key) {
      switch (key) {
        case "list_name":
          errors.list_name =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select lead name.";
          break;
        default:
          break;
      }
    });


    var dbData = {
      dial_list_uuid: response.dial_list_uuid,
      list_name: response.list_name,
      description: response.description,
      status: response.active,
    };

    partialState = dbData;

    this.setState(partialState);
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };


  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();

    let {
      errors,
      alertMessage,
      description,
      list_name,
      status,
    } = this.state;


    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Lead Name</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="list_name"
                        name="list_name"
                        value={list_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                      {errors.list_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.list_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Description</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="description"
                        name="description"
                        value={description ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Status</label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <Select
                        fullWidth
                        labelId="status"
                        id="status"
                        value={status}
                        label="status"
                        name="status"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="true">Active</MenuItem>
                        <MenuItem value="false">In Active</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item xs={4} sx={{ mt: 5 }}>
                      {alertMessage ? (
                        <Stack className="error-show" spacing={2}>
                          {alertMessage}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} justifyContent="flex-end" container>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("campaignlead")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(CampaignleadeditData);
