import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import {
    Grid,
    Container,
    Stack,
    Button,
    Box,
    TextField,
    Select,
    InputLabel,
    MenuItem,
} from '@mui/material';
import { commonPostAPI } from "../../commoncall/commoncall.js";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../../Assets/index.css';
import AG_GRID_LOCALE_EN from '../../language/locale.en.js';
import { Navigate } from "react-router-dom";
import { withRouter } from '../withRouter.js';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Custombreadcrumb from "../Custombreadcrumb.js";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';


var partialState = {};
var gridApiObj;
class Disporeport extends Component {

    constructor(props) {
        super(props);
        let startDate = dayjs(dayjs().startOf('day')).format('YYYY-MM-DD HH:mm:ss');
        let endDate = dayjs(dayjs().endOf('day')).format('YYYY-MM-DD HH:mm:ss');
        this.state = {
            properties: {
                open: false,
                rowModelType: 'serverSide',
                serverSideStoreType: 'full',
                enableBrowserTooltips: true,
            },
            columnDefs: [
                { headerName: 'Sr.No', valueGetter: "node.rowIndex + 1", filter: false, minWidth: 200, editable: false, },
                { field: 'created_date', headerName: 'Date', tooltipField: 'Date', editable: false, minWidth: 200 },
                { field: 'caller_number', headerName: 'Caller', tooltipField: 'Caller', editable: false, minWidth: 200 },
                { field: 'agent_name', headerName: 'Agent', tooltipField: 'Agent', editable: false, minWidth: 200 },
                { field: 'queue_name', headerName: 'Queue', tooltipField: 'Queue', editable: false, minWidth: 200 },
                { field: 'dispo_name', headerName: 'Dispo', tooltipField: 'Dispo', editable: false, minWidth: 200 },
                { field: 'remark', headerName: 'Remark', tooltipField: 'Remark', editable: false, minWidth: 200 },
            ],
            paginationPageSize: 50,
            currentPage: 1,
            name: '',
            hierarchy: [
                {
                    label: "Home",
                    action: "",
                },
                {
                    label: "Dispo",
                    action: "",
                },
            ],
            fromDate: startDate,
            toDate: endDate,
            alertMessage: '',
            queuename: "",
            agentname: "",
            calleridnumber: "",
            queueList: [],
            agentList: [],
        };
    }

    componentDidMount = async () => {
        let pageObj = {
            domain_uuid: sessionStorage.getItem('domain_uuid'),
        };

        let response = await commonPostAPI("queueList", pageObj);
        if (response.length > 0) {
            this.setState({ queueList: response });
        }

        let responseAgent = await commonPostAPI("agentlistall", pageObj);
        if (responseAgent.length > 0) {
            this.setState({ agentList: responseAgent });
        }
    }

    createServerSideDatasource = (server) => {
        return {
            getRows: (params) => {
                var response = server.getData(params.request);
                setTimeout(function () {
                    if (response.success) {
                        params.success({
                            rowData: response.rows,
                            rowCount: response.lastRow,
                        });
                    } else {
                        params.fail();
                    }
                }, 1000);
            },
        };
    }

    createFakeServer = (allData) => {
        return {
            getData: (request) => {
                var rowsForBlock = allData.slice(request.startRow, request.endRow);
                var lastRow = this.getLastRowIndex(request, rowsForBlock);
                return {
                    success: true,
                    rows: rowsForBlock,
                    lastRow: lastRow,
                };
            },
        };
    }

    getLastRowIndex = (request, results) => {
        if (!results) return undefined;
        var currentLastRow = (request.startRow || 0) + results.length;
        return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
    }

    onGridReady = async (params) => {
        gridApiObj = params.api;
    }

    getRecord = async (e) => {
        e.preventDefault();
        if (this.state.fromDate !== "" && this.state.toDate !== "") {
            let pageObj = {
                currentPage: 1,
                paginationPageSize: this.state.paginationPageSize,
                fromDate: dayjs(this.state.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                toDate: dayjs(this.state.toDate).format('YYYY-MM-DD HH:mm:ss'),
                queuename: this.state.queuename,
                calleridnumber: this.state.calleridnumber,
                domain_uuid: sessionStorage.getItem('domain_uuid'),
                agentname: this.state.agentname,
            };
            this.rowSelectionObj = {};
            let response = await commonPostAPI("getDispoReport", pageObj);
            const updateData = (data) => {
                var fakeServer = this.createFakeServer(data);
                var datasource = this.createServerSideDatasource(fakeServer);
                gridApiObj.setServerSideDatasource(datasource);
            };
            updateData(response);
        } else {
            this.setState({ alertMessage: "Please select From Date and To Date" });
            return false;
        }
    };

    onOpenAction = (action) => {
        this.props.navigate(`/${action}`);
    }

    onChangeItem = (e) => {
        e.preventDefault();
        partialState[e.target.name] = e.target.value;
        this.setState(partialState);
    }

    onChangeDateItem = (name, value) => {
        let data = new Date(value).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric' })
        partialState[name] = data;
        partialState['alertMessage'] = "";
        this.setState(partialState);
    }

    reSet = () => {
        var partialState = {
            fromDate: '',
            toDate: '',
            queuename: '',
            agentname: '',
            calleridnumber: '',
            alertMessage: '',
        };
        this.setState(partialState);
    }

    render() {
        let gridOptions = {
            defaultColDef: {
                flex: 1,
                sortable: true,
                resizable: true,
                autoHeight: false,
                minWidth: '170'
            },
            localeText: AG_GRID_LOCALE_EN,
            cacheBlockSize: this.state.paginationPageSize,
            rowBuffer: 0,
            serverSideStoreType: 'partial',
            maxBlocksInCache: 2,
            pagination: true,
            onGridReady: this.onGridReady,
        };

        let rowClassRules = {
            'rowOdd': function (params) { return (params.rowIndex % 2 === 0); },
            'rowEven': function (params) { return (params.rowIndex % 2 === 1); }
        };

        if (sessionStorage.length === 0) {
            return (<Navigate to="/" replace={true} />);
        }

        return (
            <div className="ag-theme-material gridProperties">
                <box>
                    <Container
                        maxWidth="xl"
                        sx={{ marginTop: 2 }}
                        className="breadcrumbs-section"
                    >
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Custombreadcrumb hierarchy={this.state.hierarchy} />
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-search"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>{" "}
                                        &nbsp;&nbsp;
                                        <span>Search</span>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            borderTop: "1px solid #ddd",
                                        }}
                                    >
                                        <Box
                                            className="formBox"
                                            component="form"
                                            sx={{
                                                boxShadow: "none",
                                                padding: "0 5px",
                                                marginTop: "0",
                                            }}
                                            onSubmit={this.getRecord}
                                        >
                                            <Grid container xs={12} spacing={2} >
                                                <Grid item md={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer
                                                            components={[
                                                                'DesktopDateTimePicker',
                                                            ]}
                                                            sx={{
                                                                width: '100%',
                                                                mt: 0
                                                            }}
                                                        >
                                                            <DesktopDateTimePicker
                                                                label="From Date"
                                                                name="fromDate"
                                                                id="fromDate"
                                                                formatDensity="-"
                                                                ampm={false}
                                                                onChange={(newValue) => {
                                                                    this.onChangeDateItem("fromDate", newValue);
                                                                }}
                                                                format="YYYY-MM-DD HH:mm:ss"
                                                                timeSteps={
                                                                    {
                                                                        'minutes': '1',
                                                                        'seconds': '1'
                                                                    }
                                                                }
                                                                slotProps={
                                                                    {
                                                                        actionBar: {
                                                                            actions: ["today", "accept", "cancel"]
                                                                        }
                                                                    }}
                                                                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                                                size="small"
                                                                value={dayjs(this.state.fromDate)}
                                                                className="iconBgcolor"
                                                                sx={{
                                                                    width: '95%',
                                                                }}
                                                            />
                                                        </DemoContainer>

                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer
                                                            components={[
                                                                'DesktopDateTimePicker',
                                                            ]}
                                                            sx={{
                                                                width: '100%',
                                                                mt: 0
                                                            }}
                                                        >
                                                            <DesktopDateTimePicker
                                                                label="To Date"
                                                                name="toDate"
                                                                id="toDate"
                                                                formatDensity="-"
                                                                ampm={false}
                                                                onChange={(newValue) => {
                                                                    this.onChangeDateItem("toDate", newValue);
                                                                }}
                                                                format="YYYY-MM-DD HH:mm:ss"
                                                                timeSteps={
                                                                    {
                                                                        'minutes': '1',
                                                                        'seconds': '1'
                                                                    }
                                                                }
                                                                slotProps={
                                                                    {
                                                                        actionBar: {
                                                                            actions: ["today", "accept", "cancel"]
                                                                        }
                                                                    }}
                                                                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                                                size="small"
                                                                value={dayjs(this.state.toDate)}
                                                                className="iconBgcolor"
                                                                sx={{
                                                                    width: '95%',
                                                                }}
                                                            />
                                                        </DemoContainer>

                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid container xs={12} spacing={2}>
                                                <Grid item md={4}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        id="calleridnumber"
                                                        label="Caller ID Number"
                                                        name="calleridnumber"
                                                        value={this.state.calleridnumber}
                                                        onChange={this.onChangeItem}
                                                        autoComplete="off"
                                                        type="text"
                                                        size="small"

                                                    />
                                                </Grid>
                                                <Grid item md={4}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Queue Name
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="queuename"
                                                        id="queuename"
                                                        value={this.state.queuename}
                                                        label="queuename"
                                                        name="queuename"
                                                        onChange={this.onChangeItem}
                                                        size="small"
                                                        autoComplete="off"
                                                    >
                                                        <MenuItem value="">Select</MenuItem>
                                                        {this.state.queueList.map((item) => (
                                                            <MenuItem value={item.call_center_queue_uuid}>{item.queue_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Agent Name
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="agentname"
                                                        id="agentname"
                                                        value={this.state.agentname}
                                                        label="agentname"
                                                        name="agentname"
                                                        onChange={this.onChangeItem}
                                                        size="small"
                                                        autoComplete="off"
                                                    >
                                                        <MenuItem value="">Select</MenuItem>
                                                        {this.state.agentList.map((item) => (
                                                            <MenuItem value={item.cc_agent_uuid}>{item.agent_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container xs={12} spacing={2} >
                                                <Grid md={12}
                                                    className="btnSections"
                                                    justifyContent="center"
                                                    display="flex"
                                                    alignItems="center"
                                                    sx={{
                                                        gap: "20px",
                                                        padding: "10px 15px",
                                                    }}
                                                >
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        className="btn btn-primary"
                                                        sx={{
                                                            mt: 1,
                                                            mb: 1,
                                                            height: "35px",
                                                            padding: "5px 20px",
                                                            width: "100px",
                                                        }}
                                                        size="small"
                                                    >
                                                        Search
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        fullWidth
                                                        className="btn btn-outline-white"
                                                        sx={{
                                                            mt: 1,
                                                            mb: 1,
                                                            height: "35px",
                                                            width: "100px",
                                                            padding: "5px 20px",
                                                        }}
                                                        size="small"
                                                        onClick={this.reSet}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            {this.state.alertMessage ? (
                                                <Grid>
                                                    <Stack
                                                        className="error-show"
                                                        sx={{ width: "100%" }}
                                                        spacing={2}
                                                    >
                                                        {this.state.alertMessage}
                                                    </Stack>
                                                </Grid>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Container>
                </box>
                <AgGridReact
                    className="table-structure"
                    gridOptions={gridOptions}
                    rowModelType="serverSide"
                    serverSideStoreType="full"
                    rowClassRules={rowClassRules}
                    suppressRowClickSelection={true}
                    suppressHorizontalScroll={true}
                    alwaysShowVerticalScroll={true}
                    columnDefs={this.state.columnDefs}
                />
            </div>

        );
    }
}

export default withRouter(Disporeport);
