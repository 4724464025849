import React, { useState, useEffect } from "react";
import socketIO from "socket.io-client";
import MuiAppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  List,
  CssBaseline,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  styled,
  useTheme,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiDrawer from "@mui/material/Drawer";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AppsIcon from "@mui/icons-material/Apps";
import GroupIcon from "@mui/icons-material/Group";
import CallIcon from "@mui/icons-material/Call";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import NearMeIcon from "@mui/icons-material/NearMe";
import DialpadIcon from "@mui/icons-material/Dialpad";
import MonitorIcon from "@mui/icons-material/Monitor";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import logoIcon from "../Assets/bgimage/logo.png";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { commonPostAPI } from "../commoncall/commoncall";
import AppConfig from "../Constants/AppConfig";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import Groups3Icon from '@mui/icons-material/Groups3';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ExtensionIcon from '@mui/icons-material/Extension';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ChatIcon from "@mui/icons-material/Chat";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CampaignIcon from '@mui/icons-material/Campaign';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GridOnIcon from '@mui/icons-material/GridOn';
import RateReviewIcon from '@mui/icons-material/RateReview';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  setUnreadMessageCount,
  setUnreadMessageCountData,
  useUserChatController,
} from "./Context/UserChatContext";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DomainIcon from '@mui/icons-material/Domain';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LayersIcon from '@mui/icons-material/Layers';

const drawerWidth = 240;

export default function MenubarFun(props) {
  let INITIAL_STATE = {
    profileflag: false,
    open: false,
    application: false,
    report: false,
    userpresencepopup: false,
    campaign: false,
    billing: false,
    userFlag: false,
  };

  const [menuDetails, setmenuDetails] = useState(INITIAL_STATE);
  const user_presence = sessionStorage.getItem("user_presence");
  const [currentuser, setCurrentuser] = useState(user_presence);
  const [showDropDownIcon, setShowDropDownIcon] = useState(false);
  const [showBadge, setShowBadge] = useState(true);
  const [socket, setSocket] = useState(null);

  const LoggedInUserId = sessionStorage.getItem("user_uuid");
  const LoggedInUserName = sessionStorage.getItem("displayname");

  const [controller, dispatch] = useUserChatController();
  const { userTotalUnreadMsgCount } = controller;

  useEffect(() => {
    if (LoggedInUserId !== undefined) {
      const sessionID = sessionStorage.getItem("token") ?? "";
      const newSocket = socketIO.connect(AppConfig.webSocketUrl, {
        auth: {
          sessionID,
          username: LoggedInUserName,
          userID: LoggedInUserId,
        },
        extraHeaders: {
          "react-client": "react-client",
        },
      });
      setSocket(newSocket);

      return () => newSocket.close();
    }
  }, []);

  let colorCode = "";
  if (user_presence === "away") {
    colorCode = "#FFb700";
  } else if (user_presence === "dnd") {
    colorCode = "#FF0000";
  } else if (user_presence === "offline") {
    colorCode = "#808080";
  } else {
    colorCode = "#44b700";
  }

  const [currentusercolor, setCurrentusercolor] = useState(colorCode);

  const navigate = useNavigate();
  const theme = useTheme();
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: currentusercolor,
      color: currentusercolor,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const StyledDropDownBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      // backgroundColor: currentusercolor,
      color: currentusercolor,
      border: "1px solid currentColor",
      padding: 0,
      borderRadius: "50%",
      minWidth: "15px",
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    "& .MuiListItemText-primary": { fontSize: "12px" },
  }));

  const onLogout = async () => {
    await commonPostAPI("setuserloginstatus", { "status": 'Logged Out', 'user_uuid': sessionStorage.getItem("user_uuid") });
    sessionStorage.clear();
    navigate("/");
  };

  const handleDrawerEvent = () => {
    INITIAL_STATE = {
      profileflag: false,
      open: !menuDetails.open,
      application: false,
      report: false,
      userpresencepopup: false,
      campaign: false,
      billing: false,
      userFlag: false,
    };

    setmenuDetails(INITIAL_STATE);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleApplicationClick = () => {
    INITIAL_STATE = {
      profileflag: false,
      open: menuDetails.open,
      application: !menuDetails.application,
      report: false,
      userpresencepopup: false,
      campaign: false,
      billing: false,
      userFlag: false,
    };
    setmenuDetails(INITIAL_STATE);
  };

  const handleReportClick = () => {
    INITIAL_STATE = {
      profileflag: false,
      open: menuDetails.open,
      application: false,
      report: !menuDetails.report,
      userpresencepopup: false,
      campaign: false,
      billing: false,
      userFlag: false,
    };
    setmenuDetails(INITIAL_STATE);
  };

  const handleCampaignClick = () => {
    INITIAL_STATE = {
      profileflag: false,
      open: menuDetails.open,
      application: false,
      report: false,
      userpresencepopup: false,
      campaign: !menuDetails.campaign,
      billing: false,
      userFlag: false,
    };
    setmenuDetails(INITIAL_STATE);
  };

  const onOpenAction = (action) => {
    setUnreadMessageCount(dispatch, 0);
    navigate(`/${action}`);
  };

  const openProfileMenu = () => {
    INITIAL_STATE = {
      profileflag: !menuDetails.profileflag,
      open: menuDetails.open,
      application: menuDetails.application,
      report: menuDetails.report,
      userpresencepopup: false,
      campaign: false,
      billing: false,
      userFlag: false,
    };
    setmenuDetails(INITIAL_STATE);
  };

  const setIsShown = () => {
    INITIAL_STATE = {
      profileflag: menuDetails.profileflag,
      open: menuDetails.open,
      application: menuDetails.application,
      report: menuDetails.report,
      userpresencepopup: !menuDetails.userpresencepopup,
      campaign: false,
      billing: false,
      userFlag: false,
    };
    setmenuDetails(INITIAL_STATE);

    // if (menuDetails.userpresencepopup) {
    setShowBadge(true);
    setShowDropDownIcon(false);
    // }
  };

  const hideShowBadge = (badgeStatus) => {
    setShowBadge(badgeStatus);
    setShowDropDownIcon(!badgeStatus);
  };

  const setUser = async (userstatus, color) => {
    const objData = {
      status: userstatus,
      color: color,
      user_uuid: sessionStorage.getItem("user_uuid"),
    };
    const requestparams = {
      user_presence: userstatus,
      user_uuid: sessionStorage.getItem("user_uuid"),
    };
    await commonPostAPI("setuserpresence", requestparams);
    socket.emit("userStatusChange", objData);

    setmenuDetails({ ...INITIAL_STATE, userpresencepopup: false });
    setShowBadge(!showDropDownIcon);
    setShowDropDownIcon(showDropDownIcon);

    return true;
  };

  const handleBillingClick = () => {
    INITIAL_STATE = {
      profileflag: false,
      open: menuDetails.open,
      application: false,
      report: false,
      userpresencepopup: false,
      campaign: false,
      billing: !menuDetails.billing,
      userFlag: false,
    };
    setmenuDetails(INITIAL_STATE);
  };

  const handleUserClick = () => {
    INITIAL_STATE = {
      profileflag: false,
      open: menuDetails.open,
      application: false,
      report: false,
      userpresencepopup: false,
      campaign: false,
      billing: false,
      userFlag: !menuDetails.userFlag,
    };
    setmenuDetails(INITIAL_STATE);
  };


  useEffect(() => {
    if (socket !== null) {
      socket.on("userStatusUpdated", (args) => {
        if (args.user_uuid === sessionStorage.getItem("user_uuid")) {
          setCurrentuser(args.status);
          setCurrentusercolor(args.color);
          sessionStorage.setItem("user_presence", args.status);
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket !== null) {
      socket.emit("new-message-count", { userID: LoggedInUserId });
    }
  }, [socket]);

  useEffect(() => {
    if (socket !== null) {
      socket.on("new-message-count", (res) => {
        let count = 0;
        res.forEach((data) => {
          count += parseInt(data.unread_message_count);
        });
        setUnreadMessageCount(dispatch, count);
        setUnreadMessageCountData(dispatch, res);
      });
    }
  }, [socket]);

  return (
    <Box
      sx={{ display: "flex" }}
      style={{ height: "100%", fontWeight: "normal" }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={menuDetails.open} variant="dense">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerEvent}
            edge="start"
            sx={{
              marginRight: 5,
              ...(menuDetails.open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ flexGrow: 0 }}
            style={{ textAlign: "right", width: "98%" }}
          >
            <Tooltip>
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    bgcolor: "#ddd",
                    color: "#212b36",
                    fontWeight: "600",
                  }}
                  src="profile.jpeg"
                  onClick={() => openProfileMenu()}
                >
                  {sessionStorage
                    .getItem("displayname")
                    .substring(0, 1)
                    .toUpperCase()}
                </Avatar>
                {/* {showBadge ? (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    onMouseEnter={() => hideShowBadge(false)}
                  >
                    &nbsp;
                  </StyledBadge>
                ) : (
                  ""
                )} */}
                {/* {showDropDownIcon ? (
                  <StyledDropDownBadge
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    onClick={() => setIsShown()}
                    badgeContent={<ArrowDropDownIcon fontSize="small" />}
                    onMouseLeave={() => hideShowBadge(true)}
                  >
                    &nbsp;
                  </StyledDropDownBadge>
                ) : (
                  ""
                )} */}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "50px" }}
              id="menu-appbar"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuDetails.profileflag)}
              onClose={() => openProfileMenu()}
            >
              <MenuItem key="logout">
                <Typography
                  textAlign="center"
                  onClick={() => onLogout()}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#212b36"
                    class="bi bi-box-arrow-in-right align-middle"
                    viewBox="0 0 16 16"
                    sx={{ fill: "#212b36 !important" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                    />
                  </svg>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>

            <Menu
              sx={{ mt: "50px" }}
              id="menu-appbar"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuDetails.userpresencepopup)}
              onClose={() => setIsShown()}
            >
              <MenuItem
                key="online"
                onClick={() => setUser("availeble", "#44b700")}
                sx={{ height: 30, width: 160 }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: "14px",
                    fontWeight: currentuser === "availeble" ? "bold" : "",
                  }}
                >
                  <CheckBoxOutlineBlankIcon
                    sx={{ bgcolor: "#44b700", height: 18, width: 18 }}
                  />
                  Availeble
                </Typography>
              </MenuItem>
              <MenuItem
                key="away"
                onClick={() => setUser("away", "#FFb700")}
                sx={{ height: 30, width: 160 }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: "14px",
                    fontWeight: currentuser === "away" ? "bold" : "",
                  }}
                >
                  <CheckBoxOutlineBlankIcon
                    sx={{ bgcolor: "#FFb700", height: 18, width: 18 }}
                  />
                  Away
                </Typography>
              </MenuItem>
              <MenuItem
                key="dnd"
                onClick={() => setUser("dnd", "#FF0000")}
                sx={{ height: 30, width: 160 }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: "14px",
                    fontWeight: currentuser === "dnd" ? "bold" : "",
                  }}
                >
                  <CheckBoxOutlineBlankIcon
                    sx={{ bgcolor: "#FF0000", height: 18, width: 18 }}
                  />
                  Do not distrub
                </Typography>
              </MenuItem>
              <MenuItem
                key="offline"
                onClick={() => setUser("offline", "#808080")}
                sx={{ height: 30, width: 160 }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: "14px",
                    fontWeight: currentuser === "offline" ? "bold" : "",
                  }}
                >
                  <CheckBoxOutlineBlankIcon
                    sx={{ bgcolor: "#808080", height: 18, width: 18 }}
                  />
                  Offline
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={menuDetails.open}>
        <DrawerHeader>
          {/* LOGO ICON :: BEGIN */}
          <Box
            sx={{
              color: "#e0dcfe",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "flex-start",
            }}
            onClick={() => onOpenAction("dashboard")}
          >
            <img src={logoIcon} alt="PBX" width="30" /> PBX
          </Box>
          {/* LOGO ICON :: END */}
          <IconButton onClick={handleDrawerEvent}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {((sessionStorage.getItem('DOMAINS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "50px" }}>
                <DomainIcon onClick={() => onOpenAction("domains")} />
              </ListItemIcon>
              <ListItemText primary="Domains" onClick={() => onOpenAction("domains")} />
            </ListItemButton> : ""}

          <ListItemButton onClick={handleUserClick}>
            <ListItemIcon sx={{ minWidth: "50px" }}>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
            {menuDetails.userFlag ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={menuDetails.userFlag} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {((sessionStorage.getItem('DEPARTMENT') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <LayersIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("departments")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Department"
                    onClick={() => onOpenAction("departments")}
                  ></CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('USER') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <GroupIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("user")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Users"
                    onClick={() => onOpenAction("user")}
                  >
                    Users
                  </CustomListItemText>
                </ListItemButton> : ""}
            </List>
          </Collapse>

          <ListItemButton onClick={handleApplicationClick}>
            <ListItemIcon sx={{ minWidth: "50px" }}>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="Applications" />
            {menuDetails.application ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={menuDetails.application} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {((sessionStorage.getItem('RING_GROUPS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <GroupIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("ringgroup")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Ring Groups"
                    onClick={() => onOpenAction("ringgroup")}
                  >
                    Ring Groups
                  </CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('CALL_CENTER') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <CallIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("callcenter")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Call Center"
                    onClick={() => onOpenAction("callcenter")}
                  >
                    Call Center
                  </CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('IVR_MENUS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <MenuBookIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("ivrmenu")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="IVR Menus"
                    onClick={() => onOpenAction("ivrmenu")}
                  >
                    IVR Menus
                  </CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('GREETING') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <RecordVoiceOverIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("recording")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Greeting"
                    onClick={() => onOpenAction("recording")}
                  >
                    Greeting
                  </CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('GATEWAY') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <NearMeIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("destinations")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Gateway"
                    onClick={() => onOpenAction("destinations")}
                  >
                    Gateway
                  </CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('DID') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <DialpadIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("did")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="DID"
                    onClick={() => onOpenAction("did")}
                  >
                    DID
                  </CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('RATE_PLAN') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <NextPlanIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("rateplan")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Rate Plan"
                    onClick={() => onOpenAction("rateplan")}
                  >
                    Rate Plan
                  </CustomListItemText>
                </ListItemButton> : ""}
            </List>
          </Collapse>

          {(AppConfig.VOICEMAIL_ENABLE === true && ((sessionStorage.getItem('VOICEMAIL') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION)) ? (
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "50px" }}>
                <VoicemailIcon onClick={() => onOpenAction("voicemail")} />
              </ListItemIcon>
              <ListItemText
                primary="Voicemail"
                onClick={() => onOpenAction("voicemail")}
              />
            </ListItemButton>
          ) : ""}

          {((sessionStorage.getItem('TIME_ROUTING') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "50px" }}>
                <MoreTimeIcon onClick={() => onOpenAction("timing")} />
              </ListItemIcon>
              <ListItemText primary="Time Routing" onClick={() => onOpenAction("timing")} />
            </ListItemButton> : ""}

          {(AppConfig.CONFERENCE_ENABLE === true && ((sessionStorage.getItem('CONFERENCE') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION)) ? (
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "50px" }}>
                <Groups3Icon onClick={() => onOpenAction("conference")} />
              </ListItemIcon>
              <ListItemText primary="Conference" onClick={() => onOpenAction("conference")} />
            </ListItemButton>
          ) : ""}

          {((sessionStorage.getItem('MONITORING') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
            <ListItemButton onClick={() => onOpenAction("callcentercall")}>
              <ListItemIcon sx={{ minWidth: "50px" }}>
                <MonitorIcon />
              </ListItemIcon>
              <ListItemText
                primary="Monitoring"
                onClick={() => onOpenAction("callcentercall")}
              />
            </ListItemButton> : ""}

          {/*
          <ListItemButton onClick={handleCampaignClick}>
            <ListItemIcon sx={{ minWidth: "50px" }}>
              <CampaignIcon />
            </ListItemIcon>
            <ListItemText primary="Campaign" />
            {menuDetails.campaign ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={menuDetails.campaign} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 5 }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <ReceiptLongIcon
                    sx={{ width: "0.8em" }}
                    onClick={() => onOpenAction("campaignlist")}
                  />
                </ListItemIcon>
                <CustomListItemText
                  primary="Campaign"
                  onClick={() => onOpenAction("campaignlist")}
                >
                  Campaign
                </CustomListItemText>
              </ListItemButton>
              {AppConfig.PBX_ONLY !== true ? (
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <GridOnIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("campaignlist")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="List"
                    onClick={() => onOpenAction("campaignlead")}
                  >
                    List
                  </CustomListItemText>
                </ListItemButton>) : ""}
              {AppConfig.PBX_ONLY !== true ? (
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <InsertEmoticonIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("dispo")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Dispo"
                    onClick={() => onOpenAction("dispo")}
                  >
                  </CustomListItemText>
                </ListItemButton>
              ) : (
                ""
              )}

              {AppConfig.PBX_ONLY !== true ? (
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <InsertEmoticonIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("dispo")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Dispo Group"
                    onClick={() => onOpenAction("dispogroup")}
                  />
                </ListItemButton>
              ) : (
                ""
              )}

            </List>
          </Collapse> 

          {AppConfig.PBX_ONLY !== true ? (
            <ListItemButton onClick={() => onOpenAction("crmtemplate")}>
              <ListItemIcon sx={{ minWidth: "50px" }}>
                <ViewQuiltIcon />
              </ListItemIcon>
              <ListItemText
                primary="CRM Template"
                onClick={() => onOpenAction("crmtemplate")}
              />
            </ListItemButton>)
            :
            ""}

          {AppConfig.PBX_ONLY !== true ? (
            <ListItemButton onClick={() => onOpenAction("reviewlist")}>
              <ListItemIcon sx={{ minWidth: "50px" }}>
                <ReviewsIcon />
              </ListItemIcon>
              <ListItemText
                primary="CRM Data"
                onClick={() => onOpenAction("reviewlist")}
              />
            </ListItemButton>) : ""}
            */}

          <ListItemButton onClick={handleBillingClick}>
            <ListItemIcon sx={{ minWidth: "50px" }}>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Billing" />
            {menuDetails.billing ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={menuDetails.billing} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {((sessionStorage.getItem('BILLING_INFO') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <AccountBalanceWalletIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("billings")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Billing info"
                    onClick={() => onOpenAction("billings")}
                  >
                    Billing info
                  </CustomListItemText>
                </ListItemButton> : ""}
              {((sessionStorage.getItem('TRANSACTIONS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <ReceiptIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("transaction")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Transactions"
                    onClick={() => onOpenAction("transaction")}
                  >
                    Transactions
                  </CustomListItemText>
                </ListItemButton> : ""}
            </List>
          </Collapse>

          <ListItemButton onClick={handleReportClick}>
            <ListItemIcon sx={{ minWidth: "50px" }}>
              <FormatListNumberedRtlIcon />
            </ListItemIcon>
            <ListItemText primary="Report" />
            {menuDetails.report ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={menuDetails.report} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {((sessionStorage.getItem('CALLS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <ContactPhoneIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("calls")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Calls"
                    onClick={() => onOpenAction("calls")}
                  >
                    Calls
                  </CustomListItemText>
                </ListItemButton> : ""}

              {((sessionStorage.getItem('QUEUE_CALLS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <LinearScaleIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("queuecalls")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Queue Calls"
                    onClick={() => onOpenAction("queuecalls")}
                  >
                    Queue Calls
                  </CustomListItemText>
                </ListItemButton> : ""}

              {((sessionStorage.getItem('TIME_STATISTICS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <AccessTimeIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("timestatistics")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Time Statistics"
                    onClick={() => onOpenAction("timestatistics")}
                  >
                    Time Statistics
                  </CustomListItemText>
                </ListItemButton> : ""}

              {((sessionStorage.getItem('QUEUE_STATISTICS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <AnalyticsIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("queuestatistics")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Queue Statistics"
                    onClick={() => onOpenAction("queuestatistics")}
                  >
                    Queue Statistics
                  </CustomListItemText>
                </ListItemButton> : ""}

              {((sessionStorage.getItem('AGENT_STATISTICS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SupportAgentIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("agentstatistics")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Agent Statistics"
                    onClick={() => onOpenAction("agentstatistics")}
                  >
                    Agent Statistics
                  </CustomListItemText>
                </ListItemButton> : ""}

              {((sessionStorage.getItem('EXTENSION_STATISTICS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <ExtensionIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("extensionstatistics")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Extension Statistics"
                    onClick={() => onOpenAction("extensionstatistics")}
                  >
                    Extension Statistics
                  </CustomListItemText>
                </ListItemButton> : ""}

              {/*<ListItemButton sx={{ pl: 5 }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <RateReviewIcon
                    sx={{ width: "0.8em" }}
                    onClick={() => onOpenAction("disporeport")}
                  />
                </ListItemIcon>
                <CustomListItemText
                  primary="Dispo"
                  onClick={() => onOpenAction("disporeport")}
                >
                  Dispo
                </CustomListItemText>
              </ListItemButton>*/}

              {((sessionStorage.getItem('AGENT_ACTION_LOG') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <PendingActionsIcon
                      sx={{ width: "0.8em" }}
                      onClick={() => onOpenAction("agentaccessaction")}
                    />
                  </ListItemIcon>
                  <CustomListItemText
                    primary="Agent Action Log"
                    onClick={() => onOpenAction("agentaccessaction")}
                  >
                    Agent Action Log
                  </CustomListItemText>
                </ListItemButton> : ""}

            </List>
          </Collapse>

          {/* <ListItemButton onClick={() => onOpenAction("chat")}>
            <ListItemIcon sx={{ minWidth: "50px" }}>
              <Badge badgeContent={userTotalUnreadMsgCount} color="primary">
                <ChatIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Chat" onClick={() => onOpenAction("chat")} />
          </ListItemButton> */}
        </List>
      </Drawer>
      <Box
        component="main"
        style={{ height: "100%" }}
        sx={{ flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box >
  );
}
