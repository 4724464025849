import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import { Button, Grid } from "@mui/material";
import Custombreadcrumb from "../Custombreadcrumb";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  rateplan_uuid: "",
  rateplanname: "",
};

class RateplaneditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        rateplanname: "Please enter name.",
      },
      alertMessage: "",
      rateplan_uuid: "",
      rateplanname: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Rate Plan",
          action: "rateplan",
        },
        {
          label: "Edit Rate Plan",
          action: "",
        },
      ],
    };
  }

  componentDidMount = async () => {
    var url = window.location.pathname;
    var rateplan_uuid = url.substring(url.lastIndexOf("/") + 1);
    this.getRecordByID(rateplan_uuid);
  }

  getRecordByID = async (id) => {
    let responseData = await commonPostAPI("getRatePlanDataByID", { rateplan_uuid: id });
    let errors = this.state.errors;
    Object.keys(responseData).forEach(function (key) {
      switch (key) {
        case "name":
          errors.rateplanname =
            typeof responseData[key] !== "undefined" &&
              responseData[key] !== "" &&
              responseData[key] != null
              ? ""
              : "Please enter name.";
          break;
        default:
          break;
      }
    });
    var dbData = {
      errors: errors,
      rateplan_uuid: id,
      rateplanname: responseData.name,
    };

    partialState = dbData;
    this.setState(partialState);
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        rateplan_uuid: this.state.rateplan_uuid,
        rateplanname: this.state.rateplanname,
      };
      let response = await commonPostAPI("rateplanupdate", requestparams);
      if (response.name && response.name !== "") {
        this.setState({
          alertMessage: <div>Please rate plan name already exists.</div>,
        });
      } else {
        this.onOpenAction("rateplan");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;
    switch (e.target.name) {
      case "rateplanname":
        errors.rateplanname =
          e.target.value.length < 1 ? "Please enter rate plan name." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      alertMessage,
      rateplanname,
    } = this.state;

    return (
      <div>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 0, width: "100%" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Rate Plan Name</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="rateplanname"
                        name="rateplanname"
                        value={rateplanname ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                      {errors.rateplanname.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.rateplanname}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>&nbsp;</Grid>

                  {alertMessage ? (
                    <Stack className="error-show" spacing={2}>
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Grid container xs={12}>&nbsp;</Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("rateplan")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(RateplaneditData);
