import React, { Component } from "react";
import { BoxDialer, Input } from "./atoms";
import Callbutton from "./Callbutton";
import Removebutton from "./Removebutton";
import Rejectcallbutton from "./Rejectcallbutton";

class Dialer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      enterNumber,
      enterInputNumber,
      removeNumber,
      onCallStarted,
      number,
      opened,
      dialedOrReceived,
      onCallReject,
    } = this.props;
    return (
      <BoxDialer opened={opened} className="dialer-setup">
        <table
          style={{ width: "100%", color: "white", "minHeight": "350px" }}
          border="0"
        >
          <tr>
            <td colSpan={3} style={{ width: "100%" }}>
              <Input
                autoFocus="true"
                value={number}
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
                onChange={(e) => enterInputNumber(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(1)}>
                1
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(2)}>
                2
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(3)}>
                3
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(4)}>
                4
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(5)}>
                5
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(6)}>
                6
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(7)}>
                7
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(8)}>
                8
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(9)}>
                9
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber("*")}>
                *
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber(0)}>
                0
              </div>
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              <div className="dial-number" onClick={() => enterNumber("#")}>
                #
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              {dialedOrReceived === true ? (
                <Rejectcallbutton onCallReject={onCallReject} />
              ) : (
                ""
              )}{" "}
            </td>
            <td
              className="call-connect"
              style={{ width: "33%", "textAlign": "center" }}
            >
              <Callbutton onCallStarted={() => onCallStarted(number)} />
            </td>
            <td style={{ width: "33%", "textAlign": "center" }}>
              {dialedOrReceived === false ? (
                <Removebutton
                  number={number}
                  removeNumber={() => removeNumber()}
                />
              ) : (
                ""
              )}
            </td>
          </tr>
        </table>
      </BoxDialer>
    );
  }
}

export default Dialer;
