import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Grid, Select, MenuItem, TextField } from "@mui/material";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var zoneObj = [
  { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00" },
  { name: "(GMT -11:00) Midway Island, Samoa", value: "-11:00" },
  { name: "(GMT -10:00) Hawaii", value: "-10:00" },
  { name: "(GMT -9:30) Taiohae", value: "-09:50" },
  { name: "(GMT -9:00) Alaska", value: "-09:00" },
  { name: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08:00" },
  { name: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07:00" },
  { name: "(GMT -6:00) Central Time (US & Canada), Mexico City", value: "-06:00" },
  { name: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", value: "-05:00" },
  { name: "(GMT -4:30) Caracas", value: "-04:50" },
  { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04:00" },
  { name: "(GMT -3:30) Newfoundland", value: "-03:50" },
  { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00" },
  { name: "(GMT -2:00) Mid-Atlantic", value: "-02:00" },
  { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00" },
  { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00" },
  { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
  { name: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00" },
  { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00" },
  { name: "(GMT +3:30) Tehran", value: "+03:50" },
  { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00" },
  { name: "(GMT +4:30) Kabul", value: "+04:50" },
  { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00" },
  { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50" },
  { name: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75" },
  { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00" },
  { name: "(GMT +6:30) Yangon, Mandalay", value: "+06:50" },
  { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
  { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00" },
  { name: "(GMT +8:45) Eucla", value: "+08:75" },
  { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00" },
  { name: "(GMT +9:30) Adelaide, Darwin", value: "+09:50" },
  { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00" },
  { name: "(GMT +10:30) Lord Howe Island", value: "+10:50" },
  { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00" },
  { name: "(GMT +11:30) Norfolk Island", value: "+11:50" },
  { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00" },
  { name: "(GMT +12:45) Chatham Islands", value: "+12:75" },
  { name: "(GMT +13:00) Apia, Nukualofa", value: "+13:00" },
  { name: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00" }
];

var partialState = {
  mondayFromValue: [],
  mondayToValue: [],
  tuesdayFromValue: [],
  tuesdayToValue: [],
  wednesdayFromValue: [],
  wednesdayToValue: [],
  thursdayFromValue: [],
  thursdayToValue: [],
  fridayFromValue: [],
  fridayToValue: [],
  saturdayFromValue: [],
  saturdayToValue: [],
  sundayFromValue: [],
  sundayToValue: [],
  mondayBreakFromValue: [],
  mondayBreakToValue: [],
  tuesdayBreakFromValue: [],
  tuesdayBreakToValue: [],
  wednesdayBreakFromValue: [],
  wednesdayBreakToValue: [],
  thursdayBreakFromValue: [],
  thursdayBreakToValue: [],
  fridayBreakFromValue: [],
  fridayBreakToValue: [],
  saturdayBreakFromValue: [],
  saturdayBreakToValue: [],
  sundayBreakFromValue: [],
  sundayBreakToValue: [],
  holidayName: [],
  holidayFromTimeValue: [],
  holidayToTimeValue: [],
  holidayOccurs: [],
  officeHoursTimeOutAction: "1",
  officeHoursTimeOutEntry: "hangup",
  breakHoursTimeOutAction: "1",
  breakHoursTimeOutEntry: "hangup",
  holidayTimeOutAction: "1",
  holidayTimeOutEntry: "hangup",
  timeZoneValue: "0",
  officeHoursGreeting: "",
  breakHoursGreeting: "",
  holidaysGreeting: "",
  didNumber: [],
};
var holiday_name = "";
var fromValue = "";
var toValue = "";
var occurs = "1";


class TimingeditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      alertMessage: "",
      mondayFromValue: [],
      mondayToValue: [],
      tuesdayFromValue: [],
      tuesdayToValue: [],
      wednesdayFromValue: [],
      wednesdayToValue: [],
      thursdayFromValue: [],
      thursdayToValue: [],
      fridayFromValue: [],
      fridayToValue: [],
      saturdayFromValue: [],
      saturdayToValue: [],
      sundayFromValue: [],
      sundayToValue: [],
      mondayBreakFromValue: [],
      mondayBreakToValue: [],
      tuesdayBreakFromValue: [],
      tuesdayBreakToValue: [],
      wednesdayBreakFromValue: [],
      wednesdayBreakToValue: [],
      thursdayBreakFromValue: [],
      thursdayBreakToValue: [],
      fridayBreakFromValue: [],
      fridayBreakToValue: [],
      saturdayBreakFromValue: [],
      saturdayBreakToValue: [],
      sundayBreakFromValue: [],
      sundayBreakToValue: [],
      holidayName: [],
      holidayFromTimeValue: [],
      holidayToTimeValue: [],
      holidayOccurs: [],
      officeHoursTimeOutAction: "1",
      officeHoursTimeOutEntry: "hangup:",
      officeHoursTimeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      breakHoursTimeOutAction: "1",
      breakHoursTimeOutEntry: "hangup:",
      breakHoursTimeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      holidayTimeOutAction: "1",
      holidayTimeOutEntry: "hangup:",
      holidayTimeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      timeZoneValue: "0",
      timeZoneList: zoneObj,
      didNumber: [],
      didNumberList: [],
      officeHoursGreeting: "0",
      breakHoursGreeting: "0",
      holidaysGreeting: "0",
      greetingList: [],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Time Routing",
          action: "",
        }
      ],
      open: false,
      openBreak: false,
      openHolidays: false,
      dayName: '',
      dayBreakName: '',
      openDialog: false,
    };
  }

  componentDidMount = async () => {

    await this.getDataByID();
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let responseList = await commonPostAPI("didlist", {
      domain_uuid: domain_uuid,
    });
    this.setState({ didNumberList: responseList });

    responseList = await commonPostAPI("recordinglist", {
      domain_uuid: domain_uuid,
    });
    this.setState({ greetingList: responseList });

  }

  timeOutOfficeHoursActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      return { officeHoursTimeOutEntryList: response, officeHoursTimeOutEntry: "0" };
    } else {
      return { officeHoursTimeOutEntryList: [{ value: "hangup:", label: "hangup" }], officeHoursTimeOutEntry: "hangup:" };
    }
  };

  timeOutBreakHoursActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      return { breakHoursTimeOutEntryList: response, breakHoursTimeOutEntry: "0" };
    } else {
      return { breakHoursTimeOutEntryList: [{ value: "hangup:", label: "hangup" }], breakHoursTimeOutEntry: "hangup:" };
    }
  };

  timeOutHolidayActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      return { holidayTimeOutEntryList: response, holidayTimeOutEntry: "0" };
    } else {
      return { holidayTimeOutEntryList: [{ value: "hangup:", label: "hangup" }], holidayTimeOutEntry: "hangup:" };
    }
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      if (
        this.state.officeHoursTimeOutAction !== "1" &&
        this.state.officeHoursTimeOutEntry === "0"
      ) {
        this.setState({ alertMessage: <div>Please select office hours action entry.</div> });
        return true;
      }

      if (
        this.state.breakHoursTimeOutAction !== "1" &&
        this.state.breakHoursTimeOutEntry === "0"
      ) {
        this.setState({ alertMessage: <div>Please select break hours action entry.</div> });
        return true;
      }

      if (
        this.state.holidayTimeOutAction !== "1" &&
        this.state.holidayTimeOutEntry === "0"
      ) {
        this.setState({ alertMessage: <div>Please select holiday action entry.</div> });
        return true;
      }

      this.setState({ alertMessage: "" });
      const domain_uuid = sessionStorage.getItem("domain_uuid");

      let requestparams = {
        domain_uuid: domain_uuid,
        mondayFromValue: this.state.mondayFromValue,
        mondayToValue: this.state.mondayToValue,
        tuesdayFromValue: this.state.tuesdayFromValue,
        tuesdayToValue: this.state.tuesdayToValue,
        wednesdayFromValue: this.state.wednesdayFromValue,
        wednesdayToValue: this.state.wednesdayToValue,
        thursdayFromValue: this.state.thursdayFromValue,
        thursdayToValue: this.state.thursdayToValue,
        fridayFromValue: this.state.fridayFromValue,
        fridayToValue: this.state.fridayToValue,
        saturdayFromValue: this.state.saturdayFromValue,
        saturdayToValue: this.state.saturdayToValue,
        sundayFromValue: this.state.sundayFromValue,
        sundayToValue: this.state.sundayToValue,
        mondayBreakFromValue: this.state.mondayBreakFromValue,
        mondayBreakToValue: this.state.mondayBreakToValue,
        tuesdayBreakFromValue: this.state.tuesdayBreakFromValue,
        tuesdayBreakToValue: this.state.tuesdayBreakToValue,
        wednesdayBreakFromValue: this.state.wednesdayBreakFromValue,
        wednesdayBreakToValue: this.state.wednesdayBreakToValue,
        thursdayBreakFromValue: this.state.thursdayBreakFromValue,
        thursdayBreakToValue: this.state.thursdayBreakToValue,
        fridayBreakFromValue: this.state.fridayBreakFromValue,
        fridayBreakToValue: this.state.fridayBreakToValue,
        saturdayBreakFromValue: this.state.saturdayBreakFromValue,
        saturdayBreakToValue: this.state.saturdayBreakToValue,
        sundayBreakFromValue: this.state.sundayBreakFromValue,
        sundayBreakToValue: this.state.sundayBreakToValue,
        holidayName: this.state.holidayName,
        holidayFromTimeValue: this.state.holidayFromTimeValue,
        holidayToTimeValue: this.state.holidayToTimeValue,
        holidayOccurs: this.state.holidayOccurs,
        officeHoursTimeOutAction: this.state.officeHoursTimeOutAction,
        officeHoursTimeOutEntry: this.state.officeHoursTimeOutEntry,
        officeHoursGreeting: this.state.officeHoursGreeting,
        breakHoursTimeOutAction: this.state.breakHoursTimeOutAction,
        breakHoursTimeOutEntry: this.state.breakHoursTimeOutEntry,
        breakHoursGreeting: this.state.breakHoursGreeting,
        holidayTimeOutAction: this.state.holidayTimeOutAction,
        holidayTimeOutEntry: this.state.holidayTimeOutEntry,
        holidaysGreeting: this.state.holidaysGreeting,
        timeZone: this.state.timeZoneValue,
        didNumber: this.state.didNumber,
        extension_uuid: "",
        status: 1,
      };

      await commonPostAPI("addtimerouting", requestparams);
      this.actionPopup(true);
      this.onOpenAction("timing");
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onChangeItem = async (e) => {
    e.preventDefault();
    let actionResult = {};
    if (e.target.name === "officeHoursTimeOutAction") {
      actionResult = await this.timeOutOfficeHoursActionChange(e);
      partialState['officeHoursTimeOutEntryList'] = actionResult.officeHoursTimeOutEntryList;
      partialState['officeHoursTimeOutEntry'] = actionResult.officeHoursTimeOutEntry;
    } else if (e.target.name === "breakHoursTimeOutAction") {
      actionResult = await this.timeOutBreakHoursActionChange(e);
      partialState['breakHoursTimeOutEntryList'] = actionResult.breakHoursTimeOutEntryList;
      partialState['breakHoursTimeOutEntry'] = actionResult.breakHoursTimeOutEntry;
    } else if (e.target.name === "holidayTimeOutAction") {
      actionResult = await this.timeOutHolidayActionChange(e);
      partialState['holidayTimeOutEntryList'] = actionResult.holidayTimeOutEntryList;
      partialState['holidayTimeOutEntry'] = actionResult.holidayTimeOutEntry;
    }

    if (e.target.name === 'holidayname') {
      holiday_name = e.target.value;
    } else if (e.target.name === 'occursValue') {
      occurs = e.target.value;
    } else {
      partialState[e.target.name] = e.target.value;
    }
    this.setState(partialState);
  };

  onChangeDateItem = (name, value) => {
    let data = new Date(value).toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' })
    if (name === 'fromValue') {
      fromValue = data;
    }

    if (name === 'toValue') {
      toValue = data;
    }
  }

  getDataByID = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");

    let timeRouteRows = await commonPostAPI("getTimeRoutingRecords", { domain_uuid: domain_uuid, extension_uuid: "" });
    if (timeRouteRows && timeRouteRows.time_route_uuid) {
      let timeRouteDidRows = await commonPostAPI("getTimeRoutingRecordDids", { time_route_uuid: timeRouteRows.time_route_uuid });
      let timeRouteSettingRows = await commonPostAPI("getTimeRoutingRecordSettings", { time_route_uuid: timeRouteRows.time_route_uuid });
      partialState['didNumber'] = (timeRouteDidRows && timeRouteDidRows.length > 0) ? timeRouteDidRows.map((item) => {
        return item.did_uuid;
      }) : [];
      partialState['timeZoneValue'] = (timeRouteRows && timeRouteRows.timezone) ? timeRouteRows.timezone : "0";

      // office hours
      partialState['officeHoursTimeOutAction'] = (timeRouteRows && timeRouteRows.office_timeout_action_type) ? timeRouteRows.office_timeout_action_type : "1";
      partialState['officeHoursTimeOutEntry'] = (timeRouteRows && timeRouteRows.office_timeout_action_entry) ? timeRouteRows.office_timeout_action_entry : "hangup";
      let responseList = [];
      if (timeRouteRows.office_timeout_action_type !== "1") {
        responseList = await commonPostAPI("callaction", {
          type: timeRouteRows.office_timeout_action_type,
          domain_uuid: domain_uuid,
          domain_name: domain_name,
        });
        this.setState({ officeHoursTimeOutEntryList: responseList });
      }
      partialState['officeHoursGreeting'] = (timeRouteRows && timeRouteRows.office_greeting) ? timeRouteRows.office_greeting : "0";

      // break hours
      partialState['breakHoursTimeOutAction'] = (timeRouteRows && timeRouteRows.break_timeout_action_type) ? timeRouteRows.break_timeout_action_type : "1";
      partialState['breakHoursTimeOutEntry'] = (timeRouteRows && timeRouteRows.break_timeout_action_entry) ? timeRouteRows.break_timeout_action_entry : "hangup";
      if (timeRouteRows.break_timeout_action_type !== "1") {
        responseList = await commonPostAPI("callaction", {
          type: timeRouteRows.break_timeout_action_type,
          domain_uuid: domain_uuid,
          domain_name: domain_name,
        });
        this.setState({ breakHoursTimeOutEntryList: responseList });
      }
      partialState['breakHoursGreeting'] = (timeRouteRows && timeRouteRows.break_greeting) ? timeRouteRows.break_greeting : "0";


      // holiday
      partialState['holidayTimeOutAction'] = (timeRouteRows && timeRouteRows.holiday_timeout_action_type) ? timeRouteRows.holiday_timeout_action_type : "1";
      partialState['holidayTimeOutEntry'] = (timeRouteRows && timeRouteRows.holiday_timeout_action_entry) ? timeRouteRows.holiday_timeout_action_entry : "hangup";
      if (timeRouteRows.holiday_timeout_action_type !== "1") {
        responseList = await commonPostAPI("callaction", {
          type: timeRouteRows.holiday_timeout_action_type,
          domain_uuid: domain_uuid,
          domain_name: domain_name,
        });
        this.setState({ holidayTimeOutEntryList: responseList });
      }
      partialState['holidaysGreeting'] = (timeRouteRows && timeRouteRows.holiday_greeting) ? timeRouteRows.holiday_greeting : "0";


      if (timeRouteSettingRows && timeRouteSettingRows.length > 0) {
        let tmpClone = [];

        // Office hours
        // sunday
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '0') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['sundayFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '0') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['sundayToValue'] = tmpClone;
        }

        //monday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '1') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['mondayFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '1') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['mondayToValue'] = tmpClone;
        }

        //tuesday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '2') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['tuesdayFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '2') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['tuesdayToValue'] = tmpClone;
        }

        //wednesday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '3') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['wednesdayFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '3') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['wednesdayToValue'] = tmpClone;
        }

        //thursday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '4') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['thursdayFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '4') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['thursdayToValue'] = tmpClone;
        }

        //friday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '5') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['fridayFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '5') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['fridayToValue'] = tmpClone;
        }

        //saturday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '6') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['saturdayFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '1' && item.day_of_week === '6') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['saturdayToValue'] = tmpClone;
        }

        // Break hours
        // sunday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '0') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['sundayBreakFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '0') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['sundayBreakToValue'] = tmpClone;
        }

        //monday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '1') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['mondayBreakFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '1') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['mondayBreakToValue'] = tmpClone;
        }

        //tuesday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '2') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['tuesdayBreakFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '2') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['tuesdayBreakToValue'] = tmpClone;
        }

        //wednesday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '3') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['wednesdayBreakFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '3') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['wednesdayBreakToValue'] = tmpClone;
        }

        //thursday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '4') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['thursdayBreakFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '4') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['thursdayBreakToValue'] = tmpClone;
        }

        //friday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '5') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['fridayBreakFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '5') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['fridayBreakToValue'] = tmpClone;
        }

        //saturday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '6') {
            tmpClone.push(item.start_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['saturdayBreakFromValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '2' && item.day_of_week === '6') {
            tmpClone.push(item.stop_time);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['saturdayBreakToValue'] = tmpClone;
        }

        // Holiday
        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '3') {
            tmpClone.push(item.holidays_name);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['holidayName'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '3') {
            tmpClone.push(item.start_date);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['holidayFromTimeValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '3') {
            tmpClone.push(item.stop_date);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['holidayToTimeValue'] = tmpClone;
        }

        tmpClone = [];
        timeRouteSettingRows.map((item) => {
          if (item.types === '3') {
            tmpClone.push(item.holidays_occurs);
          }
          return true;
        });
        if (tmpClone.length > 0) {
          partialState['holidayOccurs'] = tmpClone;
        }
      }
      this.setState(partialState);
    }


  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  setTimeOption = (name) => {
    this.setState({ open: true, dayName: name });
  }

  clearTimeOption = () => {
    partialState = {
      mondayFromValue: [],
      mondayToValue: [],
      tuesdayFromValue: [],
      tuesdayToValue: [],
      wednesdayFromValue: [],
      wednesdayToValue: [],
      thursdayFromValue: [],
      thursdayToValue: [],
      fridayFromValue: [],
      fridayToValue: [],
      saturdayFromValue: [],
      saturdayToValue: [],
      sundayFromValue: [],
      sundayToValue: [],
    };
    this.setState(partialState);
  }

  addTimeAction = () => {
    let {
      dayName,
      mondayFromValue,
      mondayToValue,
      tuesdayFromValue,
      tuesdayToValue,
      wednesdayFromValue,
      wednesdayToValue,
      thursdayFromValue,
      thursdayToValue,
      fridayFromValue,
      fridayToValue,
      saturdayFromValue,
      saturdayToValue,
      sundayFromValue,
      sundayToValue,
    } = this.state;

    switch (dayName) {
      case 'Monday':
        if (fromValue !== "" && toValue !== "") {
          mondayFromValue.push(fromValue)
          partialState['mondayFromValue'] = mondayFromValue;
          mondayToValue.push(toValue)
          partialState['mondayToValue'] = mondayToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Tuesday':
        if (fromValue !== "" && toValue !== "") {
          tuesdayFromValue.push(fromValue)
          partialState['tuesdayFromValue'] = tuesdayFromValue;
          tuesdayToValue.push(toValue)
          partialState['tuesdayToValue'] = tuesdayToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Wednesday':
        if (fromValue !== "" && toValue !== "") {
          wednesdayFromValue.push(fromValue)
          partialState['wednesdayFromValue'] = wednesdayFromValue;
          wednesdayToValue.push(toValue)
          partialState['wednesdayToValue'] = wednesdayToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Thursday':
        if (fromValue !== "" && toValue !== "") {
          thursdayFromValue.push(fromValue)
          partialState['thursdayFromValue'] = thursdayFromValue;
          thursdayToValue.push(toValue)
          partialState['thursdayToValue'] = thursdayToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Friday':
        if (fromValue !== "" && toValue !== "") {
          fridayFromValue.push(fromValue)
          partialState['fridayFromValue'] = fridayFromValue;
          fridayToValue.push(toValue)
          partialState['fridayToValue'] = fridayToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Saturday':
        if (fromValue !== "" && toValue !== "") {
          saturdayFromValue.push(fromValue)
          partialState['saturdayFromValue'] = saturdayFromValue;
          saturdayToValue.push(toValue)
          partialState['saturdayToValue'] = saturdayToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Sunday':
        if (fromValue !== "" && toValue !== "") {
          sundayFromValue.push(fromValue)
          partialState['sundayFromValue'] = sundayFromValue;
          sundayToValue.push(toValue)
          partialState['sundayToValue'] = sundayToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      default:
        break;
    }
    this.setState(partialState);
    this.handleTimeClose();
  }

  removeTimeElement = (label, index) => {
    switch (label) {
      case 'Monday':
        let newMondayFromValue = this.state.mondayFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newMondayToValue = this.state.mondayToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['mondayFromValue'] = newMondayFromValue;
        partialState['mondayToValue'] = newMondayToValue;
        break;
      case 'Tuesday':
        let newTuesdayFromValue = this.state.tuesdayFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newTuesdayToValue = this.state.tuesdayToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['tuesdayFromValue'] = newTuesdayFromValue;
        partialState['tuesdayToValue'] = newTuesdayToValue;
        break;
      case 'Wednesday':
        let newWednesdayFromValue = this.state.wednesdayFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newWednesdayToValue = this.state.wednesdayToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['wednesdayFromValue'] = newWednesdayFromValue;
        partialState['wednesdayToValue'] = newWednesdayToValue;
        break;
      case 'Thursday':
        let newThursdayFromValue = this.state.thursdayFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newThursdayToValue = this.state.thursdayToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['thursdayFromValue'] = newThursdayFromValue;
        partialState['thursdayToValue'] = newThursdayToValue;
        break;
      case 'Friday':
        let newFridayFromValue = this.state.fridayFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newFridayToValue = this.state.fridayToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['fridayFromValue'] = newFridayFromValue;
        partialState['fridayToValue'] = newFridayToValue;
        break;
      case 'Saturday':
        let newSaturdayFromValue = this.state.saturdayFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newSaturdayToValue = this.state.saturdayToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['saturdayFromValue'] = newSaturdayFromValue;
        partialState['saturdayToValue'] = newSaturdayToValue;
        break;
      case 'Sunday':
        let newSundayFromValue = this.state.sundayFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newSundayToValue = this.state.sundayToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['sundayFromValue'] = newSundayFromValue;
        partialState['sundayToValue'] = newSundayToValue;
        break;
      default:
        break;
    }
    this.setState(partialState);
  }

  handleTimeClose = () => {
    this.setState({ open: false, dayName: "" });
  };

  actionPopup = (flag) => {
    this.setState({ openDialog: flag });
  }

  setBreakTimeOption = (name) => {
    this.setState({ openBreak: true, dayBreakName: name });
  }

  handleBreakTimeClose = () => {
    this.setState({ openBreak: false, dayBreakName: "" });
  };

  addTimeBreakAction = () => {

    let {
      dayBreakName,
      mondayBreakFromValue,
      mondayBreakToValue,
      tuesdayBreakFromValue,
      tuesdayBreakToValue,
      wednesdayBreakFromValue,
      wednesdayBreakToValue,
      thursdayBreakFromValue,
      thursdayBreakToValue,
      fridayBreakFromValue,
      fridayBreakToValue,
      saturdayBreakFromValue,
      saturdayBreakToValue,
      sundayBreakFromValue,
      sundayBreakToValue,
    } = this.state;

    switch (dayBreakName) {
      case 'Monday':
        if (fromValue !== "" && toValue !== "") {
          mondayBreakFromValue.push(fromValue)
          partialState['mondayBreakFromValue'] = mondayBreakFromValue;
          mondayBreakToValue.push(toValue)
          partialState['mondayBreakToValue'] = mondayBreakToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Tuesday':
        if (fromValue !== "" && toValue !== "") {
          tuesdayBreakFromValue.push(fromValue)
          partialState['tuesdayBreakFromValue'] = tuesdayBreakFromValue;
          tuesdayBreakToValue.push(toValue)
          partialState['tuesdayBreakToValue'] = tuesdayBreakToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Wednesday':
        if (fromValue !== "" && toValue !== "") {
          wednesdayBreakFromValue.push(fromValue)
          partialState['wednesdayBreakFromValue'] = wednesdayBreakFromValue;
          wednesdayBreakToValue.push(toValue)
          partialState['wednesdayBreakToValue'] = wednesdayBreakToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Thursday':
        if (fromValue !== "" && toValue !== "") {
          thursdayBreakFromValue.push(fromValue)
          partialState['thursdayBreakFromValue'] = thursdayBreakFromValue;
          thursdayBreakToValue.push(toValue)
          partialState['thursdayBreakToValue'] = thursdayBreakToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Friday':
        if (fromValue !== "" && toValue !== "") {
          fridayBreakFromValue.push(fromValue)
          partialState['fridayBreakFromValue'] = fridayBreakFromValue;
          fridayBreakToValue.push(toValue)
          partialState['fridayBreakToValue'] = fridayBreakToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Saturday':
        if (fromValue !== "" && toValue !== "") {
          saturdayBreakFromValue.push(fromValue)
          partialState['saturdayBreakFromValue'] = saturdayBreakFromValue;
          saturdayBreakToValue.push(toValue)
          partialState['saturdayBreakToValue'] = saturdayBreakToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      case 'Sunday':
        if (fromValue !== "" && toValue !== "") {
          sundayBreakFromValue.push(fromValue)
          partialState['sundayBreakFromValue'] = sundayBreakFromValue;
          sundayBreakToValue.push(toValue)
          partialState['sundayBreakToValue'] = sundayBreakToValue;
          fromValue = "";
          toValue = "";
        }
        break;
      default:
        break;
    }
    this.setState(partialState);
    this.handleBreakTimeClose();
  }

  removeBreakTimeElement = (label, index) => {
    switch (label) {
      case 'Monday':
        let newBreakMondayFromValue = this.state.mondayBreakFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newBreakMondayToValue = this.state.mondayBreakToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['mondayBreakFromValue'] = newBreakMondayFromValue;
        partialState['mondayBreakToValue'] = newBreakMondayToValue;
        break;
      case 'Tuesday':
        let newBreakTuesdayFromValue = this.state.tuesdayBreakFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newBreakTuesdayToValue = this.state.tuesdayBreakToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['tuesdayBreakFromValue'] = newBreakTuesdayFromValue;
        partialState['tuesdayBreakToValue'] = newBreakTuesdayToValue;
        break;
      case 'Wednesday':
        let newBreakWednesdayFromValue = this.state.wednesdayBreakFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newBreakWednesdayToValue = this.state.wednesdayBreakToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['wednesdayBreakFromValue'] = newBreakWednesdayFromValue;
        partialState['wednesdayBreakToValue'] = newBreakWednesdayToValue;
        break;
      case 'Thursday':
        let newBreakThursdayFromValue = this.state.thursdayBreakFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newBreakThursdayToValue = this.state.thursdayBreakToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['thursdayBreakFromValue'] = newBreakThursdayFromValue;
        partialState['thursdayBreakToValue'] = newBreakThursdayToValue;
        break;
      case 'Friday':
        let newBreakFridayFromValue = this.state.fridayBreakFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newBreakFridayToValue = this.state.fridayBreakToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['fridayBreakFromValue'] = newBreakFridayFromValue;
        partialState['fridayBreakToValue'] = newBreakFridayToValue;
        break;
      case 'Saturday':
        let newBreakSaturdayFromValue = this.state.saturdayBreakFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newBreakSaturdayToValue = this.state.saturdayBreakToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['saturdayBreakFromValue'] = newBreakSaturdayFromValue;
        partialState['saturdayBreakToValue'] = newBreakSaturdayToValue;
        break;
      case 'Sunday':
        let newBreakSundayFromValue = this.state.sundayBreakFromValue.filter(function (items, idx) {
          return idx !== index;
        });

        let newBreakSundayToValue = this.state.sundayBreakToValue.filter(function (items, idx) {
          return idx !== index;
        });
        partialState['sundayBreakFromValue'] = newBreakSundayFromValue;
        partialState['sundayBreakToValue'] = newBreakSundayToValue;
        break;
      default:
        break;
    }
    this.setState(partialState);
  }

  clearBreakTimeOption = () => {
    partialState = {
      mondayBreakFromValue: [],
      mondayBreakToValue: [],
      tuesdayBreakFromValue: [],
      tuesdayBreakToValue: [],
      wednesdayBreakFromValue: [],
      wednesdayBreakToValue: [],
      thursdayBreakFromValue: [],
      thursdayBreakToValue: [],
      fridayBreakFromValue: [],
      fridayBreakToValue: [],
      saturdayBreakFromValue: [],
      saturdayBreakToValue: [],
      sundayBreakFromValue: [],
      sundayBreakToValue: [],
    };
    this.setState(partialState);
  }

  setHolidaysOption = () => {
    this.setState({ openHolidays: true });
  }

  handleHolidayClose = () => {
    fromValue = "";
    toValue = "";
    occurs = "1";
    holiday_name = "";
    this.setState({ openHolidays: false });
  };

  addHolidayAction = () => {
    let {
      holidayName,
      holidayFromTimeValue,
      holidayToTimeValue,
      holidayOccurs,
    } = this.state;

    holidayFromTimeValue.push(fromValue);
    partialState['holidayFromTimeValue'] = holidayFromTimeValue;
    holidayToTimeValue.push(toValue);
    partialState['holidayToTimeValue'] = holidayToTimeValue;
    holidayName.push(holiday_name);
    partialState['holidayName'] = holidayName;
    holidayOccurs.push(occurs);
    partialState['holidayOccurs'] = holidayOccurs;
    fromValue = "";
    toValue = "";
    holiday_name = "";

    this.setState(partialState);
    this.handleHolidayClose();
  }

  onChangeDateTimeItem = (name, value) => {
    let data = new Date(value).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })
    if (name === 'fromValue') {
      fromValue = data;
    }

    if (name === 'toValue') {
      toValue = data;
    }
  }

  removeHolidayElement = (index) => {
    let newHolidayName = this.state.holidayName.filter(function (items, idx) {
      return idx !== index;
    });

    let newHolidayFromTimeValue = this.state.holidayFromTimeValue.filter(function (items, idx) {
      return idx !== index;
    });

    let newHolidayToTimeValue = this.state.holidayToTimeValue.filter(function (items, idx) {
      return idx !== index;
    });

    let newHolidayOccurs = this.state.holidayOccurs.filter(function (items, idx) {
      return idx !== index;
    });


    partialState['holidayName'] = newHolidayName;
    partialState['holidayFromTimeValue'] = newHolidayFromTimeValue;
    partialState['holidayToTimeValue'] = newHolidayToTimeValue;
    partialState['holidayOccurs'] = newHolidayOccurs;

    this.setState(partialState);
  }

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      open,
      dayName,
      openBreak,
      dayBreakName,
      alertMessage,
      mondayFromValue,
      mondayToValue,
      tuesdayFromValue,
      tuesdayToValue,
      wednesdayFromValue,
      wednesdayToValue,
      thursdayFromValue,
      thursdayToValue,
      fridayFromValue,
      fridayToValue,
      saturdayFromValue,
      saturdayToValue,
      sundayFromValue,
      sundayToValue,
      mondayBreakFromValue,
      mondayBreakToValue,
      tuesdayBreakFromValue,
      tuesdayBreakToValue,
      wednesdayBreakFromValue,
      wednesdayBreakToValue,
      thursdayBreakFromValue,
      thursdayBreakToValue,
      fridayBreakFromValue,
      fridayBreakToValue,
      saturdayBreakFromValue,
      saturdayBreakToValue,
      sundayBreakFromValue,
      sundayBreakToValue,
      openHolidays,
      holidayName,
      holidayFromTimeValue,
      holidayToTimeValue,
      holidayOccurs,
      officeHoursTimeOutAction,
      officeHoursTimeOutEntry,
      officeHoursTimeOutEntryList,
      breakHoursTimeOutAction,
      breakHoursTimeOutEntry,
      breakHoursTimeOutEntryList,
      holidayTimeOutAction,
      holidayTimeOutEntry,
      holidayTimeOutEntryList,
      timeZoneValue,
      timeZoneList,
      didNumber,
      didNumberList,
      officeHoursGreeting,
      breakHoursGreeting,
      holidaysGreeting,
      greetingList,
      openDialog,
    } = this.state;

    let MondayArray = [];
    for (var i = 0; i < mondayFromValue.length; i++) {
      let idx = i;
      MondayArray.push(
        <Grid container xs={12} spacing={2} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(mondayFromValue[i]) ? mondayFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(mondayToValue[i]) ? mondayToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeTimeElement('Monday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let TuesdayArray = [];
    for (i = 0; i < tuesdayFromValue.length; i++) {
      let idx = i;
      TuesdayArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(tuesdayFromValue[i]) ? tuesdayFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(tuesdayToValue[i]) ? tuesdayToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeTimeElement('Tuesday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let WednesdayArray = [];
    for (i = 0; i < wednesdayFromValue.length; i++) {
      let idx = i;
      WednesdayArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(wednesdayFromValue[i]) ? wednesdayFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(wednesdayToValue[i]) ? wednesdayToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeTimeElement('Wednesday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let ThursdayArray = [];
    for (i = 0; i < thursdayFromValue.length; i++) {
      let idx = i;
      ThursdayArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(thursdayFromValue[i]) ? thursdayFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(thursdayToValue[i]) ? thursdayToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeTimeElement('Thursday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let FridayArray = [];
    for (i = 0; i < fridayFromValue.length; i++) {
      let idx = i;
      FridayArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(fridayFromValue[i]) ? fridayFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(fridayToValue[i]) ? fridayToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeTimeElement('Friday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let SaturdayArray = [];
    for (i = 0; i < saturdayFromValue.length; i++) {
      let idx = i;
      SaturdayArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(saturdayFromValue[i]) ? saturdayFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(saturdayToValue[i]) ? saturdayToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeTimeElement('Saturday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let SundayArray = [];
    for (i = 0; i < sundayFromValue.length; i++) {
      let idx = i;
      SundayArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(sundayFromValue[i]) ? sundayFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(sundayToValue[i]) ? sundayToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeTimeElement('Sunday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let MondayBreakArray = [];
    for (i = 0; i < mondayBreakFromValue.length; i++) {
      let idx = i;
      MondayBreakArray.push(
        <Grid container xs={12} spacing={2} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(mondayBreakFromValue[i]) ? mondayBreakFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(mondayBreakToValue[i]) ? mondayBreakToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeBreakTimeElement('Monday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let TuesdayBreakArray = [];
    for (i = 0; i < tuesdayBreakFromValue.length; i++) {
      let idx = i;
      TuesdayBreakArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(tuesdayBreakFromValue[i]) ? tuesdayBreakFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(tuesdayBreakToValue[i]) ? tuesdayBreakToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeBreakTimeElement('Tuesday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let WednesdayBreakArray = [];
    for (i = 0; i < wednesdayBreakFromValue.length; i++) {
      let idx = i;
      WednesdayBreakArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(wednesdayBreakFromValue[i]) ? wednesdayBreakFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(wednesdayBreakToValue[i]) ? wednesdayBreakToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeBreakTimeElement('Wednesday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let ThursdayBreakArray = [];
    for (i = 0; i < thursdayBreakFromValue.length; i++) {
      let idx = i;
      ThursdayBreakArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(thursdayBreakFromValue[i]) ? thursdayBreakFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(thursdayBreakToValue[i]) ? thursdayBreakToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeBreakTimeElement('Thursday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let FridayBreakArray = [];
    for (i = 0; i < fridayBreakFromValue.length; i++) {
      let idx = i;
      FridayBreakArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(fridayBreakFromValue[i]) ? fridayBreakFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(fridayBreakToValue[i]) ? fridayBreakToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeBreakTimeElement('Friday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let SaturdayBreakArray = [];
    for (i = 0; i < saturdayBreakFromValue.length; i++) {
      let idx = i;
      SaturdayBreakArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(saturdayBreakFromValue[i]) ? saturdayBreakFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(saturdayBreakToValue[i]) ? saturdayBreakToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeBreakTimeElement('Saturday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let SundayBreakArray = [];
    for (i = 0; i < sundayBreakFromValue.length; i++) {
      let idx = i;
      SundayBreakArray.push(
        <Grid container xs={12} spacing={1} sx={{ borderBottom: 1, mb: 3 }}>
          <Grid item md={5}>
            {(sundayBreakFromValue[i]) ? sundayBreakFromValue[i] : ""}
          </Grid>
          <Grid item md={5}>
            {(sundayBreakToValue[i]) ? sundayBreakToValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ mt: 2 }}>
            <RemoveIcon size="small" fontSize="inherit" onClick={(i) => this.removeBreakTimeElement('Sunday', idx)} />
          </Grid>
        </Grid>
      );
    }

    let holidayArray = [];
    for (i = 0; i < holidayName.length; i++) {
      let idx = i;
      let occursLabel = "";
      if (holidayOccurs[i] && (holidayOccurs[i]) === "1") {
        occursLabel = "Once";
      } else if (holidayOccurs[i] && (holidayOccurs[i]) === "2") {
        occursLabel = "Repeated";
      }
      holidayArray.push(
        <Grid container spacing={5} xs={12} sx={{ mr: 0, mt: 2 }} >
          <Grid item md={3} sx={{ textAlign: 'center' }}>
            {(holidayName[i]) ? holidayName[i] : ""}
          </Grid>
          <Grid item md={3} sx={{ textAlign: 'center' }}>
            {(holidayFromTimeValue[i]) ? holidayFromTimeValue[i] : ""}
          </Grid>
          <Grid item md={3} sx={{ textAlign: 'center' }}>
            {(holidayToTimeValue[i]) ? holidayToTimeValue[i] : ""}
          </Grid>
          <Grid item md={2} sx={{ textAlign: 'center' }}>
            {occursLabel}
          </Grid>
          <Grid item md={1} sx={{ textAlign: 'center' }}>
            <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'red' }}>
              <RemoveIcon fontSize="inherit" onClick={() => this.removeHolidayElement(idx)} />
            </IconButton>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12} >
                    <Grid item md={12}>
                      <h2>Set your office hours and holidays</h2>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ mb: 0 }}>
                    <Grid item md={11} sx={{ verticalAlign: 'top' }}>
                      <h2><i>Office Hours</i></h2>
                    </Grid>
                    <Grid item md={1}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'red' }}>
                        <ClearIcon fontSize="inherit" onClick={() => this.clearTimeOption()} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ ml: 0, mt: 2 }} >
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Monday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Tuesday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Wednesday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Thursday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Friday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Saturday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Sunday</b>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ ml: 0, mt: 2 }}>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setTimeOption('Monday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setTimeOption('Tuesday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setTimeOption('Wednesday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setTimeOption('Thursday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setTimeOption('Friday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setTimeOption('Saturday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setTimeOption('Sunday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ ml: 0, mt: 2 }} >
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {MondayArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {TuesdayArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {WednesdayArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {ThursdayArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {FridayArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {SaturdayArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {SundayArray}
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} ><Grid item md={12} sx={{ borderBottom: 1, ml: 5, borderBottomStyle: 'dotted' }}>&nbsp;</Grid></Grid>

                  <Grid container spacing={5} xs={12} sx={{ mb: 0 }}>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Action</label>
                      <Select
                        fullWidth
                        labelId="officeHoursTimeOutAction"
                        id="officeHoursTimeOutAction"
                        value={officeHoursTimeOutAction}
                        label="officeHoursTimeOutAction"
                        name="officeHoursTimeOutAction"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="1">End Call</MenuItem>
                        {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
                        <MenuItem value="3">User</MenuItem>
                        <MenuItem value="4">Ringgroup</MenuItem>
                        <MenuItem value="5">IVR Menu</MenuItem>
                        <MenuItem value="6">Callcenter Queue</MenuItem>
                        <MenuItem value="7">Recording</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Entry</label>
                      <Select
                        fullWidth
                        labelId="officeHoursTimeOutEntry"
                        id="officeHoursTimeOutEntry"
                        value={officeHoursTimeOutEntry}
                        label="officeHoursTimeOutEntry"
                        name="officeHoursTimeOutEntry"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {officeHoursTimeOutEntryList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Greeting</label>
                      <Select
                        fullWidth
                        labelId="officeHoursGreeting"
                        id="officeHoursGreeting"
                        value={officeHoursGreeting}
                        label="officeHoursGreeting"
                        name="officeHoursGreeting"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {greetingList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} ><Grid item md={12} sx={{ borderBottom: 2, ml: 5 }}>&nbsp;</Grid></Grid>

                  <Grid container spacing={5} xs={12} sx={{ mb: 0, mt: 2 }}>
                    <Grid item md={11} sx={{ verticalAlign: 'top' }}>
                      <h2><i>Break Hours</i></h2>
                    </Grid>
                    <Grid item md={1}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'red' }}>
                        <ClearIcon fontSize="inherit" onClick={() => this.clearBreakTimeOption()} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ ml: 0, mt: 2 }} >
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Monday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Tuesday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Wednesday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Thursday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Friday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Saturday</b>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <b>Sunday</b>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ ml: 0, mt: 2 }}>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setBreakTimeOption('Monday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setBreakTimeOption('Tuesday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setBreakTimeOption('Wednesday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setBreakTimeOption('Thursday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setBreakTimeOption('Friday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setBreakTimeOption('Saturday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="delete" size="small" sx={{ backgroundColor: 'blue' }} onClick={() => this.setBreakTimeOption('Sunday')}>
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ ml: 0, mt: 2 }} >
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {MondayBreakArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {TuesdayBreakArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {WednesdayBreakArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {ThursdayBreakArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {FridayBreakArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {SaturdayBreakArray}
                    </Grid>
                    <Grid item md={1.70} sx={{ textAlign: 'center' }}>
                      {SundayBreakArray}
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} ><Grid item md={12} sx={{ borderBottom: 1, ml: 5, borderBottomStyle: 'dotted' }}>&nbsp;</Grid></Grid>

                  <Grid container spacing={5} xs={12} sx={{ mb: 0 }}>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Action</label>
                      <Select
                        fullWidth
                        labelId="breakHoursTimeOutAction"
                        id="breakHoursTimeOutAction"
                        value={breakHoursTimeOutAction}
                        label="breakHoursTimeOutAction"
                        name="breakHoursTimeOutAction"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="1">End Call</MenuItem>
                        {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
                        <MenuItem value="3">User</MenuItem>
                        <MenuItem value="4">Ringgroup</MenuItem>
                        <MenuItem value="5">IVR Menu</MenuItem>
                        <MenuItem value="6">Callcenter Queue</MenuItem>
                        <MenuItem value="7">Recording</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Entry</label>
                      <Select
                        fullWidth
                        labelId="breakHoursTimeOutEntry"
                        id="breakHoursTimeOutEntry"
                        value={breakHoursTimeOutEntry}
                        label="breakHoursTimeOutEntry"
                        name="breakHoursTimeOutEntry"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {breakHoursTimeOutEntryList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Greeting</label>
                      <Select
                        fullWidth
                        labelId="breakHoursGreeting"
                        id="breakHoursGreeting"
                        value={breakHoursGreeting}
                        label="breakHoursGreeting"
                        name="breakHoursGreeting"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {greetingList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} ><Grid item md={12} sx={{ borderBottom: 2, ml: 5 }}>&nbsp;</Grid></Grid>

                  <Grid container spacing={5} xs={12} >
                    <Grid item md={11} sx={{ verticalAlign: 'top' }}>
                      <h2><i>Holidays</i></h2>
                    </Grid>
                    <Grid item md={1}>
                      <IconButton aria-label="add" size="small" sx={{ backgroundColor: 'blue' }}>
                        <AddIcon fontSize="inherit" onClick={() => this.setHolidaysOption()} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ mr: 0, mt: 2 }} >
                    <Grid item md={3} sx={{ textAlign: 'center' }}>
                      <b>Name</b>
                    </Grid>
                    <Grid item md={3} sx={{ textAlign: 'center' }}>
                      <b>From Date</b>
                    </Grid>
                    <Grid item md={3} sx={{ textAlign: 'center' }}>
                      <b>To Date</b>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'center' }}>
                      <b>Occurs</b>
                    </Grid>
                    <Grid item md={1} sx={{ textAlign: 'center' }}>
                      <b>Remove</b>
                    </Grid>
                  </Grid>

                  {holidayArray}

                  <Grid container spacing={5} xs={12} ><Grid item md={12} sx={{ borderBottom: 1, ml: 5, borderBottomStyle: 'dotted' }}>&nbsp;</Grid></Grid>

                  <Grid container spacing={5} xs={12} sx={{ mb: 0 }}>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Action</label>
                      <Select
                        fullWidth
                        labelId="holidayTimeOutAction"
                        id="holidayTimeOutAction"
                        value={holidayTimeOutAction}
                        label="holidayTimeOutAction"
                        name="holidayTimeOutAction"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="1">End Call</MenuItem>
                        {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
                        <MenuItem value="3">User</MenuItem>
                        <MenuItem value="4">Ringgroup</MenuItem>
                        <MenuItem value="5">IVR Menu</MenuItem>
                        <MenuItem value="6">Callcenter Queue</MenuItem>
                        <MenuItem value="7">Recording</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Entry</label>
                      <Select
                        fullWidth
                        labelId="holidayTimeOutEntry"
                        id="holidayTimeOutEntry"
                        value={holidayTimeOutEntry}
                        label="holidayTimeOutEntry"
                        name="holidayTimeOutEntry"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {holidayTimeOutEntryList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item md={1}>
                      &nbsp;
                    </Grid>
                    <Grid item md={3}>
                      <label>Greeting</label>
                      <Select
                        fullWidth
                        labelId="holidaysGreeting"
                        id="holidaysGreeting"
                        value={holidaysGreeting}
                        label="holidaysGreeting"
                        name="holidaysGreeting"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {greetingList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} ><Grid item md={12} sx={{ borderBottom: 2, ml: 5 }}>&nbsp;</Grid></Grid>

                  <Grid container spacing={5} xs={12} sx={{ mb: 0, mt: 2 }}>
                    <Grid item md={11} sx={{ verticalAlign: 'top' }}>
                      <h2><i>General information</i></h2>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} sx={{ mb: 0, mt: 2 }}>
                    <Grid item md={6}>
                      <label>Time Zone</label>
                      <Select
                        fullWidth
                        labelId="timeZoneValue"
                        id="timeZoneValue"
                        value={timeZoneValue}
                        label="timeZoneValue"
                        name="timeZoneValue"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {timeZoneList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item md={6} sx={{ width: 100 }}>
                      <label>DID</label>
                      <Select
                        fullWidth
                        labelId="didNumber"
                        id="didNumber"
                        value={didNumber}
                        label="didNumber"
                        name="didNumber"
                        onChange={this.onChangeItem}
                        size="small"
                        multiple
                        autoComplete="off"
                      >
                        {didNumberList.map((item) => {
                          return (
                            <MenuItem key={item.did_uuid} value={item.did_uuid}>
                              {item.did_number}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} ><Grid item md={12} sx={{ borderBottom: 2, ml: 5 }}>&nbsp;</Grid></Grid>

                  {alertMessage ? (
                    <Stack
                      className="error-show"
                      sx={{ width: "100%" }}
                      spacing={2}
                    >
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}

                  <Grid container justifyContent="flex-end" sx={{ paddingTop: 5 }}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          {((sessionStorage.getItem('TIME_ROUTING') & AppConfig.EDIT_ACTION) === AppConfig.EDIT_ACTION) ?
                            <Button
                              type="submit"
                              fullWidth
                              className="btn btn-primary"
                              sx={{
                                mt: 1,
                                mb: 1,
                                height: "35px",
                                padding: "5px 20px",
                              }}
                              size="small"
                            >
                              Submit
                            </Button> : ""}
                        </td>
                      </tr>
                    </table>
                  </Grid>

                  <div>
                    <Dialog
                      open={open}
                      onClose={this.handleTimeClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      id="messageDialog"
                    >
                      <DialogTitle id="alert-dialog-title">Set Office Time in {dayName}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={5}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={[
                                    'TimePicker',
                                    'MobileTimePicker',
                                    'DesktopTimePicker',
                                    'StaticTimePicker',
                                  ]}
                                >
                                  <DesktopTimePicker name="fromValue" id="fromValue" size="small" className="iconBgcolor"
                                    onChange={(newValue) => {
                                      this.onChangeDateItem("fromValue", newValue);
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                            <Grid item md={1} sx={{ mt: 3 }}> -</Grid>
                            <Grid item md={5}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={[
                                    'TimePicker',
                                    'MobileTimePicker',
                                    'DesktopTimePicker',
                                    'StaticTimePicker',
                                  ]}
                                >
                                  <DesktopTimePicker name="toValue" id="toValue" size="small" className="iconBgcolor"
                                    onChange={(newValue) => {
                                      this.onChangeDateItem("toValue", newValue);
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => this.addTimeAction()}
                          className="btn-primary"
                          autoFocus
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={this.handleTimeClose}
                          autoFocus
                          className="btn-outline-white"
                        >
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                  <div>
                    <Dialog
                      open={openBreak}
                      onClose={this.handleBreakTimeClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      id="messageDialog"
                    >
                      <DialogTitle id="alert-dialog-title">Set Break Time in {dayBreakName}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={5}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={[
                                    'TimePicker',
                                    'MobileTimePicker',
                                    'DesktopTimePicker',
                                    'StaticTimePicker',
                                  ]}
                                >
                                  <DesktopTimePicker name="fromValue" id="fromValue" size="small" className="iconBgcolor"
                                    onChange={(newValue) => {
                                      this.onChangeDateItem("fromValue", newValue);
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                            <Grid item md={1} sx={{ mt: 3 }}> -</Grid>
                            <Grid item md={5}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={[
                                    'TimePicker',
                                    'MobileTimePicker',
                                    'DesktopTimePicker',
                                    'StaticTimePicker',
                                  ]}
                                >
                                  <DesktopTimePicker name="toValue" id="toValue" size="small" className="iconBgcolor"
                                    onChange={(newValue) => {
                                      this.onChangeDateItem("toValue", newValue);
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => this.addTimeBreakAction()}
                          className="btn-primary"
                          autoFocus
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={this.handleBreakTimeClose}
                          autoFocus
                          className="btn-outline-white"
                        >
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                  <div>
                    <Dialog
                      open={openHolidays}
                      onClose={this.handleHolidayClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      id="messageDialog"

                    >
                      <DialogTitle id="alert-dialog-title">Set Holidays</DialogTitle>
                      <DialogContent sx={{ width: '500px' }}>
                        <DialogContentText id="alert-dialog-description">
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>Name</Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>
                              <TextField
                                margin="normal"
                                required
                                id="holidayname"
                                name="holidayname"
                                value={holiday_name ?? ""}
                                onChange={this.onChangeItem}
                                autoComplete="off"
                                type="text"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>&nbsp;</Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>
                              <label>From Date</label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={[
                                    'DesktopDateTimePicker',
                                  ]}
                                  sx={{
                                    width: '100%',
                                    mt: 0
                                  }}
                                >
                                  <DesktopDateTimePicker
                                    name="fromValue"
                                    id="fromValue"
                                    formatDensity="-"
                                    ampm={false}
                                    onChange={(newValue) => {
                                      this.onChangeDateTimeItem("fromValue", newValue);
                                    }}
                                    format="YYYY-MM-DD HH:mm"
                                    timeSteps={
                                      {
                                        'minutes': '1',
                                        'seconds': '1'
                                      }
                                    }
                                    slotProps={
                                      {
                                        actionBar: {
                                          actions: ["today", "accept", "cancel"]
                                        },
                                        width: 200
                                      }
                                    }
                                    sx={{
                                      width: '95%',
                                    }}
                                    className="iconBgcolor"
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                    size="small"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>&nbsp;</Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>
                              <label>To Date</label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={[
                                    'DesktopDateTimePicker',
                                  ]}
                                  sx={{
                                    width: '100%',
                                    mt: 0
                                  }}
                                >
                                  <DesktopDateTimePicker
                                    name="toValue"
                                    id="toValue"
                                    formatDensity="-"
                                    ampm={false}
                                    onChange={(newValue) => {
                                      this.onChangeDateTimeItem("toValue", newValue);
                                    }}
                                    format="YYYY-MM-DD HH:mm"
                                    timeSteps={
                                      {
                                        'minutes': '1',
                                        'seconds': '1'
                                      }
                                    }
                                    slotProps={
                                      {
                                        actionBar: {
                                          actions: ["today", "accept", "cancel"]
                                        },
                                        width: 200
                                      }
                                    }
                                    sx={{
                                      width: '95%',
                                    }}
                                    className="iconBgcolor"
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                    size="small"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>&nbsp;</Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>Occurs</Grid>
                          </Grid>
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>
                              <Select
                                labelId="occursValue"
                                id="occursValue"
                                value={
                                  occurs
                                }
                                name="occursValue"
                                onChange={this.onChangeItem}
                                size="small"
                              >
                                <MenuItem value="1">Once</MenuItem>
                                <MenuItem value="2">Repeated</MenuItem>
                              </Select>
                            </Grid>
                          </Grid>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => this.addHolidayAction()}
                          className="btn-primary"
                          autoFocus
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={this.handleHolidayClose}
                          autoFocus
                          className="btn-outline-white"
                        >
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                  <div>
                    <Dialog
                      open={openDialog}
                      onClose={() => this.actionPopup(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      id="messageDialog"

                    >
                      <DialogTitle id="alert-dialog-title">Update</DialogTitle>
                      <DialogContent sx={{ width: '500px' }}>
                        <DialogContentText id="alert-dialog-description">
                          <Grid container spacing={5} xs={12}>
                            <Grid item md={10}>Record updated successfully...</Grid>
                          </Grid>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => this.actionPopup(false)}
                          className="btn-primary"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>

      </div >
    );
  }
}

export default withRouter(TimingeditData);
