import React, { Component } from "react";
import { BoxDtmf } from "./atoms";
import Removebutton from "./Removebutton";
import Calltransbutton from "./Calltransbutton";
import Backbutton from "./Backbutton";

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      tansferNumber,
      enterTransferNumber,
      removeTransferNumber,
      number,
      onTransferCallStarted,
      opened,
      callComponent,
    } = this.props;
    return (
      <BoxDtmf opened={opened} className="dialer-setup">
        <table
          style={{ width: "100%", color: "white", "minHeight": "350px" }}
          border="0"
        >
          <tr>
            <td colSpan={3} style={{ width: "100%", textAlign: "center" }}>
              {number !== "" ? number : "\u00A0"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={3}
              style={{
                width: "100%",
                textAlign: "center",
                color: "#624bff",
                height: "30px",
                background: "#454f5b",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            >
              {tansferNumber !== "" ? tansferNumber : "\u00A0"}
            </td>
          </tr>
          <tr>
            <td colSpan={3} height="10px"></td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(1)}
              >
                1
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(2)}
              >
                2
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(3)}
              >
                3
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(4)}
              >
                4
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(5)}
              >
                5
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(6)}
              >
                6
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(7)}
              >
                7
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(8)}
              >
                8
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(9)}
              >
                9
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber("*")}
              >
                *
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber(0)}
              >
                0
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div
                className="dial-number"
                onClick={() => enterTransferNumber("#")}
              >
                #
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              {
                <Backbutton
                  number={number}
                  removeNumber={() => callComponent(1)}
                />
              }
            </td>
            <td
              style={{ width: "33%", "textAlign": "center" }}
              className="call-connect"
            >
              {tansferNumber !== "" ? (
                <Calltransbutton
                  onTransferCallStarted={() =>
                    onTransferCallStarted(tansferNumber)
                  }
                />
              ) : (
                ""
              )}
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              {tansferNumber !== "" ? (
                <Removebutton
                  number={tansferNumber}
                  removeNumber={() => removeTransferNumber()}
                />
              ) : (
                ""
              )}
            </td>
          </tr>
        </table>
      </BoxDtmf>
    );
  }
}

export default Transfer;
