import React, { Component } from "react";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";

class Callbutton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Button: styled.button`
        padding-top: 5px;
        padding-bottom: 5px;
        justify-content: center;
        bottom: 15px;
        background: #624bff;
        border: none;
        color: #fff;
        border-radius: 22.5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        z-index: 11;

        &:hover {
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
        }
      `,
    };
  }

  StyledButton = () => {
    let { Button } = this.state;
    let { onCallStarted } = this.props;
    return (
      <Button onClick={onCallStarted}>
        <MaterialIcon icon="call" color="#FFF" size={24} />
      </Button>
    );
  };

  render() {
    return <div>{this.StyledButton()}</div>;
  }
}

export default Callbutton;
