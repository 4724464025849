import React, { Component } from "react";
import { BoxReciever } from "./atoms";
import Endcallbutton from "./Endcallbutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlog,
  faRotate,
  faUsersLine
} from "@fortawesome/free-solid-svg-icons";

let interval = "";
class Eavesdrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 0,
      second: 0,
    };
  }

  componentDidMount = () => {
    this.intervalFun();
  };

  componentWillUnmount = () => {
    clearInterval(interval);
  };

  intervalFun = () => {
    interval = setInterval(() => {
      let { minutes, second } = this.state;
      let newSecond = second === 59 ? 0 : second + 1;
      let newMinutes = newSecond === 59 ? minutes + 1 : minutes;
      this.setState({ second: newSecond, minutes: newMinutes });
    }, 1000);
  };

  render() {
    let { minutes, second } = this.state;
    let strMinutes = minutes > 9 ? minutes : `0${minutes}`;
    let strsecond = second > 9 ? second : `0${second}`;
    let timer = `${strMinutes}:${strsecond}`;
    let { number, onCallEnded, opened, displayName, eavesdropAction } =
      this.props;
    return (
      <BoxReciever opened={opened} className="dialer-setup receiver-dialer">
        <table
          style={{ width: "100%", color: "white", "minHeight": "100%" }}
          border="0"
        >
          {
            displayName !== "" ?
              <tr>
                <td colSpan={2} style={{ width: "100%", textAlign: "center" }}>
                  {displayName}
                </td>
              </tr> : ""
          }

          {
            number !== "" ?
              <tr>
                <td colSpan={2} style={{ width: "100%", textAlign: "center" }}>
                  {number}
                </td>
              </tr> : ""
          }
          <tr>
            <td colSpan={2} style={{ width: "100%", textAlign: "center" }}>
              {timer}
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon icon={faBlog} onClick={() => eavesdropAction("local")} />
              </div>
              <div className="numberFontSizeReciever">
                Local
              </div>
            </td>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon icon={faBlog} onClick={() => eavesdropAction("remote")} />
              </div>
              <div className="numberFontSizeReciever">
                Remote
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon icon={faRotate} onClick={() => eavesdropAction("restore")} />
              </div>
              <div className="numberFontSizeReciever">
                Restore
              </div>
            </td>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon icon={faUsersLine} onClick={() => eavesdropAction("conference")} />
              </div>
              <div className="numberFontSizeReciever">
                Conference
              </div>
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "0 !important",
              }}
              className="endCall"
            >
              <Endcallbutton onCallEnded={onCallEnded} />{" "}
            </td>
          </tr>
        </table>
      </BoxReciever >
    );
  }
}

export default Eavesdrop;
