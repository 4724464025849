import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Grid from "@mui/material/Grid";
import "../../Assets/index.css";
import { withRouter } from "../withRouter";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import agentimage from "../../Assets/bgimage/agent.png"



const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.background.paper}`,
}));


let intervalDataAgentList = "";


class Agentlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowListAgent: [],
    };
  }

  componentDidMount = async () => {
    this.getRecord();
    intervalDataAgentList = setInterval(() => {
      this.getRecord();
    }, 60000);
  };

  getRecord = async () => {
    let { queue_extension } = this.props;
    let response = await commonPostAPI("agentlist", {
      extension: queue_extension,
    });
    if (response && response.length > 0) {
      this.setState({ rowListAgent: response });
    } else {
      this.setState({ rowListAgent: [] });
    }
  }

  componentWillUnmount = () => {
    clearInterval(intervalDataAgentList);
  }

  agentAvatar = (props) => {
    const StyledBadge = styled(Badge)(({ theme }) => (
      {
        '& .MuiBadge-badge': {
          backgroundColor: props.color,
          color: props.color,
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }
    ));

    return <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      presenceColor="#FF0000"
    >
      <SmallAvatar alt="Remy Sharp" src={agentimage} />
    </StyledBadge>;
  }

  render() {
    let { rowListAgent } = this.state;
    return (
      <div>
        <Grid
          container
          spacing={2}
          sx={{ mt: 1, mb: 1, ml: 1, mr: 1, height: "10%", width: "90%" }}
        >
          {rowListAgent.length > 0
            ? rowListAgent.map((item) => {
              const obj = {
                color: "#44b700",
                label: item.agent_name,
              }
              return (
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 1,
                    mb: 1,
                    height: "100%",
                    width: "70%",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    fontSize: 12,
                    bgcolor: "#E8E6E6",
                    "border-radius": 10,
                  }}
                >

                  {this.agentAvatar(obj)}
                  {item.agent_name}<br />
                  {(item.agent_status && item.agent_status === 'Available') ? `${item.agent_status}(${item.agent_state})` : `${item.agent_status}`}
                </Grid>
              );
            })
            : ""}
        </Grid>
      </div>
    );
  }
}

export default withRouter(Agentlist);
