import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};

class CampaignaddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        campaign_name: "Please enter campaign name.",
      },
      alertMessage: "",
      campaign_name: "",
      campaign_type: "inbound",
      campaign_type_style: "",
      dial_speed: "1",
      gateway: "",
      queue_greeting: "0",
      status: "true",
      caller_id: "",
      gatewayList: [],
      recordingList: [],
      queue_max_wait_time: "0",
      musicOnHold: "0",
      queue_strategy: "ring-all",
      queue_timeout: "1",
      queue_timeout_entry: "hangup:",
      timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      templateList: [],
      dipsogroupList: [],
      template_uuid: "",
      dispo_group_uuid: "",
      outbound_style: "none",
      dial_list_uuid: "",
      dial_list_array: [],
      start_time: "0",
      stop_time: "0",
      wrap_up_time: "30",
      auto_answer: "false",
      sticky_agent: "false",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Campaign",
          action: "campaignlist",
        },
        {
          label: "Add Campaign Data",
          action: "CampaignaddData",
        },
      ],
    };
  }

  componentWillMount = async () => {
    let pageObj = {
      domain_uuid: sessionStorage.getItem("domain_uuid")
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("destinations", pageObj);
    this.setState({ gatewayList: response });

    response = await commonPostAPI("recordings", pageObj);
    this.setState({ recordingList: response });

    let templateList = await commonPostAPI("templateList", pageObj);
    this.setState({ templateList: templateList });

    let dipsogroupList = await commonPostAPI("dipsogroupList", pageObj);
    this.setState({ dipsogroupList: dipsogroupList });

    let dialList = await commonPostAPI("getCampaignleadList", pageObj);
    this.setState({ dial_list_array: dialList });
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });

      let requestparams = {
        campaign_name: this.state.campaign_name,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        campaign_type: (AppConfig.PBX_ONLY !== true) ? this.state.campaign_type : "inbound",
        dial_speed: (this.state.campaign_type === 'outbound') ? this.state.dial_speed : "1",
        gateway: (this.state.campaign_type === 'outbound') ? this.state.gateway : "",
        queue_greeting: (this.state.queue_greeting !== "0") ? this.state.queue_greeting : "",
        status: this.state.status,
        caller_id: this.state.caller_id,
        queue_strategy: (this.state.campaign_type === 'inbound') ? this.state.queue_strategy : "top-down",
        queue_max_wait_time: (this.state.campaign_type === 'inbound') ? this.state.queue_max_wait_time : "30",
        musicOnHold: (this.state.campaign_type === 'inbound' && this.state.musicOnHold !== "0") ? this.state.musicOnHold : "",
        queue_timeout: (this.state.campaign_type === 'inbound') ? this.state.queue_timeout : "1",
        queue_timeout_entry: (this.state.campaign_type === 'inbound') ? this.state.queue_timeout_entry : "hangup",
        template_uuid: this.state.template_uuid,
        dispo_group_uuid: this.state.dispo_group_uuid,
        dial_list_uuid: (this.state.campaign_type !== 'inbound') ? this.state.dial_list_uuid : "",
        domain_name: sessionStorage.getItem("domain_name"),
        start_time: (this.state.campaign_type !== 'inbound' && this.state.start_time !== "0") ? this.state.start_time : "00:00",
        stop_time: (this.state.campaign_type !== 'inbound' && this.state.stop_time !== "0") ? this.state.stop_time : "00:00",
        wrap_up_time: (this.state.wrap_up_time !== "" && this.state.wrap_up_time !== "0") ? this.state.wrap_up_time : 30,
        auto_answer: this.state.auto_answer,
        sticky_agent: this.state.sticky_agent,
      };
      if (
        this.state.queue_timeout !== "1" &&
        this.state.queue_timeout_entry === "0" &&
        this.state.campaign_type === "inbound"
      ) {
        this.setState({ alertMessage: <div>Please select entry.</div> });
      } else {
        let response = await commonPostAPI("addcampaign", requestparams);
        if (response.campaign_name && response.campaign_name !== "") {
          this.setState({ alertMessage: <div>Name already exists.</div> });
        } else {
          this.onOpenAction("campaignlist");
        }
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  handleChangeMultiple = (e) => {
    const selected = e.target.value;
    this.setState({ users: selected });
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    if (e.target.name === "queue_timeout") {
      this.timeOutActionChange(e);
    }
    let errors = this.state.errors;

    if (e.target.name === 'campaign_type') {
      if (e.target.value === 'outbound') {
        partialState['campaign_type_style'] = 'none';
        partialState['outbound_style'] = '';
      } else {
        partialState['campaign_type_style'] = '';
        partialState['outbound_style'] = 'none';
      }
    }

    switch (e.target.name) {
      case "campaign_name":
        errors.campaign_name =
          e.target.value.length < 1 ? "Please enter campaign name." : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  timeOutActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({ timeOutEntryList: response, queue_timeout_entry: "0" });
    } else {
      this.setState({
        timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
        queue_timeout_entry: "0",
      });
    }
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      alertMessage,
      campaign_name,
      campaign_type,
      dial_speed,
      gateway,
      queue_greeting,
      status,
      caller_id,
      gatewayList,
      recordingList,
      queue_strategy,
      queue_max_wait_time,
      musicOnHold,
      queue_timeout,
      queue_timeout_entry,
      timeOutEntryList,
      templateList,
      dipsogroupList,
      template_uuid,
      dispo_group_uuid,
      campaign_type_style,
      outbound_style,
      dial_list_uuid,
      dial_list_array,
      start_time,
      stop_time,
      wrap_up_time,
      auto_answer,
      sticky_agent,
    } = this.state;

    let minutesList = [
      "00", "15", "30", "45",
    ];

    let timeList = [];
    for (var i = 0; i <= 23; i++) {
      minutesList.filter((rowData) => {
        let x = (i < 10) ? `0${i}` : i;
        timeList.push(`${x}:${rowData}`);
        return true;
      });
    }
    timeList.push('23:59');

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Campaign Name</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="campaign_name"
                        name="campaign_name"
                        value={campaign_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                      {errors.campaign_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.campaign_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item md={3}>
                      <label>Auto Answer</label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="auto_answer"
                            id="auto_answer"
                            value={auto_answer}
                            label="auto_answer"
                            name="auto_answer"
                            onChange={this.onChangeItem}
                            size="small"
                          >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={3}>
                      <label>WrapUp Time</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="wrap_up_time"
                        name="wrap_up_time"
                        value={wrap_up_time ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="number"
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Caller ID</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="caller_id"
                        name="caller_id"
                        value={caller_id ?? ""}
                        onChange={this.onChangeItem}
                        autoFocus
                        type="text"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item md={3}>
                      <label>Status</label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="status"
                            id="status"
                            value={status}
                            label="status"
                            name="status"
                            onChange={this.onChangeItem}
                            size="small"
                          >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">In Active</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={3}>
                      <label>Sticky Agent</label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="sticky_agent"
                            id="sticky_agent"
                            value={sticky_agent}
                            label="sticky_agent"
                            name="sticky_agent"
                            onChange={this.onChangeItem}
                            size="small"
                          >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={3} style={{ display: outbound_style }}>
                      <label>Dial Speed</label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="status"
                            id="dial_speed"
                            value={dial_speed}
                            label="dial_speed"
                            name="dial_speed"
                            onChange={this.onChangeItem}
                            size="small"
                          >
                            <MenuItem value="1">Low</MenuItem>
                            <MenuItem value="2">Medium</MenuItem>
                            <MenuItem value="3">High</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {AppConfig.PBX_ONLY !== true ? (
                    <Grid container spacing={5} xs={12}>
                      <Grid item md={3}>
                        <label>Type</label>
                        <Select
                          fullWidth
                          labelId="campaign_type"
                          id="campaign_type"
                          value={campaign_type}
                          label="Enabled"
                          name="campaign_type"
                          onChange={this.onChangeItem}
                          size="small"
                          autoComplete="off"
                        >
                          <MenuItem value="inbound">Inbound</MenuItem>
                          <MenuItem value="outbound">Outbound</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} style={{ display: outbound_style }}>
                        <label>Campaign Lead List</label>
                        <Select
                          fullWidth
                          labelId="dial_list_uuid"
                          id="dial_list_uuid"
                          value={dial_list_uuid}
                          label="Enabled"
                          name="dial_list_uuid"
                          onChange={this.onChangeItem}
                          size="small"
                          sx={{ mt: 2 }}
                          autoComplete="off"
                        >
                          <MenuItem value="">Select</MenuItem>
                          {dial_list_array.map((item) => (
                            <MenuItem value={item.dial_list_uuid}>{item.list_name}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item md={3}>
                        <label>
                          CRM Template
                        </label>
                        <Select
                          fullWidth
                          labelId="template_uuid"
                          id="template_uuid"
                          value={template_uuid}
                          label="template_uuid"
                          name="template_uuid"
                          onChange={this.onChangeItem}
                          size="small"
                          autoComplete="off"
                        >
                          <MenuItem value="0">Select</MenuItem>
                          {templateList.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item md={3}>
                        <label>
                          Dispo Group
                        </label>
                        <Select
                          fullWidth
                          labelId="dispo_group_uuid"
                          id="dispo_group_uuid"
                          value={dispo_group_uuid}
                          label="dispo_group_uuid"
                          name="dispo_group_uuid"
                          onChange={this.onChangeItem}
                          size="small"
                          autoComplete="off"
                        >
                          <MenuItem value="0">Select</MenuItem>
                          {dipsogroupList.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>) : ""}

                  <Grid container spacing={5} xs={12} style={{ display: campaign_type_style }}>
                    <Grid item md={3}>
                      <label>Greeting</label>
                      <Select
                        fullWidth
                        labelId="queue_greeting"
                        id="queue_greeting"
                        value={queue_greeting}
                        label="Enabled"
                        name="queue_greeting"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {recordingList.map((item) => (
                          <MenuItem value={item.recording_filename}>{item.recording_filename}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>Strategy</label>
                      <Select
                        fullWidth
                        labelId="queue_strategy"
                        id="queue_strategy"
                        value={queue_strategy}
                        label="queue_strategy"
                        name="queue_strategy"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="ring-all">Ring All</MenuItem>
                        <MenuItem
                          value="longest-idle-agent"
                          selected="selected"
                        >
                          Longest Idle Agent
                        </MenuItem>
                        <MenuItem value="round-robin">Round Robin</MenuItem>
                        <MenuItem value="top-down">Top Down</MenuItem>
                        <MenuItem value="agent-with-least-talk-time">
                          Agent With Least Talk Time
                        </MenuItem>
                        <MenuItem value="agent-with-fewest-calls">
                          Agent With Fewest Calls
                        </MenuItem>
                        <MenuItem value="sequentially-by-agent-order">
                          Sequentially By Agent Order
                        </MenuItem>
                        <MenuItem value="sequentially-by-next-agent-order">
                          Sequentially By Next Agent Order
                        </MenuItem>
                        <MenuItem value="random">Random</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Music On Hold
                      </label>
                      <Select
                        fullWidth
                        labelId="musicOnHold"
                        id="musicOnHold"
                        value={musicOnHold}
                        label="musicOnHold"
                        name="musicOnHold"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {recordingList.map((item) => (
                          <MenuItem value={item.recording_filename}>{item.recording_filename}</MenuItem>
                        ))}
                      </Select>

                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12} style={{ display: campaign_type_style }}>

                    <Grid item md={3}>
                      <label>
                        Timeout Action
                      </label>
                      <Select
                        fullWidth
                        labelId="queue_timeout"
                        id="queue_timeout"
                        value={queue_timeout}
                        label="queue_timeout"
                        name="queue_timeout"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="1">End Call</MenuItem>
                        {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
                        <MenuItem value="3">User</MenuItem>
                        <MenuItem value="4">Ringgroup</MenuItem>
                        <MenuItem value="5">IVR Menu</MenuItem>
                        <MenuItem value="6">Callcenter Queue</MenuItem>
                        <MenuItem value="7">Recording</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Entry
                      </label>
                      <Select
                        fullWidth
                        labelId="queue_timeout_entry"
                        id="queue_timeout_entry"
                        value={queue_timeout_entry}
                        label="queue_timeout_entry"
                        name="queue_timeout_entry"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {timeOutEntryList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>Max Wait Time</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="queue_max_wait_time"
                        label=""
                        name="queue_max_wait_time"
                        value={queue_max_wait_time ?? ""}
                        onChange={this.onChangeItem}
                        autoFocus
                        type="number"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>


                  <Grid container spacing={5} xs={12} style={{ display: outbound_style }}>
                    <Grid item md={3}>
                      <label>Gateway</label>
                      <Select
                        fullWidth
                        labelId="gateway"
                        id="gateway"
                        value={gateway}
                        label="Enabled"
                        name="gateway"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {gatewayList.map((item) => (
                          <MenuItem value={item.route_name}>{item.route_name}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>State Time</label>
                      <Select
                        fullWidth
                        labelId="start_time"
                        id="start_time"
                        value={start_time}
                        label="Enabled"
                        name="start_time"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {timeList.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={3}>
                      <label>Stop Time</label>
                      <Select
                        fullWidth
                        labelId="stop_time"
                        id="stop_time"
                        value={stop_time}
                        label="Enabled"
                        name="stop_time"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {timeList.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </Grid>

                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item xs={4} sx={{ mt: 5 }}>
                      {alertMessage ? (
                        <Stack className="error-show" spacing={2}>
                          {alertMessage}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} justifyContent="flex-end" container>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("campaignlist")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(CampaignaddData);
