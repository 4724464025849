import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faMicrophoneLines,
  faPlus,
  faUsersLine,
  faShuffle,
  faRecordVinyl,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { BoxReciever } from "./atoms";
import Endcallbutton from "./Endcallbutton";

class Reciever extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { minutes, second } = this.props;
    let strMinutes = minutes > 9 ? minutes : `0${minutes}`;
    let strsecond = second > 9 ? second : `0${second}`;
    let timer = `${strMinutes}:${strsecond}`;
    let {
      number,
      onCallEnded,
      callComponent,
      opened,
      muteMethod,
      isMute,
      holdMethod,
      isHold,
      attTransferEnabled,
      onConferenceCall,
      call_state,
      onRecordingCall,
      recordingIconFlag,
    } = this.props;
    return (
      <BoxReciever opened={opened} className="dialer-setup receiver-dialer">
        <table
          style={{ width: "100%", color: "white", "minHeight": "350px" }}
          border="0"
        >
          <tr>
            <td
              colSpan={3}
              style={{
                width: "100%",
                textAlign: "center",
                background: "#454f5b",
                height: "30px",
                borderRadius: "5px",
              }}
            >
              {number !== "" ? number : "\u00A0"}
            </td>
          </tr>
          <tr>
            <td
              colSpan={3}
              style={{ width: "100%", textAlign: "center", height: "40px" }}
            >
              {(call_state === "") ? timer : call_state}
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon icon={faPause} onClick={() => holdMethod()} />
              </div>
              <div className="numberFontSizeReciever">
                {!isHold ? "Hold" : "UnHold"}
              </div>
            </td>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon
                  icon={faMicrophoneLines}
                  onClick={() => muteMethod()}
                />
              </div>
              <div className="numberFontSizeReciever">
                {!isMute ? "Mute" : "UnMute"}
              </div>
            </td>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="numberFontSizeReciever">New Call</div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%", textAlign: "center" }}>
              {attTransferEnabled === true ? (
                <div>
                  <div>
                    <FontAwesomeIcon
                      icon={faUsersLine}
                      onClick={() => onConferenceCall()}
                    />
                  </div>
                  <div className="numberFontSizeReciever">Conference</div>
                </div>
              ) : (
                <div>
                  <div>
                    <FontAwesomeIcon
                      icon={faUsersLine}
                      style={{ 'pointer-events': 'none', color: 'grey' }}
                    />
                  </div>
                  <div className="numberFontSizeReciever" style={{ color: 'grey' }}>Conference</div>
                </div>
              )}
            </td>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon
                  icon={faShuffle}
                  onClick={() => callComponent(3)}
                />
              </div>
              <div className="numberFontSizeReciever">Transfer</div>
            </td>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon
                  icon={faShuffle}
                  onClick={() => callComponent(4)}
                />
              </div>
              <div className="numberFontSizeReciever">Att.Transfer</div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "100%", textAlign: "center" }}>
              {
                (recordingIconFlag === true) ?
                  <div>
                    <FontAwesomeIcon icon={faRecordVinyl} onClick={() => onRecordingCall()} />
                  </div> : <div>
                    <FontAwesomeIcon icon={faRecordVinyl} style={{ 'pointer-events': 'none', color: 'grey' }} />
                  </div>
              }
              <div className="numberFontSizeReciever">Recording</div>
            </td>
            <td style={{ width: "100%", textAlign: "center" }}>
              <div>
                <FontAwesomeIcon
                  icon={faList}
                  onClick={() => callComponent(2)}
                />
              </div>
              <div className="numberFontSizeReciever">Keypad</div>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td
              className="endCall"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Endcallbutton onCallEnded={onCallEnded} />{" "}
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </BoxReciever>
    );
  }
}

export default Reciever;
