import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};
class IvrmenuaddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        ivr_menu_name: "Please enter name.",
        ivr_menu_timeout: "",
        ivr_menu_timeout_entry: "",
        ivr_menu_enabled: "",
        ivrtimeout: "",
      },
      alertMessage: "",
      ivr_menu_name: "",
      ivr_menu_timeout: "1",
      ivr_menu_timeout_entry: "hangup:",
      ivr_menu_enabled: true,
      ivrmenu_greeting: "0",
      ivrmenu_greetingList: [],
      timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      ivr_menu_timeout_option_name: [
        "option_0",
        "option_1",
        "option_2",
        "option_3",
        "option_4",
        "option_5",
        "option_6",
        "option_7",
        "option_8",
        "option_9",
      ],
      ivr_menu_timeout_option: [
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
      ],
      ivr_menu_timeout_option_entry_name: [
        "option_entry_0",
        "option_entry_1",
        "option_entry_2",
        "option_entry_3",
        "option_entry_4",
        "option_entry_5",
        "option_entry_6",
        "option_entry_7",
        "option_entry_8",
        "option_entry_9",
      ],
      ivr_menu_timeout_option_entry: [
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
      ],
      timeOutOptionEntryList: [[], [], [], [], [], [], [], [], [], []],
      ivrtimeout: 3000,
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "IVR Menu",
          action: "ivrmenu",
        },
        {
          label: "Add IVR Menu",
          action: "ivrmenuadd",
        },
      ],
    };
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        ivr_menu_name: this.state.ivr_menu_name,
        ivr_menu_timeout: this.state.ivr_menu_timeout,
        ivr_menu_timeout_entry: this.state.ivr_menu_timeout_entry,
        ivr_menu_timeout_option: this.state.ivr_menu_timeout_option,
        ivr_menu_timeout_option_entry: this.state.ivr_menu_timeout_option_entry,
        ivrmenu_greeting: this.state.ivrmenu_greeting,
        ivr_menu_enabled: this.state.ivr_menu_enabled,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        domain_name: sessionStorage.getItem("domain_name"),
        ivrtimeout: this.state.ivrtimeout,
      };
      if (
        this.state.ivr_menu_timeout !== "1" &&
        this.state.ivr_menu_timeout_entry === "0"
      ) {
        this.setState({ alertMessage: <div>Please select entry.</div> });
      } else {
        let response = await commonPostAPI("addivrmenus", requestparams);
        if (response.ivr_menu_name && response.ivr_menu_name !== "") {
          this.setState({ alertMessage: <div>Name already exists.</div> });
        } else {
          this.onOpenAction("ivrmenu");
        }
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    if (e.target.name === "ivr_menu_timeout") {
      this.timeOutActionChange(e);
    }

    let errors = this.state.errors;

    switch (e.target.name) {
      case "ivr_menu_name":
        errors.ivr_menu_name =
          e.target.value.length < 1 ? "Please enter name." : "";
        break;
      case "ivr_menu_enabled":
        errors.ivr_menu_enabled =
          e.target.value.length < 1 ? "Please select Enabled." : "";
        break;
      case "ivr_menu_timeout":
        errors.ivr_menu_timeout =
          e.target.value.length < 1 ? "Please select timeout Action." : "";
        break;
      case "ivr_menu_timeout_entry":
        errors.ivr_menu_timeout_entry =
          e.target.value.length < 1
            ? "Please select timeout action entry."
            : "";
        break;
      case "ivrtimeout":
        errors.ivrtimeout =
          e.target.value.length < 1
            ? "Please enter timeout."
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  timeOutActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({
        timeOutEntryList: response,
        ivr_menu_timeout_entry: "0",
      });
    } else {
      this.setState({
        timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
        ivr_menu_timeout_entry: "0",
      });
    }
  };

  onChangeItemOptionAction = async (i, e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value > 1) {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({
        ivr_menu_timeout_option: {
          ...this.state.ivr_menu_timeout_option,
          [i]: e.target.value,
        },
        timeOutOptionEntryList: {
          ...this.state.timeOutOptionEntryList,
          [i]: response,
        },
      });
    } else {
      this.setState({
        ivr_menu_timeout_option: {
          ...this.state.ivr_menu_timeout_option,
          [i]: e.target.value,
        },
        timeOutOptionEntryList: {
          ...this.state.timeOutOptionEntryList,
          [i]: [{ ivr_value: "hangup:", label: "hangup" }],
        },
      });
    }
  };

  componentDidMount = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let responseList = await commonPostAPI("recordinglist", {
      domain_uuid: domain_uuid,
    });
    this.setState({ ivrmenu_greetingList: responseList });
  };

  onChangeItemOptionActionEntry = (i, e) => {
    this.setState({
      ivr_menu_timeout_option_entry: {
        ...this.state.ivr_menu_timeout_option_entry,
        [i]: e.target.value,
      },
    });
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      ivr_menu_name,
      ivr_menu_enabled,
      alertMessage,
      ivr_menu_timeout,
      ivr_menu_timeout_entry,
      timeOutEntryList,
      ivr_menu_timeout_option_name,
      ivr_menu_timeout_option,
      ivr_menu_timeout_option_entry_name,
      ivr_menu_timeout_option_entry,
      timeOutOptionEntryList,
      ivrmenu_greeting,
      ivrmenu_greetingList,
      ivrtimeout,
    } = this.state;
    let fieldsArray = [];
    for (var i = 0; i <= 9; i++) {
      let itemList = timeOutOptionEntryList[i];
      fieldsArray.push(
        <tr>
          <td>{i}</td>
          <td>
            <Select
              fullWidth
              labelId={ivr_menu_timeout_option[i]}
              value={ivr_menu_timeout_option[i]}
              id={ivr_menu_timeout_option_name[i]}
              name={ivr_menu_timeout_option_name[i]}
              onChange={this.onChangeItemOptionAction.bind(this, i)}
              size="small"
              autoComplete="off"
            >
              <MenuItem value="0">Select</MenuItem>
              <MenuItem value="1">End Call</MenuItem>
              {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
              <MenuItem value="3">User</MenuItem>
              <MenuItem value="4">Ringgroup</MenuItem>
              <MenuItem value="5">IVR Menu</MenuItem>
              <MenuItem value="6">Callcenter Queue</MenuItem>
              <MenuItem value="7">Recording</MenuItem>
            </Select>
          </td>
          <td>
            <Select
              fullWidth
              labelId={ivr_menu_timeout_option_entry[i]}
              value={ivr_menu_timeout_option_entry[i]}
              name={ivr_menu_timeout_option_entry_name[i]}
              id={ivr_menu_timeout_option_entry_name[i]}
              onChange={this.onChangeItemOptionActionEntry.bind(this, i)}
              size="small"
              autoComplete="off"
            >
              <MenuItem value="0">Select</MenuItem>
              {itemList.map((item) => {
                return (
                  <MenuItem key={item.ivr_value} value={item.ivr_value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </td>
        </tr>
      );
    }

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3} >
                      <label>Name</label>
                      <TextField
                        margin="0"
                        required
                        fullWidth
                        id="ivr_menu_name"
                        name="ivr_menu_name"
                        value={ivr_menu_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="text"
                        size="small"
                      />
                      {errors.ivr_menu_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.ivr_menu_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Greeting</label>
                      <Select
                        fullWidth
                        labelId="ivrmenu_greeting"
                        id="ivrmenu_greeting"
                        value={ivrmenu_greeting}
                        label="ivrmenu_greeting"
                        name="ivrmenu_greeting"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {ivrmenu_greetingList.map((item) => (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={3}>
                      <label>Timeout</label>
                      <TextField
                        margin="0"
                        required
                        fullWidth
                        id="ivrtimeout"
                        name="ivrtimeout"
                        value={ivrtimeout ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="number"
                        size="small"
                      />
                      {errors.ivrtimeout.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.ivrtimeout}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Enabled</label>
                      <Select
                        fullWidth
                        labelId="ivr_menu_enabled"
                        id="ivr_menu_enabled"
                        value={ivr_menu_enabled}
                        label="Enabled"
                        name="ivr_menu_enabled"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="true">Active</MenuItem>
                        <MenuItem value="false">In Active</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Timeout Action</label>
                      <Select
                        fullWidth
                        labelId="ivr_menu_timeout"
                        id="ivr_menu_timeout"
                        value={ivr_menu_timeout}
                        label="ivr_menu_timeout"
                        name="ivr_menu_timeout"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="1">End Call</MenuItem>
                        <MenuItem value="2">Voicemail</MenuItem>
                        <MenuItem value="3">User</MenuItem>
                        <MenuItem value="4">Ringgroup</MenuItem>
                        <MenuItem value="5">IVR Menu</MenuItem>
                        <MenuItem value="6">Callcenter Queue</MenuItem>
                        <MenuItem value="7">Recording</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3} sx={{ marginBottom: 5 }}>
                      <label>Entry</label>
                      <Select
                        fullWidth
                        labelId="ivr_menu_timeout_entry"
                        id="ivr_menu_timeout_entry"
                        value={ivr_menu_timeout_entry}
                        label="ivr_menu_timeout_entry"
                        name="ivr_menu_timeout_entry"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {timeOutEntryList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {errors.ivr_menu_timeout_entry.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.ivr_menu_timeout_entry}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid
                      item
                      xs={12}
                      className="responsive-table"
                      sx={{
                        marginLeft: "40px !important",
                        borderTop: "1px solid #ddd",
                        padding: " 10px!important",
                        margin: 1,
                      }}
                    >
                      <table width="100%">
                        <thead>
                          <tr>
                            <td width="80"><label>Key</label></td>
                            <td><label>Action</label></td>
                            <td><label>Entry</label></td>
                          </tr>
                        </thead>
                        <tbody>{fieldsArray}</tbody>
                      </table>
                    </Grid>
                  </Grid>
                  {alertMessage ? (
                    <Stack className="error-show" spacing={2}>
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("ivrmenu")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(IvrmenuaddData);
