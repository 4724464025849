import React, { Component } from "react";
import { commonPostAPI, commonUploadPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';


var partialState = {
  showColumnsLength: 0,
};
class DialleadImportData extends Component {
  constructor(props) {
    super(props);
    this.setState(partialState);
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.state = {
      errors: {
        fileName: "Please upload dial code file.",
        skipRow: "",
      },
      alertMessage: "",
      dial_list_uuid: id,
      leadName: "",
      fileName: "",
      importFile: "",
      selectedFile: "",
      steps: ['Upload File', 'Column Mapping', 'Results'],
      activeStep: 0,
      completed: 2,
      importType: 0,
      skipRow: 1,
      showColumns: [],
      showColumnsLength: 0,
      onlyColumnValue: [],
      finalResult: {},
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Lead",
          action: `campaignlead`,
        },
        {
          label: "Dial",
          action: `diallead/${id}`,
        },
        {
          label: "Import dial Data",
          action: "",
        },
      ],
    };
  }

  clearColumnState = (myState) => {
    let keys = (myState) ? Object.keys(myState) : {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key.substring(0, 7) === 'column_') {
        partialState[key] = "0";
      }
    }
    this.setState({ ...partialState });
  }

  componentWillMount = async () => {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.setState({ dial_list_uuid: id });
    let pageObj = {
      dial_list_uuid: id,
    };
    let response = await commonPostAPI("getLeadName", pageObj);
    this.setState({ leadName: response });
  }

  totalSteps = () => {
    return this.state.steps.length;
  };

  setActiveStep = (value) => {
    this.setState({ activeStep: value })
  }

  setCompleted = (value) => {
    this.setState({ completed: value })
  }

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  };

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  handleNext = async () => {
    const newActiveStep =
      this.isLastStep()
        ? this.state.steps.findIndex((step, i) => !(i in this.state.completed))
        : this.state.activeStep + 1;

    if (newActiveStep === 1) {
      this.clearColumnState(this.state);
      let extension = this.state.fileName.split('.').pop();
      if (extension === '') {
        return true;
      }
      const fileFileType = ["xlsx", "xls", "XLSX", "XLS", "csv", "CSV"];
      var data = fileFileType.filter((val) =>
        val.includes(extension)
      );

      if (data.length === 0) {
        this.setState({ alertMessage: 'Allow only xlsx or xls file...' });
        return true;
      } else {
        var formData = new FormData();
        formData.append("file", this.state.selectedFile);
        formData.append("skipRow", this.state.skipRow);
        formData.append("filename", this.state.selectedFile.name);
        formData.append("domain_uuid", sessionStorage.getItem("domain_uuid"));
        let respones = await commonUploadPostAPI("uploadleadfile", formData);

        if (respones && respones.rows) {
          this.setState({ showColumns: respones.rows, showColumnsLength: respones.rows.length });
        }

        if (respones && respones.file) {
          this.setState({ importFile: respones.file, });
        }
      }
    }
    if (newActiveStep === 2) {

      let flag = this.check_duplicate_in_array(this.state.onlyColumnValue);
      if (flag.length > 0) {
        this.setState({ alertMessage: 'Duplicate column selection not allowd.' });
        return false;
      } else {
        // this.setState({ onlyColumnValue: [], alertMessage: 'ready for import.' });
        // return false;
        var formData = new FormData();
        formData.append("importFile", this.state.importFile);
        formData.append("skipRow", this.state.skipRow);
        formData.append("domain_uuid", sessionStorage.getItem("domain_uuid"));
        formData.append("dial_list_uuid", this.state.dial_list_uuid);
        formData.append("showColumnsLength", this.state.showColumnsLength);
        formData.append("importtype", this.state.importType);
        const keys = Object.keys(this.state);

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (key.substring(0, 7) === 'column_' && this.state[key] !== "" && this.state[key] !== "0") {
            formData.append(`${key}`, this.state[key]);
          }
        }
        let respones = await commonPostAPI("importleadfile", formData);
        this.setState({ finalResult: respones });
        this.handleStep(2);
      }
    }
    this.setState({ activeStep: newActiveStep });

  };

  check_duplicate_in_array = ((input_array) => {
    console.log(input_array);
    const duplicates = input_array.filter((item, index) => input_array.indexOf(item) !== index);
    console.log(duplicates);
    return Array.from(new Set(duplicates));
  })

  handleBack = () => {
    let newValue = this.state.activeStep - 1;
    this.setState({ activeStep: newValue });

  };

  handleStep = (step) => () => {
    this.setState({ activeStep: step });
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = (e) => {
    e.preventDefault();
    if (e.target.name === 'fileName') {
      partialState['selectedFile'] = e.target.files[0];
    }
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;

    switch (e.target.name) {
      case "fileName":
        errors.fileName =
          e.target.value.length < 1 ? "Please upload dial code file.." : "";
        break;
      case "skipRow":
        errors.skipRow =
          e.target.value.length < 1 ? "Please enter skip number." : "";
        break;
      default:
        break;
    }
    let column = e.target.name.substring(0, 7);
    if (column === "column_" && e.target.value !== "0" && e.target.value !== "") {
      let oldValue = this.state.onlyColumnValue;
      oldValue.push(e.target.value);
      this.setState({ onlyColumnValue: oldValue });
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  btnTemplateDownload = async () => {
    let url = "../Dialcode.xlsx";
    const link = document.createElement("a");
    link.href = `${url}`;
    let filename = url.split('/').pop();
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    const theme = createTheme();

    let {
      errors,
      alertMessage,
      dial_list_uuid,
      leadName,
      steps,
      activeStep,
      completed,
      importType,
      skipRow,
      showColumns,
      finalResult,
    } = this.state;


    let secondRow = "";
    let name = "";
    let sr = 0;
    if (activeStep === 1) {
      secondRow = (showColumns.length > 0) ? showColumns.map((items, index) => {
        sr = index + 1;
        name = `column_${sr}`;
        return <Grid container spacing={5} xs={12} ml="5" style={{ borderBottom: '1px' }}>
          <Grid item md={1}>({sr})</Grid>
          <Grid item md={4}>{items}</Grid>
          <Grid item md={7}>
            <Select
              key={index}
              fullWidth
              id={name}
              name={name}
              onChange={this.onChangeItem}
              value={this.state[name] !== undefined ? this.state[name] : "0"}
              autoComplete="off"
            >
              <MenuItem value="0">Select</MenuItem>
              <MenuItem value="1">Phone Code</MenuItem>
              <MenuItem value="2">Phone Number</MenuItem>
              <MenuItem value="3">Title</MenuItem>
              <MenuItem value="4">First Name</MenuItem>
              <MenuItem value="5">Middle Initial</MenuItem>
              <MenuItem value="6">Last Name</MenuItem>
              <MenuItem value="7">Address 1</MenuItem>
              <MenuItem value="8">Address 2</MenuItem>
              <MenuItem value="9">Address 3</MenuItem>
              <MenuItem value="10">City</MenuItem>
              <MenuItem value="11">State</MenuItem>
              <MenuItem value="12">Province</MenuItem>
              <MenuItem value="13">Postal Code</MenuItem>
              <MenuItem value="14">Country Code</MenuItem>
              <MenuItem value="15">Gender</MenuItem>
              <MenuItem value="16">Date Of Birth</MenuItem>
              <MenuItem value="17">Alternate Phone</MenuItem>
              <MenuItem value="18">Email</MenuItem>
              <MenuItem value="19">Security Phrase</MenuItem>
              <MenuItem value="20">Comments</MenuItem>
            </Select>
          </Grid>
        </Grid>;
      })
        : '';
    }

    return (
      <div className="submit-form formElements" >
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  name="myform"
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}>
                      <h4>Lead Name : {leadName}</h4>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}>
                      <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={this.handleStep(index)}>
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                      <div>
                        {
                          <React.Fragment>
                            {(activeStep === 0) ?
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={12}>
                                    Template : <IconButton aria-label="import" style={{ backgroundColor: "green" }} onClick={() => this.btnTemplateDownload()}>
                                      <ImportContactsIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>Skip Row</label>
                                    <TextField
                                      margin="normal"
                                      fullWidth
                                      id="skipRow"
                                      name="skipRow"
                                      value={skipRow ?? 0}
                                      onChange={this.onChangeItem}
                                      autoComplete="off"
                                      autoFocus
                                      type="number"
                                      size="small"
                                    />
                                    {errors.skipRow.length > 0 ? (
                                      <Stack className="error-show" spacing={2}>
                                        {errors.skipRow}
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>
                                      Operation Type
                                    </label>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <Select
                                          fullWidth
                                          id="importType"
                                          value={importType}
                                          label="importType"
                                          name="importType"
                                          onChange={this.onChangeItem}
                                          autoComplete="off"
                                        >
                                          <MenuItem value="0">New/Update</MenuItem>
                                          <MenuItem value="1">Replace</MenuItem>
                                        </Select>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={12}>
                                    Upload : <TextField
                                      margin="normal"
                                      required
                                      fullWidth
                                      id="fileName"
                                      name="fileName"
                                      onChange={this.onChangeItem}
                                      autoComplete="off"
                                      autoFocus
                                      type="file"
                                      size="small"
                                    />
                                    {errors.fileName.length > 0 ? (
                                      <Stack className="error-show" spacing={2}>
                                        {errors.fileName}
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                    {alertMessage ? (
                                      <Stack className="error-show" spacing={2}>
                                        {alertMessage}
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                              </Typography> : ""}

                            {(activeStep === 1) ?
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <Grid container spacing={5} xs={12} style={{ borderBottom: 1 }}>
                                  <Grid item md={1}><b>Sr.No</b></Grid>
                                  <Grid item md={4}><b>Sheet Start Row</b></Grid>
                                  <Grid item md={7}><b>Column Selection</b></Grid>
                                </Grid>
                                {secondRow}
                                {alertMessage ? (
                                  <Stack className="error-show" spacing={2}>
                                    {alertMessage}
                                  </Stack>
                                ) : (
                                  ""
                                )}
                              </Typography> : ""}

                            {(activeStep === 2) ?
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>Inserted Record : </label>
                                  </Grid>
                                  <Grid item md={3}>
                                    <label>{(finalResult.insert) ? finalResult.insert : 0}</label>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>Skip Record : </label>
                                  </Grid>
                                  <Grid item md={3}>
                                    <label>{(finalResult.skip) ? finalResult.skip : 0}</label>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>Error Record : </label>
                                  </Grid>
                                  <Grid item md={3}>
                                    <label>{(finalResult.error) ? finalResult.error : 0}</label>
                                  </Grid>
                                </Grid>
                              </Typography> : ""}

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                              {/* <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                sx={{ mr: 1 }}
                              >
                                Back
                              </Button> */}
                              <Box sx={{ flex: '1 1 auto' }} />
                              {
                                activeStep !== steps.length - 1 ?
                                  <Button onClick={this.handleNext} sx={{ mr: 1 }}>
                                    Next
                                  </Button> : ""
                              }
                              {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                    Step {activeStep + 1} already completed
                                  </Typography>
                                ) : "")}
                            </Box>
                          </React.Fragment>
                        }
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} justifyContent="flex-end" container>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction(`diallead/${dial_list_uuid}`)}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(DialleadImportData);
