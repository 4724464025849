import axios from 'axios';
import AppConfig from '../Constants/AppConfig';
import { Navigate } from "react-router-dom";

export const commonPostLoginAPI = async (methodName, request) => {
    var responseData = await axios.post(AppConfig.apiUrl + methodName, request)
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(error => JSON.parse(JSON.stringify(error.response)));

    const errCode = statusErrCode();
    const lgnErrCode = loginErrCode();
    var responses = {};
    try {
        if (responseData.status && responseData.data.errCode && (lgnErrCode.includes(responseData.status) || lgnErrCode.includes(responseData.data.errCode))) {
            responses = responseData.status;
        } else if (errCode.includes(responseData.status)) {
            responses = responseData.status;
        } else {
            responses = responseData.data;
        }
    } catch (error) {
        responses = staticResponseObj(responseData.status);
    }
    if (responses === 'jwt expired') {
        sessionStorage.clear();
        return (<Navigate to="/" replace={true} />);
    }
    return responses;
}


export const commonPostAPI = async (methodName, request, config) => {

    let token = sessionStorage.getItem("token");
    var responseData = await axios.post(AppConfig.apiUrl + methodName, request, {
        headers: {
            'authorization': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(error => JSON.parse(JSON.stringify(error.response)));

    const errCode = statusErrCode();
    const lgnErrCode = loginErrCode();
    var responses = {};
    try {
        if (responseData.status && responseData.data.errCode && (lgnErrCode.includes(responseData.status) || lgnErrCode.includes(responseData.data.errCode))) {
            responses = responseData.status;
        } else if (errCode.includes(responseData.status)) {
            responses = responseData.status;
        } else {
            responses = responseData.data;
        }
    } catch (error) {
        responses = staticResponseObj(responseData.status);
    }
    if (responses === 'jwt expired') {
        sessionStorage.clear();
        return (<Navigate to="/" replace={true} />);
    }
    return responses;
}

export const commonDelAPI = async (methodName, request) => {
    let token = sessionStorage.getItem("token");
    var responseData = await axios.delete(
        AppConfig.apiUrl + methodName,
        {
            data: {
                rows: request
            },
            headers: {
                'authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        })
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(error => JSON.parse(JSON.stringify(error.response)));

    const errCode = statusErrCode();
    const lgnErrCode = loginErrCode();
    var responses = {};
    try {
        if (responseData.status && responseData.data.errCode && (lgnErrCode.includes(responseData.status) || lgnErrCode.includes(responseData.data.errCode))) {
            responses = responseData.status;
        } else if (errCode.includes(responseData.status)) {
            responses = responseData.status;
        } else {
            responses = responseData.data;
        }
    } catch (error) {
        responses = staticResponseObj(responseData.status);
    }
    if (responses === 'jwt expired') {
        sessionStorage.clear();
        return (<Navigate to="/" replace={true} />);
    }
    return responses;
}


function staticResponseObj(statusCode) {
    var response = {
        ErrorCode: statusCode,
        ReturnCode: 1,
        ReturnMsg: "Please try after sometime.",
        statusCode: statusCode,
    };
    return response;
}

function statusErrCode() {
    let list = [404, 500, 502, 503];
    return list;
}

export function statusErrCodeList() {
    return statusErrCode();
}

export function loginErrCode() {
    let list = [401, 498, 1004, 1005, 1006, 1007];
    return list;
}

export const commonUploadPostAPI = async (methodName, request) => {

    let token = sessionStorage.getItem("token");
    var responseData = await axios.post(AppConfig.apiUrl + methodName, request, {
        headers: {
            'authorization': token,
            'Accept': 'application/json',
            'content-type': 'multipart/form-data'
        }
    })
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(error => JSON.parse(JSON.stringify(error.response)));

    const errCode = statusErrCode();
    const lgnErrCode = loginErrCode();
    var responses = {};
    try {
        if (responseData.status && responseData.data.errCode && (lgnErrCode.includes(responseData.status) || lgnErrCode.includes(responseData.data.errCode))) {
            responses = responseData.status;
        } else if (errCode.includes(responseData.status)) {
            responses = responseData.status;
        } else {
            responses = responseData.data;
        }
    } catch (error) {
        responses = staticResponseObj(responseData.status);
    }
    if (responses === 'jwt expired') {
        sessionStorage.clear();
        return (<Navigate to="/" replace={true} />);
    }
    return responses;
}
