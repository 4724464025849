import React, { Component } from "react";
import styled, { css } from "styled-components";
import MaterialIcon from "material-icons-react";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";

class Phonebutton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { isConnected } = this.props;
    let Button = styled.button`
      display: flex;
      padding: 10px;
      justify-content: center;
      position: fixed;
      bottom: 15px;
      right: 15px;
      width: 45px;
      height: 45px;
      background: #624bff;
      border: none;
      color: #fff;
      border-radius: 22.5px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      z-index: 11;

      &:hover {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
      }

      ${(props) =>
        props.opened &&
        css`
          border-top-right-radius: 50;
        `}
    `;
    let { opened, openedClosed } = this.props;
    let phoneButtomHtml =
      isConnected === true ? (
        <Button onClick={() => openedClosed()}>
          {opened && <MaterialIcon icon="close" color="#FFF" size={24} />}
          {!opened && <MaterialIcon icon="call" color="#FFF" size={24} />}
        </Button>
      ) : (
        <Button>
          <PhoneDisabledIcon color="#FFF" size={24} />
        </Button>
      );

    return <div>{phoneButtomHtml}</div>;
  }
}

export default Phonebutton;
