import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid } from "@mui/material";
import ResetTvIcon from '@mui/icons-material/ResetTv';


const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  rows: [],
};

class Campaignlistview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      dial_list_uuid: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Lead",
          action: "campaignlead",
        },
        {
          label: "View List Data",
          action: "",
        },
      ],
    };
  }

  componentWillMount = async () => {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);

    this.getDataByID(id);
  };



  getDataByID = async (id) => {
    let response = await commonPostAPI("leadstatus", { dial_list_uuid: id });

    var dbData = {
      rows: response,
      dial_list_uuid: id,
    };

    partialState = dbData;

    this.setState(partialState);
  };

  reset = async (id) => {
    let response = await commonPostAPI("leadstatus", { dial_list_uuid: id });
    await this.getDataByID(id);
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };


  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    let { dial_list_uuid } = this.state;
    const theme = createTheme();

    let {
      rows
    } = this.state;

    let campaign_name = "";
    let list_name = "";
    rows.filter((item) => {
      campaign_name = item.campaign_name;
      list_name = item.list_name;
    });

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 2, width: "70%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label><b>Campaign Name :</b> {campaign_name}</label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      &nbsp;
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label><b>List Name : </b>{list_name}</label>
                    </Grid>
                    <Grid item md={9}>
                      &nbsp;
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}>
                      &nbsp;
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label><b>Status</b></label>
                    </Grid>
                    <Grid item md={3}>
                      <label><b>Count</b></label>
                    </Grid>
                  </Grid>

                  {rows.length > 0 ? (
                    rows.map((item) => {
                      return (
                        <Grid container spacing={5} xs={12}>
                          <Grid item md={3}>
                            <label>{item.status}</label>
                          </Grid>
                          <Grid item md={3}>
                            <label>{item.numrows}</label>
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    ""
                  )}

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={10}>
                      &nbsp;
                    </Grid>

                    <Grid item md={2}>
                      <Button
                        type="button"
                        fullWidth
                        className="btn btn-outline-white"
                        sx={{
                          mt: 1,
                          mb: 1,
                          height: "35px",
                          padding: "5px 20px",
                          marginLeft: "10px",
                        }}
                        size="small"
                        onClick={() => this.onOpenAction("campaignlead")}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(Campaignlistview);
