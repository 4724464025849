import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import { withRouter } from "../withRouter";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import agentimage from "../../Assets/bgimage/agent.png";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.background.paper}`,
}));

var partialState = {
  conference_room_uuid: "",
  conference_room_name: "",
  start_datetime: "",
  stop_datetime: "",
  description: "",
  users: "",
  participant_pin: "",
  newParticipant: {},
  liveMembers: [],
};

let intervalDataConf = "";

class ConferenceviewData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conference_room_uuid: "",
      conference_center_extension: "",
      alertMessage: "",
      conference_room_name: "",
      start_datetime: "",
      stop_datetime: "",
      description: "",
      participant_pin: "",
      users: "",
      user_email: "",
      user_extension: "",
      conference_center_uuid: "",
      userList: [],
      open: false,
      newParticipant: "0",
      liveMembers: [],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Conference",
          action: "conference",
        },
        {
          label: "View Conference",
          action: "",
        },
      ],
    };
  }

  agentAvatar = (props) => {
    const StyledBadge = styled(Badge)(({ theme }) => (
      {
        '& .MuiBadge-badge': {
          backgroundColor: props.color,
          color: props.color,
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }
    ));

    return <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      presenceColor="#FF0000"
    >
      <SmallAvatar alt="Remy Sharp" src={agentimage} />
    </StyledBadge>;
  }

  componentDidMount = async () => {

    var url = window.location.pathname;
    const domain_name = sessionStorage.getItem("domain_name");

    var conference_room_uuid = url.substring(url.lastIndexOf("/") + 1);
    this.getDataByID(conference_room_uuid);

    let response = "";
    let cmd = "";

    cmd = `conference ${conference_room_uuid}@${domain_name} json_list`;
    response = await commonPostAPI("eslcommand", {
      command: cmd,
    });

    let members = [];
    if (response && response[0] && response[0].members) {
      let list = response[0].members;

      let element = "";
      for (let index = 0; index < list.length; index++) {
        element = list[index];
        members.push(
          {
            'caller_id_number': element.caller_id_number,
            'caller_id_name': element.caller_id_name,
            'id': element.id,
            'mute': !element.flags.can_speak,
            'uuid': element.uuid,
          }
        );
      }
      this.setState({ liveMembers: members });
    }

    intervalDataConf = setInterval(async () => {
      this.getDataByID(this.state.conference_room_uuid);

      let response = "";
      let cmd = "";

      cmd = `conference ${this.state.conference_room_uuid}@${domain_name} json_list`;
      response = await commonPostAPI("eslcommand", {
        command: cmd,
      });

      let members = [];
      if (response && response[0] && response[0].members) {
        let list = response[0].members;

        let element = "";
        for (let index = 0; index < list.length; index++) {
          element = list[index];
          members.push(
            {
              'caller_id_number': element.caller_id_number,
              'caller_id_name': element.caller_id_name,
              'id': element.id,
              'mute': !element.flags.can_speak,
              'uuid': element.uuid,
            }
          );
        }
        this.setState({ liveMembers: members });
      }
    }, 5000);
  }

  componentWillUnmount = () => {
    clearInterval(intervalDataConf);
  }

  getDataByID = async (conference_room_uuid) => {
    let response = await commonPostAPI("getRecordConferenceByViewID", { conference_room_uuid: conference_room_uuid });
    let dbData = {
      conference_room_uuid: response.conference_room_uuid,
      conference_room_name: response.conference_room_name,
      start_datetime: response.start_datetime,
      stop_datetime: response.stop_datetime,
      description: response.description,
      users: response.users,
      participant_pin: response.participant_pin,
      conference_center_uuid: response.conference_center_uuid,
      user_email: response.user_email,
      user_extension: response.user_extension,
      conference_center_extension: response.conference_center_extension,
    };
    partialState = dbData;
    this.setState(partialState);

    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const responseUsers = await commonPostAPI("userslist", { domain_uuid: domain_uuid });
    const usersArray = (response.users && response.users !== "") ? response.users.split(":") : [];

    const restUsers = [];
    responseUsers.map((item) => {
      if (usersArray.indexOf(item.username) === -1) {
        restUsers.push(item);
      }
      return true;
    });
    this.setState({ userList: restUsers });
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  addParticipentAction = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let requestparams = {
      domain_uuid: domain_uuid,
      user_uuid: this.state.newParticipant.user_uuid,
      conference_room_uuid: this.state.conference_room_uuid,
    };
    await commonPostAPI("addParticipant", requestparams);
    this.getDataByID(this.state.conference_room_uuid);
    this.handleClose();
  }

  onChangeItem = async (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  };

  muteAction = async (item) => {
    let response = "";
    let cmd = "";
    const domain_name = sessionStorage.getItem("domain_name");
    if (!item.mute) {
      cmd = `conference ${this.state.conference_room_uuid}@${domain_name} mute ${item.id}`;
    } else {
      cmd = `conference ${this.state.conference_room_uuid}@${domain_name} unmute ${item.id}`;
    }

    response = await commonPostAPI("eslcommand", {
      command: cmd,
    });

    cmd = `conference ${this.state.conference_room_uuid}@${domain_name} json_list`;
    response = await commonPostAPI("eslcommand", {
      command: cmd,
    });

    let members = [];
    if (response && response[0] && response[0].members) {
      let list = response[0].members;

      let element = "";
      for (let index = 0; index < list.length; index++) {
        element = list[index];
        members.push(
          {
            'caller_id_number': element.caller_id_number,
            'caller_id_name': element.caller_id_name,
            'id': element.id,
            'mute': !element.flags.can_speak,
            'uuid': element.uuid,
          }
        );
      }
      this.setState({ liveMembers: members });
    }
  }

  cutCallAction = async (item) => {
    let response = "";

    const domain_name = sessionStorage.getItem("domain_name");

    let cmd = `uuid_kill ${item.uuid}`;;
    response = await commonPostAPI("eslcommand", {
      command: cmd,
    });

    cmd = `conference ${this.state.conference_room_uuid}@${domain_name} json_list`;
    response = await commonPostAPI("eslcommand", {
      command: cmd,
    });

    let members = [];
    if (response && response[0] && response[0].members) {
      let list = response[0].members;

      let element = "";
      for (let index = 0; index < list.length; index++) {
        element = list[index];
        members.push(
          {
            'caller_id_number': element.caller_id_number,
            'caller_id_name': element.caller_id_name,
            'id': element.id,
            'mute': !element.flags.can_speak,
            'uuid': element.uuid,
          }
        );
      }
      if (members && members.length > 0) {
        this.setState({ liveMembers: members });
      } else {
        this.setState({ liveMembers: [] });
      }

    } else {
      this.setState({ liveMembers: [] });
    }
  }

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    const theme = createTheme({
      palette: {
        primary: {
          main: "#0052cc",
        },
        secondary: {
          main: "#edf2ff",
        },
      },
    });

    let {
      conference_room_name,
      start_datetime,
      stop_datetime,
      users,
      participant_pin,
      user_email,
      user_extension,
      userList,
      open,
      newParticipant,
      conference_center_extension,
      liveMembers,
    } = this.state;

    const rowListAgent = users.split(':');
    const rowListAgentEmail = user_email.split(':');
    const rowListAgentExtension = user_extension.split(':');

    const commonAgent = (liveMembers && liveMembers.length > 0) ? liveMembers.map((item) => {
      return item.caller_id_number;
    }) : [];


    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>

              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container justifyContent="flex-end">
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("conference")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={2}>
                      <label><b>Name :</b></label>
                    </Grid>
                    <Grid item md={10}>
                      <label>{conference_room_name}</label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={2}>
                      <label><b>Start Time :</b></label>
                    </Grid>
                    <Grid item md={10}>
                      <label>{start_datetime}</label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={2}>
                      <label><b>Stop Time :</b></label>
                    </Grid>
                    <Grid item md={10}>
                      <label>{stop_datetime}</label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={2}>
                      <label><b>Conference ID :</b></label>
                    </Grid>
                    <Grid item md={10}>
                      <label>{participant_pin}</label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}>
                      To join dial {conference_center_extension} (internal) and enter the conference ID.
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={10}>&nbsp;</Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={1} style={{ marginTop: '3px' }}><b>Participants</b></Grid>
                    <Grid item md={11}>
                      &nbsp;&nbsp;&nbsp;<AddCircleOutlineIcon onClick={() => this.handleOpen()} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}>&nbsp;</Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={5} xs={12}
                    sx={{ mt: 1, mb: 1, ml: 1, mr: 1, height: "10%", width: "90%" }}
                  >
                    <item
                      className="caller-list-data"
                      sx={{ mt: 1, mb: 1, height: "100%", background: "#fff" }}
                    >
                      {liveMembers.length > 0
                        ? liveMembers.map((item, index) => {
                          const obj = {
                            color: "#44b700",
                            label: item.caller_id_name,
                          }
                          return (
                            <Grid
                              item
                              sx={{
                                mt: 1,
                                mb: 1,
                                width: "70%",
                                textAlign: "left",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                fontSize: 12,
                                bgcolor: "#E8E6E6",
                                "border-radius": 10,
                              }}
                              style={{ backgroundColor: "#00FF00" }}
                            >

                              {this.agentAvatar(obj)}
                              {item.caller_id_name}
                              &nbsp;&nbsp;&nbsp; ({item.caller_id_number})
                              &nbsp;&nbsp;&nbsp;{item.mute === false ? <MicOffIcon onClick={() => this.muteAction(item)} /> : <MicIcon onClick={() => this.muteAction(item)} />}
                              &nbsp;<PhoneDisabledIcon sx={{ bgcolor: 'red' }} onClick={() => this.cutCallAction(item)} />
                            </Grid>
                          );
                        })
                        : ""}


                      {rowListAgent.length > 0
                        ? rowListAgent.map((item, index) => {
                          const obj = {
                            color: "#44b700",
                            label: item,
                          }
                          let isPresent = (commonAgent.indexOf(rowListAgentExtension[index]) > -1);
                          if (isPresent === false) {
                            return (
                              <Grid
                                item
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "70%",
                                  textAlign: "left",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  fontSize: 12,
                                  bgcolor: "#E8E6E6",
                                  "border-radius": 10,
                                }}

                                style={{ backgroundColor: "#F0F0F0" }}
                              >

                                {this.agentAvatar(obj)}
                                {item}
                                &nbsp;&nbsp;&nbsp;({(rowListAgentExtension[index] && rowListAgentExtension[index] !== "") ? rowListAgentExtension[index] : ""},
                                &nbsp;{(rowListAgentEmail[index] && rowListAgentEmail[index] !== "") ? rowListAgentEmail[index] : ""})
                              </Grid>
                            );
                          } else {
                            return false;
                          }
                        })
                        : ""}
                    </item>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
        <div>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="messageDialog"

          >
            <DialogTitle id="alert-dialog-title">Participant</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={5} xs={12}>
                  <Grid item md={10}>Participants</Grid>
                </Grid>
                <Grid container spacing={5} xs={12}>
                  <Grid item md={10}>&nbsp;</Grid>
                </Grid>
                <Grid container spacing={5} xs={12}>
                  <Grid item md={10}>
                    <Select
                      labelId="newParticipant"
                      id="newParticipant"
                      value={
                        newParticipant
                      }
                      name="newParticipant"
                      onChange={this.onChangeItem}
                      size="small"
                    >
                      <MenuItem value="0">Select</MenuItem>
                      {userList.map((item) => {
                        return (
                          <MenuItem key={item.user_uuid} value={item}>
                            {item.username} ({item.extension}, {item.user_email})
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.addParticipentAction()}
                className="btn-primary"
                autoFocus
              >
                Add
              </Button>
              <Button
                onClick={this.handleClose}
                autoFocus
                className="btn-outline-white"
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div >
    );
  }
}

export default withRouter(ConferenceviewData);
