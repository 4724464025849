import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { withRouter } from "./withRouter";
import { Grid } from '@mui/material';
import { commonPostAPI } from "../commoncall/commoncall";
import MDBox from "./Widgets/MDBox";
import borders from "../Assets/theme/base/borders";
import boxShadows from "../Assets/theme/base/boxShadows";
import colors from "../Assets/theme/base/colors";
import card from "../Assets/theme/components/card";
import cardMedia from "../Assets/theme/components/card/cardMedia";
import cardContent from "../Assets/theme/components/card/cardContent";
import divider from "../Assets/theme/components/divider";
import typography from "../Assets/theme/base/typography";
import ComplexStatisticsCard from "./Widgets/CustomTools/StatisticsCards/ComplexStatisticsCard";
import CallIcon from '@mui/icons-material/Call';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Chart from 'react-apexcharts';

function linearGradient(color, colorState, angle = 195) {
  return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
}

let intervalCall = "";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCalls: 0,
      voicemail: 0,
      miscalls: 0,
      activecalls: 0,
      currentHours: 0,
      ColumnXaxis: [],
      ColumnYaxis: [],
      lineXaxis: [],
      lineYaxix: [],
      pieData: {
        series: [],
        options: {
          title: {
            text: "Agents",
            align: 'center',
          },
          chart: {
            width: 380,
            type: 'donut',
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toFixed(2)
            },
          },
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }],
          legend: {
            position: 'bottom',
            offsetY: 0,
          },
          fill: {
            colors: ['#0888ed', '#bbb', '#FFA500']
          },
        }
      },
      extensionDonutData: {},
      memDonutOption: {},
      cpuDonutOption: {},
      diskDonutOption: {},
    };
  }

  componentDidMount = async () => {
    this.getRecord();
    intervalCall = setInterval(() => {
      this.getRecord();
    }, 10000);
  }

  getRecord = async () => {
    let response = 0;
    let params = {
      domain_uuid: sessionStorage.getItem('domain_uuid'),
    };

    let partialState = {};
    let responsexy = [];
    let xaxis = [];
    let yaxis = [];
    response = await commonPostAPI("currentHours", params);
    if (response && response.hours) {
      responsexy = await commonPostAPI("last6hourstotalcalls", params);
      xaxis = responsexy.map((item) => {
        return item.count;
      });

      yaxis = responsexy.map((item) => {
        return item.hours;
      });

      partialState['ColumnXaxis'] = xaxis;
      partialState['ColumnYaxis'] = yaxis;

      responsexy = await commonPostAPI("last6hoursduration", params);
      xaxis = responsexy.map((item) => {
        return item.count;
      });

      yaxis = responsexy.map((item) => {
        return item.hours;
      });

      partialState['lineXaxis'] = xaxis;
      partialState['lineYaxix'] = yaxis;
    }

    partialState['currentHours'] = (response.hours) ? response.hours : 0;

    response = await commonPostAPI("todaytotalcalls", params);
    partialState['totalCalls'] = response;

    response = await commonPostAPI("todayvoicemail", params);
    partialState['voicemail'] = response;

    response = await commonPostAPI("todaymisscalls", params);
    partialState['miscalls'] = response;

    response = await commonPostAPI("eslcommand", {
      command: "show calls as json",
    });
    partialState['activecalls'] = (response && response.row_count) ? response.row_count : 0;

    response = await commonPostAPI("memusage", params);
    let tmpData = [];
    let memDonutOption = {};
    if (response && response.usedMemMb && response.freeMemMb) {
      tmpData.push(response.usedMemMb);
      tmpData.push(response.freeMemMb);
      memDonutOption = {
        series: tmpData,
        options: {
          title: {
            text: "Memory Usages",
            align: 'center',
          },
          chart: {
            width: 380,
            type: 'donut',
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              offsetY: 10
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toFixed(2)
            },
          },
          labels: ["Used", "Free"],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }],
          legend: {
            position: 'bottom',
            offsetY: 0,
          },
          fill: {
            colors: ['#0888ed', '#bbb']
          },
        }
      };
      partialState['memDonutOption'] = memDonutOption;
    }

    response = await commonPostAPI("cpuusage", params);
    tmpData = [];
    let cpuDonutOption = {};
    if (response && response.used) {
      tmpData.push(response.used);
      tmpData.push(100 - response.used);
      cpuDonutOption = {
        series: tmpData,
        options: {
          title: {
            text: "CPU Usages",
            align: 'center',
          },
          chart: {
            width: 380,
            type: 'donut',
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              offsetY: 10
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toFixed(2)
            },
          },
          labels: ["Used", "Free"],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }],
          legend: {
            position: 'bottom',
            offsetY: 0,
          },
          fill: {
            colors: ['#0888ed', '#bbb']
          },
        }
      };
      partialState['cpuDonutOption'] = cpuDonutOption;
    }

    let registrarExtension = await commonPostAPI("eslcommand", {
      command: "show registrations as json",
    });

    let registrarOnline = (registrarExtension && registrarExtension.row_count) ? registrarExtension.row_count : 0;
    response = await commonPostAPI("extensionall", params);
    let extensionOffline = response - registrarOnline;
    let extensionDonutData = {};
    if (response && registrarExtension) {
      extensionDonutData = {
        series: [registrarOnline, extensionOffline],
        options: {
          title: {
            text: "Extentions",
            align: 'center',
          },
          chart: {
            width: 380,
            type: 'donut',
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              offsetY: 10
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toFixed(2)
            },
          },
          labels: ["Online", "Offline"],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }],
          legend: {
            position: 'bottom',
            offsetY: 0,
          },
          fill: {
            colors: ['#0888ed', '#bbb']
          },
        }
      };

      this.setState({ extensionDonutData: extensionDonutData });
    }

    response = await commonPostAPI("agentStatus", params);
    const agentStatusCount = [
      (response.available) ? parseInt(response.available) : 0,
      (response.logout) ? parseInt(response.logout) : 0,
      (response.onbreak) ? parseInt(response.onbreak) : 0
    ];

    let agentStatusName = ['Available', 'Logged Out', 'On Break'];


    let pieData = {
      series: agentStatusCount,
      options: {
        title: {
          text: "Agents",
          align: 'center',
        },
        chart: {
          width: 380,
          type: 'donut',
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2)
          },
        },
        labels: agentStatusName,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          position: 'bottom',
          offsetY: 0,
        },
        fill: {
          colors: ['#0888ed', '#bbb', '#FF0000', '#FF45000']
        },
      }
    };
    partialState['pieData'] = pieData;
    this.setState(partialState)

  }

  componentWillUnmount = () => {
    clearInterval(intervalCall);
  }

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    let { ColumnXaxis, ColumnYaxis, lineXaxis, lineYaxix } = this.state;

    let columnData = {
      series: [{
        name: 'CDR',
        data: ColumnXaxis
      }],
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: ColumnYaxis,
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }

        },
        title: {
          text: 'Last 6 Hours',
          floating: true,
          align: 'center',
          style: {
            color: '#444'
          },
        }
      }
    };


    let lineData = {
      series: [{
        name: "Calls",
        data: lineXaxis
      }],
      options: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Talk Time',
          align: 'center'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: lineYaxix,
        }
      }
    };

    const extraOptions = {
      palette: { ...colors },
      borders: { ...borders },
      boxShadows: { ...boxShadows },
      typography: { ...typography },
      functions: {
        linearGradient,
      },
      components: {
        MuiCard: { ...card },
        MuiCardMedia: { ...cardMedia },
        MuiCardContent: { ...cardContent },
        MuiDivider: { ...divider },
      },
    };
    const theme = createTheme({ ...extraOptions });

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container
            component="main"
            maxWidth="xl"
            sx={{ padding: "0 !important" }}
          >
            <CssBaseline />
            <MDBox py={3}>
              <Grid
                container
                xs={12}
                spacing={2}
                sx={{ width: "100%", padding: "20px !important" }}
              >
                <Grid item md={4} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon={<CallIcon />}
                      title="Total Calls"
                      count={this.state.totalCalls}
                      percentage={{
                        color: "success",
                        //amount: "+55%",
                        label: "Total Calls(Today).",
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item md={4} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      text="primary"
                      icon={<VoicemailIcon />}
                      title="Voice Mail"
                      count={this.state.voicemail}
                      percentage={{
                        color: "success",
                        //amount: "+55%",
                        label: "Voice mail messages(Today).",
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item md={4} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="error"
                      icon={<PhoneMissedIcon />}
                      title="Missed call"
                      count={this.state.miscalls}
                      percentage={{
                        color: "success",
                        //amount: "+55%",
                        label: "Missed calls(Today).",
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item md={4} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon={<ThumbUpIcon />}
                      title="Active Calls"
                      count={this.state.activecalls}
                      percentage={{
                        color: "success",
                        //amount: "+55%",
                        label: "Active Calls(Today).",
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                xs={12}
                sx={{ width: "100%", padding: "20px !important" }}
              >
                <Grid item md={4} lg={4}>
                  {this.state.memDonutOption.options ? <Chart options={this.state.memDonutOption.options} series={this.state.memDonutOption.series} type="donut" /> : ""}
                </Grid>
                <Grid item md={4} lg={4}>
                  {this.state.cpuDonutOption.options ? <Chart options={this.state.cpuDonutOption.options} series={this.state.cpuDonutOption.series} type="donut" /> : ""}
                </Grid>
                <Grid item md={4} lg={4}>
                  {this.state.extensionDonutData.options ? <Chart options={this.state.extensionDonutData.options} series={this.state.extensionDonutData.series} type="donut" /> : ""}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                xs={12}
                sx={{ width: "100%", padding: "20px !important" }}
              >

                <Grid item md={1} lg={1}>&nbsp;</Grid>
                <Grid item md={4} lg={5}>
                  <Chart options={columnData.options} series={columnData.series} type="bar" />
                </Grid>

                <Grid item md={4} lg={5}>
                  <Chart options={this.state.pieData.options} series={this.state.pieData.series} type="pie" />
                </Grid>
                <Grid item md={1} lg={1}>&nbsp;</Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                xs={12}
                sx={{ width: "100%", padding: "20px !important" }}
              >
                <Grid item md={12} lg={7}>
                  <Chart options={lineData.options} series={lineData.series} type="line" />
                </Grid>
              </Grid>
            </MDBox>

          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withRouter(Dashboard);
