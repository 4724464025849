import styled, { css } from "styled-components";

export const BoxDialer = styled.div`
  position: fixed;
  bottom: 70px;
  right: 15px;
  width: 100%;
  max-width: 240px;
  minHeight: 350px;
  padding: 15px;
  background: #263238;
  color: #FFFFFF;
  border-radius: 22.5px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.15s ease-in, transform 0.25s ease-in,
    border-radius 0.3s ease-in-out, visibility 0s linear 0.3s;
  z-index: 10;

  ${props =>
    props.opened &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.15s ease-out, transform 0.25s ease-out,
        border-radius 0.3s ease-in-out, visibility 0s;
      border-bottom-right-radius: 0;
    `}
`;

export const Input = styled.input`
  width: 80%;
  margin-bottom: 5px;
  border: none;
  background: #37474f;
  height: 35px;
  padding: 0 15px;
  border-radius: 4px;
  color: #cfd8dc;
`;

export const BoxReciever = styled.div`
  position: fixed;
  bottom: 70px;
  right: 15px;
  width: 100%;
  max-width: 240px;
  minHeight: 350px;
  padding: 15px;
  background: #263238;
  color: #FFFFFF;
  border-radius: 22.5px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.15s ease-in, transform 0.25s ease-in,
    border-radius 0.3s ease-in-out, visibility 0s linear 0.3s;
  z-index: 10;

  ${props =>
    props.opened &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.15s ease-out, transform 0.25s ease-out,
        border-radius 0.3s ease-in-out, visibility 0s;
      border-bottom-right-radius: 0;
    `}
`;

export const BoxDtmf = styled.div`
  position: fixed;
  bottom: 70px;
  right: 15px;
  width: 100%;
  max-width: 240px;
  minHeight: 350px;
  padding: 15px;
  background: #263238;
  color: #FFFFFF;
  border-radius: 22.5px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.15s ease-in, transform 0.25s ease-in,
    border-radius 0.3s ease-in-out, visibility 0s linear 0.3s;
  z-index: 10;

  ${props =>
    props.opened &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.15s ease-out, transform 0.25s ease-out,
        border-radius 0.3s ease-in-out, visibility 0s;
      border-bottom-right-radius: 0;
    `}
`;
