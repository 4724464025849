import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Navigate } from "react-router-dom";
import { withRouter } from "../withRouter";

let intervalDataAllCall = "";

class AllcallData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            editable: false,
            minWidth: 200,
          },
          {
            field: "created",
            headerName: "Start Time",
            filter: "agTextColumnFilter",
            tooltipField: "Type",
            editable: false,
            minWidth: 200,
          },
          {
            field: "cid_name",
            headerName: "CID Name",
            filter: "agTextColumnFilter",
            tooltipField: "CID Name",
            minWidth: 200,
          },
          {
            field: "cid_num",
            headerName: "CID Number",
            filter: "agTextColumnFilter",
            tooltipField: "CID Number",
            minWidth: 200,
          },
          {
            field: "dest",
            headerName: "Dest",
            filter: "agTextColumnFilter",
            tooltipField: "Dest",
            minWidth: 200,
          },
          {
            field: "application",
            headerName: "Application",
            filter: "agTextColumnFilter",
            tooltipField: "Application",
            minWidth: 200,
          },
        ],
        enableBrowserTooltips: true,
      },
      rowData: [],
    };
  }

  componentDidMount = async () => {
    this.getRecord();
    intervalDataAllCall = setInterval(() => {
      this.getRecord();
    }, 60000);
  };

  componentWillUnmount = () => {
    clearInterval(intervalDataAllCall);
  }

  getRecord = async () => {
    let response = await commonPostAPI("eslcommand", {
      command: "show channels as json",
    });
    if (response && response.row_count > 0) {
      this.setState({ rowData: response.rows });
    } else {
      this.setState({ rowData: [] });
    }
  }

  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        resizable: true,
        autoHeight: false,
        editable: true,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      rowData: this.state.rowData,
      pagination: false,
    };

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    let divId = "grid" + Math.random();
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    return (
      <div className="ag-theme-material gridProperties" key={divId}>
        <AgGridReact
          className="table-structure"
          gridOptions={gridOptions}
          rowModelType="clientSide"
          rowClassRules={rowClassRules}
          suppressHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
        />
      </div>
    );
  }
}

export default withRouter(AllcallData);
