import React, { Component } from "react";
import { Button } from "@mui/material";
import { Navigate } from "react-router-dom";
import { commonPostLoginAPI, commonPostAPI } from "../commoncall/commoncall";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { withRouter } from "./withRouter";
import axios from 'axios';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      PBX
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

var partialState = {};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      alertMessage: "",
      Username: "",
      Password: "",
    };
  }

  onChangeItem = (e) => {
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
    e.preventDefault();
  };

  onAgentPortal = () => {
    window.location.href = process.env.REACT_APP_AGENT_PORTAL
  }

  checkLogin = async (event) => {
    event.preventDefault();
    let { Username, Password } = this.state;
    if (Username === "") {
      this.setState({ alertMessage: "Please enter username" });
    } else if (Password === "") {
      this.setState({ alertMessage: "Please enter password" });
    } else {

      let result = Username.indexOf("@");
      if (result >= 0) {
        const answer_array = Username.split('@');
        if (answer_array.length === 2) {
          let inputUsername = (answer_array[0]) ? answer_array[0] : "";
          let inputDomain = (answer_array[1]) ? answer_array[1] : "";
          let responseFirst = await commonPostLoginAPI("domaincheck", { domain_name: inputDomain });
          if (responseFirst && responseFirst.domain_uuid && responseFirst.domain_uuid !== "") {
            let requestData = {
              Username: inputUsername,
              Password: this.state.Password,
              domain_uuid: responseFirst.domain_uuid
            };
            let response = await commonPostLoginAPI("loginuser", requestData);
            if (response && response.username) {
              this.setState({ alertMessage: "" });
              sessionStorage.setItem("Username", response.username);
              sessionStorage.setItem("user_uuid", response.user_uuid);
              sessionStorage.setItem("displayname", response.username);
              sessionStorage.setItem("token", response.accessToken);
              sessionStorage.setItem("domain_uuid", response.domain_uuid);
              sessionStorage.setItem("domain_name", response.domain_name);
              sessionStorage.setItem("extension", response.extension);
              sessionStorage.setItem("user_presence", response.user_presence);
              sessionStorage.setItem(
                "extension_password",
                response.extension_password
              );
              sessionStorage.setItem("isConnected", true);
              sessionStorage.setItem("loginState", 0);

              await commonPostAPI("setuserloginstatus", { "status": 'Available', 'user_uuid': response.user_uuid });

              let departmentList = await commonPostAPI("getAccessRecordByID", { department_id: response.department_id });
              await this.defineAccessConstant(departmentList);

            } else {
              partialState["alertMessage"] = "Please enter valid username and password...";
            }
          } else {
            partialState["alertMessage"] = "Please enter valid username and password...";
          }

        } else {
          partialState["alertMessage"] = "Please enter valid username and password...";
        }
      } else {
        const domainHost = window.location.host;
        var responseData = await axios.get('https://api.ipify.org/?format=json')
          .then(response => JSON.parse(JSON.stringify(response)))
          .catch(error => JSON.parse(JSON.stringify(error.response)));
        const domainIP = (responseData && responseData.data && responseData.data.ip) ? responseData.data.ip : "";

        let responseFirst = await commonPostLoginAPI("domaincheckIp", { hostname: domainHost, hostip: domainIP });
        if (responseFirst && responseFirst.domain_uuid && responseFirst.domain_uuid !== "") {
          let requestData = {
            Username: this.state.Username,
            Password: this.state.Password,
            domain_uuid: responseFirst.domain_uuid
          };
          let response = await commonPostLoginAPI("loginuser", requestData);
          if (response && response.username) {
            this.setState({ alertMessage: "" });
            sessionStorage.setItem("Username", response.username);
            sessionStorage.setItem("user_uuid", response.user_uuid);
            sessionStorage.setItem("displayname", response.username);
            sessionStorage.setItem("token", response.accessToken);
            sessionStorage.setItem("domain_uuid", response.domain_uuid);
            sessionStorage.setItem("domain_name", response.domain_name);
            sessionStorage.setItem("extension", response.extension);
            sessionStorage.setItem("user_presence", response.user_presence);
            sessionStorage.setItem(
              "extension_password",
              response.extension_password
            );
            sessionStorage.setItem("isConnected", true);
            sessionStorage.setItem("loginState", 0);

            await commonPostAPI("setuserloginstatus", { "status": 'Available', 'user_uuid': response.user_uuid });
            let departmentList = await commonPostAPI("getAccessRecordByID", { department_id: response.department_id });
            await this.defineAccessConstant(departmentList);

          } else {
            partialState["alertMessage"] = "Please enter valid username and password...";
          }
        } else {
          partialState["alertMessage"] = "Please enter valid username and password...";
        }
      }

      this.setState(partialState);
      return false;
    }
  };

  defineAccessConstant = async (departmentList) => {
    let find = ' ';
    let reData = new RegExp(find, 'g');
    Object.keys(departmentList).map((element, index) => {
      if (departmentList[element].sub_module_id && departmentList[element].check_access_value) {
        let name = departmentList[element].name;
        name = name.replace(reData, "_");
        name = name.toUpperCase();
        sessionStorage.setItem(name, departmentList[element].check_access_value);
      }
      return true;
    });
  }

  render() {
    let { Username, Password, alertMessage } = this.state;
    if (sessionStorage.getItem("token") !== null) {
      return <Navigate to="/dashboard" replace={true} />;
    }
    const theme = createTheme({
      palette: {
        primary: {
          main: "#624bff",
        },
        secondary: {
          main: "#edf2ff",
        },
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl">
          <CssBaseline />
          <Box
            className="smooth-shadow-md"
            sx={{
              margin: "10px auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: ".5rem",
              padding: "15px 25px",
              background: "#fff",
              width: "450px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
              Admin
            </Typography>
            { }
            <Box
              component="form"
              onSubmit={this.checkLogin}
              noValidate
              sx={{ mt: 1 }}
            >
              <label>Username</label>
              <TextField
                margin="normal"
                required
                fullWidth
                id="Username"
                name="Username"
                value={Username ?? ""}
                onChange={this.onChangeItem}
                autoComplete="off"
                sx={{
                  marginTop: 1,
                  marginBottom: 2,
                }}
              />
              <label>Password</label>
              <TextField
                margin="normal"
                required
                fullWidth
                name="Password"
                type="password"
                id="Password"
                value={Password ?? ""}
                onChange={this.onChangeItem}
                autoComplete="off"
                sx={{
                  marginTop: 1,
                  marginBottom: 2,
                }}
              />
              {alertMessage ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="warning">{alertMessage}</Alert>
                </Stack>
              ) : (
                ""
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, height: "50px" }}
              >
                Sign In
              </Button>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, height: "50px" }}
                onClick={() => this.onAgentPortal()}
              >
                Agent
              </Button>
            </Box>
          </Box>
          <Copyright
            sx={{
              mt: 0,
              mb: 1,
              position: "absolute",
              bottom: "10px",
              width: "100%",
              left: "0",
              textAlignment: "center",
            }}
          />
        </Container>
      </ThemeProvider>
    );
  }
}

export default withRouter(Login);
