import { createContext, useContext, useReducer, useMemo } from "react";


const UserChatContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "USER_TOTAL_UNREAD_MSG_COUNT": {
      return { ...state, userTotalUnreadMsgCount: action.value };
    }
    case "USER_UNREAD_MSG_COUNT_DATA": {
      return { ...state, userUnreadMsgCountData: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserChatControllerProvider({ children }) {
  const initialState = {
    userTotalUnreadMsgCount: 0,
    userUnreadMsgCountData: [],
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return (
    <UserChatContext.Provider value={value}>
      {children}
    </UserChatContext.Provider>
  );
}

function useUserChatController() {
  const context = useContext(UserChatContext);

  if (!context) {
    throw new Error(
      "useUserChatController should be used inside the useUserChatControllerProvider."
    );
  }

  return context;
}

const setUnreadMessageCount = (dispatch, value) =>
  dispatch({ type: "USER_TOTAL_UNREAD_MSG_COUNT", value });

const setUnreadMessageCountData = (dispatch, value) =>
  dispatch({ type: "USER_UNREAD_MSG_COUNT_DATA", value });


export {
  UserChatControllerProvider,
  useUserChatController,
  setUnreadMessageCount,
  setUnreadMessageCountData,
};