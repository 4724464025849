import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};
class RatesaddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        dialcode: "Please enter dial code.",
        dialrate: "Please enter rates.",
      },
      alertMessage: "",
      rateplan_uuid: "",
      dialcode: "",
      dialrate: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Rates",
          action: "rates",
        },
        {
          label: "Add Rates",
          action: "",
        },
      ],
    };
  }

  componentDidMount = async () => {
    var url = window.location.pathname;
    var rateplan_uuid = url.substring(url.lastIndexOf("/") + 1);
    this.setState({ rateplan_uuid: rateplan_uuid });
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        rateplan_uuid: this.state.rateplan_uuid,
        dialcode: this.state.dialcode,
        dialrate: this.state.dialrate,
      };
      let response = await commonPostAPI("addrate", requestparams);
      this.onOpenAction(`rates/${this.state.rateplan_uuid}`);
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  handleChangeMultiple = (e) => {
    const selected = e.target.value;
    this.setState({ users: selected });
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;
    switch (e.target.name) {
      case "dialcode":
        errors.dialcode =
          e.target.value.length < 1 ? "Please enter dial code." : "";
        break;
      case "dialrate":
        errors.dialrate =
          e.target.value.length < 1 ? "Please enter dial rate." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      dialcode,
      dialrate,
      alertMessage,
    } = this.state;


    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Dial Code</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="dialcode"
                        name="dialcode"
                        value={dialcode ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="number"
                        size="small"
                      />
                      {errors.dialcode.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.dialcode}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Rates</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="dialrate"
                        name="dialrate"
                        value={dialrate ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="number"
                        size="small"
                      />
                      {errors.dialrate.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.dialrate}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    {alertMessage ? (
                      <Stack className="error-show" spacing={2}>
                        {alertMessage}
                      </Stack>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction(`rates/${this.state.rateplan_uuid}`)}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(RatesaddData);
