import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Navigate } from "react-router-dom";
import { withRouter } from "../withRouter";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Custombreadcrumb from "../Custombreadcrumb";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import {
  Button,
  Box,
  TextField,
  Grid,
} from "@mui/material";

var partialState = {};
var gridApiObj;
class Voicemailmessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            minWidth: 200,
            editable: false,
          },
          {
            field: "created_time",
            headerName: "Received",
            filter: "agTextColumnFilter",
            tooltipField: "Received",
            editable: false,
            minWidth: 200,
          },
          {
            field: "caller_id_name",
            headerName: "Caller ID Name",
            filter: "agTextColumnFilter",
            tooltipField: "Caller ID Name",
            editable: false,
            minWidth: 200,
          },
          {
            field: "caller_id_number",
            headerName: "Caller ID Number",
            filter: "agTextColumnFilter",
            tooltipField: "Caller ID Number",
            editable: false,
            minWidth: 200,
          },
          {
            field: "message_length",
            headerName: "Length",
            filter: "agTextColumnFilter",
            tooltipField: "Length",
            editable: false,
            minWidth: 200,
          },
          {
            field: "size",
            headerName: "size",
            filter: "agTextColumnFilter",
            tooltipField: "size",
            editable: false,
            minWidth: 200,
          },
          {
            field: "file",
            headerName: "file",
            filter: "agTextColumnFilter",
            tooltipField: "file",
            editable: false,
            cellRenderer: BtnFileDownloadRenderer,
            minWidth: 200,
          },
        ],
        rowModelType: "serverSide",
        serverSideStoreType: "full",
        enableBrowserTooltips: true,
      },
      paginationPageSize: 50,
      currentPage: 1,
      id: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Voicemail",
          action: "voicemail",
        },
        {
          label: sessionStorage.getItem("voicemail_username"),
          action: "",
        },
      ],
      cid_number: '',
    };

    this.rowSelectionObj = [];
  }

  createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 1000);
      },
    };
  };

  createFakeServer = (allData) => {
    return {
      getData: (request) => {
        var rowsForBlock = allData.slice(request.startRow, request.endRow);
        var lastRow = this.getLastRowIndex(request, rowsForBlock);
        return {
          success: true,
          rows: rowsForBlock,
          lastRow: lastRow,
        };
      },
    };
  };

  getLastRowIndex = (request, results) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  onGridReady = async (params) => {
    gridApiObj = params.api;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
    };
    let response = await commonPostAPI("voicemailmessages", {
      voicemail_uuid: this.state.id,
      domain_name: sessionStorage.getItem("domain_name"),
    });
    updateData(response);
  };

  getRecord = async (e) => {
    e.preventDefault();
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      cid_number: this.state.cid_number,
      voicemail_uuid: this.state.id,
      domain_name: sessionStorage.getItem("domain_name"),
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("voicemailmessages", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  reSet = () => {
    var partialState = {
      cid_number: '',
    };
    this.setState(partialState);
  }

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  }

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  componentDidMount = () => {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.setState({ id: id });
  };



  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: false,
        editable: true,
        floatingFilter: false,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      cacheBlockSize: this.state.paginationPageSize,
      rowBuffer: 0,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      maxBlocksInCache: 2,
      rowSelection: "multiple",
      pagination: true,
      onGridReady: this.onGridReady,

    };

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    return (
      <>
        <Container
          maxWidth="xl"
          sx={{ marginTop: 2 }}
          className="breadcrumbs-section"
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Custombreadcrumb hierarchy={this.state.hierarchy} />
            </Grid>
          </Grid>
          <Grid item xs={12}>&nbsp;</Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>{" "}
                &nbsp;&nbsp;
                <span>Search</span>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderTop: "1px solid #ddd",
                }}
              >
                <Box
                  className="formBox"
                  component="form"
                  sx={{
                    boxShadow: "none",
                    padding: "0 5px",
                    marginTop: "0",
                  }}
                  onSubmit={this.getRecord}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="cid_number"
                        label="CID Number"
                        name="cid_number"
                        value={this.state.cid_number}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="text"
                        size="small"
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      className="btnSections"
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                      sx={{
                        gap: "20px",
                        padding: "10px 15px",
                      }}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        className="btn btn-primary"
                        sx={{
                          mt: 1,
                          mb: 1,
                          height: "35px",
                          padding: "5px 20px",
                          width: "100px",
                        }}
                        size="small"
                      >
                        Search
                      </Button>
                      <Button
                        type="reset"
                        fullWidth
                        className="btn btn-outline-white"
                        sx={{
                          mt: 1,
                          mb: 1,
                          height: "35px",
                          width: "100px",
                          padding: "5px 20px",
                        }}
                        size="small"
                        onClick={this.reSet}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Container>
        <div className="ag-theme-material gridProperties">
          <AgGridReact
            className="table-structure"
            gridOptions={gridOptions}
            rowModelType="serverSide"
            serverSideStoreType="full"
            rowClassRules={rowClassRules}
            suppressRowClickSelection={true}
            suppressHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          />
        </div>
      </>
    );
  }
}

export default withRouter(Voicemailmessages);

class BtnFileDownloadRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnDownload = this.btnDownload.bind(this);
    this.state = {
      playFlag: false,
      audio: new Audio(),
    }
  }

  btnDownload = async (url) => {
    const link = document.createElement("a");
    link.href = `${url}`;
    let filename = url.split('/').pop();
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  playRowFile = (url) => {
    let { audio } = this.state;
    audio.src = url;
    if (!this.state.playFlag) {
      this.state.audio.play();
    } else {
      this.state.audio.pause();
    }
    this.setState({ playFlag: !this.state.playFlag })
  }

  render() {
    let { file } = this.props.data;
    let htmlData =
      file !== "" ? (
        <div>
          <IconButton aria-label="play" style={{ backgroundColor: "gray", marginTop: '6px' }} size="small">
            {
              this.state.playFlag !== true ?
                <PlayCircleOutlineIcon onClick={() => this.playRowFile('../clock-alarm-8761.mp3')} />
                :
                <PauseCircleOutlineIcon onClick={() => this.playRowFile()} />
            }
          </IconButton>
          <IconButton aria-label="delete" style={{ backgroundColor: "gray", marginTop: '6px', marginLeft: '6px' }} size="small">
            <DownloadIcon onClick={() => this.btnDownload('../clock-alarm-8761.mp3')} />
          </IconButton>
        </div>
      ) : (
        ""
      );
    return htmlData;
  }
}
