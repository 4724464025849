import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Navigate } from "react-router-dom";
import { withRouter } from "../withRouter";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import {
  Button,
  Box,
  Grid,
  Select,
  InputLabel,
  MenuItem
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

var partialState = {};
var gridApiObj;

class VoicemailData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            minWidth: 200,
            editable: false,
          },
          {
            field: "username",
            headerName: "User",
            filter: "agTextColumnFilter",
            tooltipField: "User",
            cellStyle: { color: "blue" },
            editable: false,
            minWidth: 200,
          },
          {
            field: "messages",
            headerName: "Messages",
            filter: "agTextColumnFilter",
            tooltipField: "Messages",
            editable: false,
            minWidth: 200,
          },
        ],
        rowModelType: "serverSide",
        serverSideStoreType: "full",
        enableBrowserTooltips: true,
      },
      paginationPageSize: 50,
      currentPage: 1,
      operationActionText: "",
      open: false,
      operationTitle: "",
      operationAction: "",
      isDelete: false,
      userList: [],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Voicemail",
          action: "voicemail",
        },
      ],
      username: '',
    };

    this.rowSelectionObj = [];
  }

  componentDidMount = async () => {
    let pageObj = {
      domain_uuid: sessionStorage.getItem('domain_uuid'),
    };

    let responseAgent = await commonPostAPI("userslist", pageObj);
    if (responseAgent.length > 0) {
      this.setState({ userList: responseAgent });
    }
  }

  createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 1000);
      },
    };
  };

  createFakeServer = (allData) => {
    return {
      getData: (request) => {
        var rowsForBlock = allData.slice(request.startRow, request.endRow);
        var lastRow = this.getLastRowIndex(request, rowsForBlock);
        return {
          success: true,
          rows: rowsForBlock,
          lastRow: lastRow,
        };
      },
    };
  };

  getLastRowIndex = (request, results) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  onGridReady = async (params) => {
    gridApiObj = params.api;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
    };

    let response = await commonPostAPI("voicemails", {
      domain_uuid: sessionStorage.getItem("domain_uuid"),
    });
    updateData(response);
  };

  getRecord = async (e) => {
    e.preventDefault();
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      username: this.state.username,
      domain_uuid: sessionStorage.getItem("domain_uuid"),
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("voicemails", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  reSet = () => {
    var partialState = {
      username: '',
    };
    this.setState(partialState);
  }

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  }

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onSelectionChanged = (params) => {
    sessionStorage.setItem("voicemail_username", params.data.username);
    let action = `voicemailmessages/${params.data.voicemail_uuid}`;
    this.onOpenAction(action);
  };

  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: false,
        editable: true,
        floatingFilter: false,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      cacheBlockSize: this.state.paginationPageSize,
      rowBuffer: 0,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      maxBlocksInCache: 2,
      rowSelection: "multiple",
      pagination: true,
      onGridReady: this.onGridReady,
      onCellClicked: this.onSelectionChanged,
    };

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    return (
      <>
        <Container
          maxWidth="xl"
          sx={{ marginTop: 2 }}
          className="breadcrumbs-section"
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Custombreadcrumb hierarchy={this.state.hierarchy} />
            </Grid>
          </Grid>
          <Grid item xs={12}>&nbsp;</Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>{" "}
                &nbsp;&nbsp;
                <span>Search</span>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderTop: "1px solid #ddd",
                }}
              >
                <Box
                  className="formBox"
                  component="form"
                  sx={{
                    boxShadow: "none",
                    padding: "0 5px",
                    marginTop: "0",
                  }}
                  onSubmit={this.getRecord}
                >
                  <Grid container spacing={2} xs={12}>
                    <Grid item md={6}>
                      <InputLabel id="demo-simple-select-label">
                        User Name
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="username"
                        id="username"
                        value={this.state.username}
                        label="username"
                        name="username"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="">Select</MenuItem>
                        {this.state.userList.map((item) => (
                          <MenuItem value={item.user_uuid}>{item.username}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={6}>
                      &nbsp;
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>

                    <Grid
                      xs={12}
                      className="btnSections"
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                      sx={{
                        gap: "20px",
                        padding: "10px 15px",
                      }}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        className="btn btn-primary"
                        sx={{
                          mt: 1,
                          mb: 1,
                          height: "35px",
                          padding: "5px 20px",
                          width: "100px",
                        }}
                        size="small"
                      >
                        Search
                      </Button>
                      <Button
                        type="reset"
                        fullWidth
                        className="btn btn-outline-white"
                        sx={{
                          mt: 1,
                          mb: 1,
                          height: "35px",
                          width: "100px",
                          padding: "5px 20px",
                        }}
                        size="small"
                        onClick={this.reSet}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Container>
        <div className="ag-theme-material gridProperties">
          <AgGridReact
            className="table-structure"
            gridOptions={gridOptions}
            rowModelType="serverSide"
            serverSideStoreType="full"
            rowClassRules={rowClassRules}
            suppressRowClickSelection={true}
            suppressHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          />
        </div>
      </>
    );
  }
}

export default withRouter(VoicemailData);
