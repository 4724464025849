import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Navigate } from "react-router-dom";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import {
  Grid,
  Container,
} from '@mui/material';

var partialState = {};
var gridApiObj;
class ActiveData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            editable: false,
            minWidth: 150,
          },
          {
            field: "created",
            headerName: "Start Time",
            filter: "agTextColumnFilter",
            tooltipField: "Type",
            editable: false,
            minWidth: 200,
          },
          {
            field: "cid_name",
            headerName: "CID Name",
            filter: "agTextColumnFilter",
            tooltipField: "CID Name",
            minWidth: 200,
          },
          {
            field: "cid_num",
            headerName: "CID Number",
            filter: "agTextColumnFilter",
            tooltipField: "CID Number",
            minWidth: 200,
          },
          {
            field: "dest",
            headerName: "Dest",
            filter: "agTextColumnFilter",
            tooltipField: "Dest",
            minWidth: 200,
          },
          {
            field: "application",
            headerName: "Application",
            filter: "agTextColumnFilter",
            tooltipField: "Application",
            minWidth: 200,
          },
        ],
        enableBrowserTooltips: true,
      },
      paginationPageSize: 50,
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Active Call",
          action: "activecall",
        },
      ],
      name: "",
    };

    this.rowSelectionObj = [];
  }

  createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 1000);
      },
    };
  };

  createFakeServer = (allData) => {
    return {
      getData: (request) => {
        var rowsForBlock = allData.slice(request.startRow, request.endRow);
        var lastRow = this.getLastRowIndex(request, rowsForBlock);
        return {
          success: true,
          rows: rowsForBlock,
          lastRow: lastRow,
        };
      },
    };
  };

  getLastRowIndex = (request, results) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  onGridReady = async (params) => {
    gridApiObj = params.api;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
    };

    let response = await commonPostAPI("eslcommand", {
      command: "show channels as json",
    });
    if (response.row_count > 0) {
      updateData(response.rows);
    }
  };

  getRecord = async (e) => {
    e.preventDefault();
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
      domain_uuid: sessionStorage.getItem('domain_uuid'),
      command: "show channels as json",
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("eslcommand", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };


  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  }

  reSet = () => {
    var partialState = {
      name: '',
    };
    this.setState(partialState);
  }

  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        resizable: true,
        autoHeight: false,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      cacheBlockSize: this.state.paginationPageSize,
      rowBuffer: 0,
      serverSideStoreType: 'partial',
      maxBlocksInCache: 2,
      pagination: true,
      onGridReady: this.onGridReady,
    };

    let rowClassRules = {
      'rowOdd': function (params) { return (params.rowIndex % 2 === 0); },
      'rowEven': function (params) { return (params.rowIndex % 2 === 1); }
    };

    if (sessionStorage.length === 0) {
      return (<Navigate to="/" replace={true} />);
    }
    return (
      <div className="ag-theme-material gridProperties">

        <box>
          <Container
            maxWidth="xl"
            sx={{ marginTop: 2 }}
            className="breadcrumbs-section"
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Custombreadcrumb hierarchy={this.state.hierarchy} />
              </Grid>
            </Grid>
          </Container>
        </box>
        <AgGridReact
          className="table-structure"
          gridOptions={gridOptions}
          rowModelType="serverSide"
          serverSideStoreType="full"
          rowClassRules={rowClassRules}
          suppressRowClickSelection={true}
        />
      </div>

    );
  }
}

export default withRouter(ActiveData);
