import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import { TextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 290px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 120px;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 24px ${theme.palette.mode === 'dark' ? blue[900] : blue[100]
    };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

var partialState = {};
class TransactionData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        domain_uuid: "Please select domain.",
        transaction_type: "Please select transcation type.",
        amount: "Please enter amount.",
        transaction_note: "Please enter transcation note.",
      },
      alertMessage: "",
      domain_uuid: "0",
      transaction_type: "0",
      amount: "0.000000",
      transaction_note: "",
      domain_list: [],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Transactions",
          action: "Transaction",
        },
        {
          label: "Add Transaction",
          action: "",
        },
      ],
    };
  }

  async componentDidMount() {
    let responseList = await commonPostAPI("domainlist", {});
    this.setState({ domain_list: responseList });
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      if (this.state.amount !== 0) {
        let requestparams = {
          domain_uuid: this.state.domain_uuid,
          transaction_type: this.state.transaction_type,
          amount: this.state.amount,
          transaction_note: this.state.transaction_note,
          user_uuid: sessionStorage.getItem('user_uuid')
        };
        let response = await commonPostAPI("transactionsadd", requestparams);
        this.onOpenAction("Transaction");
      } else {
        this.setState({
          alertMessage: <div>Please enter valid amount.</div>,
        });
      }

    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  handleChangeMultiple = (e) => {
    const selected = e.target.value;
    this.setState({ users: selected });
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;
    switch (e.target.name) {
      case "domain_uuid":
        errors.domain_uuid =
          e.target.value === '0' ? "Please select domain." : "";
        break;
      case "transaction_type":
        errors.transaction_type =
          e.target.value === '0' ? "Please select transcation type." : "";
        break;
      case "amount":
        errors.amount =
          e.target.value === 0.000000 ? "Please enter amount." : "";
        break;
      case "transaction_note":
        errors.transaction_note =
          e.target.value.length < 1 ? "Please enter transcation note." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      domain_list,
      alertMessage,
    } = this.state;


    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Domsin Name</label>
                      <Select
                        fullWidth
                        labelId="domain_uuid"
                        id="domain_uuid"
                        value={this.state.domain_uuid}
                        label="Domain"
                        name="domain_uuid"
                        onChange={this.onChangeItem}
                        size="small"
                        sx={{ mt: 2 }}
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {domain_list.map((item) => (
                          <MenuItem value={item.domain_uuid}>{item.domain_name}</MenuItem>
                        ))}
                      </Select>
                      {errors.domain_uuid.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.domain_uuid}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Transaction Type</label>
                      <Select
                        fullWidth
                        labelId="transaction_type"
                        id="transaction_type"
                        value={this.state.transaction_type}
                        label="transaction_type"
                        name="transaction_type"
                        onChange={this.onChangeItem}
                        size="small"
                        sx={{ mt: 2 }}
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        <MenuItem value="credit">Credit</MenuItem>
                        <MenuItem value="debit">Debit</MenuItem>
                      </Select>
                      {errors.transaction_type.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.transaction_type}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Amount</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="amount"
                        name="amount"
                        value={this.state.amount ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="number"
                        size="small"
                      />
                      {errors.amount.value === '0.000000' ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.amount}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>

                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Tranctions Note</label>
                      <StyledTextarea
                        aria-label="empty textarea"
                        name="transaction_note"
                        id="transaction_note"
                        value={this.state.transaction_note ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                      />
                      {errors.transaction_note.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.transaction_note}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    {alertMessage ? (
                      <Stack className="error-show" spacing={2}>
                        {alertMessage}
                      </Stack>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("Transaction")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(TransactionData);
