import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  dispo_group_name: "",
  dispo_group_uuid: "",
  dispoSelection: [],

};

class DispogroupeditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        dispogroup_name: "Please enter group name.",
        dispoSelection: "Please select dispo",
      },
      alertMessage: "",
      dispogroup_name: "",
      dispo_group_uuid: "",
      dispoSelection: [],
      dispoList: [],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Dispo Group",
          action: "dispogroup",
        },
        {
          label: "Edit Dispo Group Data",
          action: "dispogroupedit",
        },
      ],
    };
  }

  componentWillMount = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);

    let pageObj = {
      domain_uuid: sessionStorage.getItem("domain_uuid")
    };
    let response = await commonPostAPI("dispodata", pageObj);
    this.setState({ dispoList: response });
    this.getDataByID(id);
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let tmpItem = [];
      this.state.dispoList.filter((row) => {
        if (this.state.dispoSelection.indexOf(row.dispo_name) > -1) {
          tmpItem.push(row.dispo_uuid);
        }
        return false;
      });
      let requestparams = {
        dispo_group_uuid: this.state.dispo_group_uuid,
        dispogroup_name: this.state.dispogroup_name,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        dispo: tmpItem,
      };
      let response = await commonPostAPI("updatedispogroup", requestparams);
      if (response.dispo_group_name && response.dispo_group_name !== "") {
        this.setState({ alertMessage: <div>Name already exists.</div> });
      } else {
        this.onOpenAction("dispogroup");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;

    switch (e.target.name) {
      case "dispogroup_name":
        errors.dispogroup_name =
          e.target.value.length < 1 ? "Please enter group name." : "";
        break;
      case "dispoSelection":
        errors.dispoSelection =
          e.target.value.length < 1 ? "Please enter group name." : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  getDataByID = async (id) => {
    let response = await commonPostAPI("getRecorDispoGroupByID", { id: id });
    let groupdata = response.groupdata;
    let groupdatalist = response.groupdatalist;

    let errors = this.state.errors;

    Object.keys(groupdata).forEach(function (key) {
      switch (key) {
        case "dispo_group_name":
          errors.dispogroup_name =
            typeof groupdata[key] !== "undefined" &&
              groupdata[key] !== "" &&
              groupdata[key] != null
              ? ""
              : "Please enter group name.";
          break;
        default:
          break;
      }
    });

    errors.dispoSelection = (typeof groupdatalist !== "undefined" && groupdatalist.length > 0) ? "" : "Please select dispo.";


    let selection = groupdatalist.map((item) => {
      return item.dispo_name;
    })

    var dbData = {
      dispogroup_name: response.groupdata.dispo_group_name,
      dispo_group_uuid: response.groupdata.dispo_group_uuid,
      dispoSelection: selection,
    };

    partialState = dbData;

    this.setState(partialState);
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };


  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      dispogroup_name,
      dispoList,
      dispoSelection,
      alertMessage
    } = this.state;


    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Typography component="h1" variant="h5">
                  Edit Dispo Data
                </Typography>
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Name</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="dispogroup_name"
                        name="dispogroup_name"
                        value={dispogroup_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        size="small"
                      />
                      {errors.dispogroup_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.dispogroup_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Dispo</label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <Select
                        labelId="dispoSelection"
                        id="dispoSelection"
                        name="dispoSelection"
                        multiple
                        value={dispoSelection}
                        onChange={this.onChangeItem}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                        style={{ width: 290 }}
                        MenuProps={MenuProps}
                        autoComplete="off"
                      >
                        {dispoList.map((row) => (
                          <MenuItem key={row.dispo_uuid} value={row.dispo_name}>
                            <Checkbox style={{ backgroundColor: 'gray' }} checked={dispoSelection.indexOf(row.dispo_name) > -1} />
                            <ListItemText style={{ paddingLeft: 10 }} primary={row.dispo_name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.dispoSelection.length > 0 ? (
                        <Stack className="error-show" spacing={2} style={{ paddingTop: 10 }}>
                          {errors.dispoSelection}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item xs={4} sx={{ mt: 5 }}>
                      {alertMessage ? (
                        <Stack className="error-show" spacing={2}>
                          {alertMessage}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("dispogroup")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(DispogroupeditData);
