import React from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function CustombreadcrumbFun(props) {

    const navigate = useNavigate();

    const onOpenAction = (action) => {
        navigate(`/${action}`);
    }

    const length = props.hierarchy.length;
    const breadcrumbDiv = (props.hierarchy.map((item, index) => {
        var current = index + 1;
        return (current < length) ?
            <Link
                key={index}
                underline="hover"
                color="inherit"
                fontSize="small"
                onClick={() => onOpenAction(item.action)}>
                {item.label}
            </Link>
            :
            <Typography color="text.primary" fontSize="small">{item.label}</Typography>;
    }));

    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                {breadcrumbDiv}
            </Breadcrumbs>
        </div>
    );
}
