import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import { TextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';
import { Button, Grid, Select, MenuItem } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import dayjs from 'dayjs';

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  template_uuid: "",
  template_name: "",
  form_title: "",
  tools_label_name: [],
  tools_label_value: [],
  tools_type_name: [],
  tools_type_value: [],
  tools_type_options_label: [],
  tools_type_options_value: [],
  option_caption_name: [],
  option_caption_value: [],
  option_caption_option_name: [],
  option_caption_option_value: [],
  currentOptionIndex: "",
  dynamicValue: [],
  dunamicName: [],
};

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 260px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 120px;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 24px ${theme.palette.mode === 'dark' ? blue[900] : blue[100]
    };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);


class ReviewsformData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        template_name: "Please enter name.",
        form_title: "Please enter form title",
      },
      alertMessage: "",
      template_uuid: props.template_uuid,
      template_name: "",
      form_title: "",
      tools_label_name: [],
      tools_label_value: [],
      tools_type_name: [],
      tools_type_value: [],
      tools_type_options_label: [],
      tools_type_options_value: [],
      option_caption_name: [],
      option_caption_value: [],
      option_caption_option_name: [],
      option_caption_option_value: [],
      open: false,
      currentOptionIndex: "",
      dynamicValue: [],
      typeWithValue: [],
    };
  }

  componentDidMount = async () => {
    this.getDataByID(this.state.template_uuid);
  }

  getDataByID = async (id) => {
    let responseTemplate = await commonPostAPI("getRecordTemplatesByID", { id: id });
    let responseTemplateList = await commonPostAPI("getRecordTemplateListsByID", { id: id });
    let errors = this.state.errors;
    Object.keys(responseTemplate).forEach(function (key) {
      switch (key) {
        case "template_name":
          errors.template_name =
            typeof responseTemplate[key] !== "undefined" &&
              responseTemplate[key] !== "" &&
              responseTemplate[key] != null
              ? ""
              : "Please enter name.";
          break;
        case "form_title":
          errors.form_title =
            typeof responseTemplate[key] !== "undefined" &&
              responseTemplate[key] !== "" &&
              responseTemplate[key] != null
              ? ""
              : "Please enter form title.";
          break;
        default:
          break;
      }
    });

    let current_tools_label_name = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return `Label_Name_${index}`;
    }) : [];

    let current_tools_label_value = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return item.tools_label;
    }) : [];

    let current_tools_type_name = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return `Tools_Name_${index}`;
    }) : [];

    let current_tools_type_value = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return parseInt(item.tools_type);
    }) : [];

    let current_tools_type_options_label = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return JSON.parse(item.tools_options_label);
    }) : [];

    let current_tools_type_options_value = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return JSON.parse(item.tools_options_value);
    }) : [];

    var dbData = {
      errors: errors,
      template_uuid: id,
      template_name: responseTemplate.template_name,
      form_title: responseTemplate.form_title,
      tools_label_name: current_tools_label_name,
      tools_label_value: current_tools_label_value,
      tools_type_name: current_tools_type_name,
      tools_type_value: current_tools_type_value,
      tools_type_options_label: current_tools_type_options_label,
      tools_type_options_value: current_tools_type_options_value,
    };

    partialState = dbData;
    this.setState(partialState);
  };

  saveData = async (event) => {
    let { tools_label_value, tools_type_value } = this.state;
    event.preventDefault();

    if (validateForm(this.state.errors)) {
      let typeWithValue = [];
      let tmpDynamicValue = [];
      for (let index = 0; index < tools_label_value.length; index++) {
        const nameItem = (tools_label_value[index]) ? tools_label_value[index] : '';
        const typeItem = (tools_type_value[index]) ? tools_type_value[index] : '';
        let obj = {
          name: nameItem,
          type: typeItem
        };
        typeWithValue.push(obj);

        obj = {
          name: nameItem,
          value: this.state.dynamicValue[nameItem]
        };

        tmpDynamicValue.push(obj);
      }


      let requestparams = {
        template_uuid: this.state.template_uuid,
        template_name: this.state.template_name,
        form_title: this.state.form_title,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        dynamicValue: tmpDynamicValue,
        typeWithValue: typeWithValue,
        user_uuid: sessionStorage.getItem('user_uuid'),
      };


      await commonPostAPI("addreview", requestparams);
      this.onOpenAction("reviewlist");
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = (e) => {
    e.preventDefault();
    let returnObj = this.state.dynamicValue;
    returnObj[e.target.name] = e.target.value;
    partialState['dynamicValue'] = returnObj;
    this.setState(partialState);
  };

  onChangeDateItem = (name, value) => {
    let data = new Date(value).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric' })
    let returnObj = this.state.dynamicValue;;
    returnObj[name] = data;
    partialState['dynamicValue'] = returnObj;
    this.setState(partialState);
  }

  submitOptionAction = async () => {
    let {
      currentOptionIndex,
      tools_type_options_label,
      tools_type_options_value,
      option_caption_value,
      option_caption_option_value,

    } = this.state;

    let old1 = [...tools_type_options_label];
    old1[currentOptionIndex] = option_caption_value;

    let old2 = [...tools_type_options_value];
    old2[currentOptionIndex] = option_caption_option_value;

    this.setState({
      open: false,
      tools_type_options_label: old1,
      tools_type_options_value: old2,
      currentOptionIndex: "",
      option_caption_name: [],
      option_caption_value: [],
      option_caption_option_name: [],
      option_caption_option_value: [],
    });

  }

  toolsRender = (row) => {
    let label = row.label;
    let name = row.name;
    let value = (row.value && row.value.length > 0) ? row.value : [];
    let type = row.type;
    let optionLabel = row.optionLabel;
    let optionValue = row.optionValue;
    let htmlData = "";
    switch (type) {
      case 1:
        htmlData = (
          <Grid container style={{ marginTop: 0, marginBottom: 0 }} xs={12}>
            <Grid item xs={2}>
              <label>{label}</label>
            </Grid>
            <Grid item xs={10}>
              <TextField
                margin="0"
                required
                fullWidth
                id={name}
                name={name}
                value={value ?? ""}
                onChange={this.onChangeItem}
                autoComplete="off"
                type="text"
                size="small"
              />
            </Grid>
          </Grid>
        );
        break;
      case 2:
        htmlData = (
          <Grid container style={{ marginTop: 0, marginBottom: 0 }} xs={12}>
            <Grid item xs={2}>
              <label>{label}</label>
            </Grid>
            <Grid item xs={10}>
              <StyledTextarea
                aria-label="empty textarea"
                id={name}
                name={name}
                value={value ?? ""}
                onChange={this.onChangeItem}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        );
        break;
      case 3:
        htmlData = (
          <Grid container style={{ marginTop: 0, marginBottom: 0 }} xs={12}>
            <Grid item xs={2}>
              <label>{label}</label>
            </Grid>
            <Grid item xs={10}>
              <Select
                fullWidth
                labelId={name}
                id={name}
                value={value ?? ""}
                label={name}
                name={name}
                onChange={this.onChangeItem}
                size="small"
                autoComplete="off"
              >
                {optionLabel.map((item, index) => {
                  return (
                    <MenuItem key={(optionValue[index] ? optionValue[index] : "")} value={(optionValue[index] ? optionValue[index] : "")}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        );
        break;
      case 4:
        htmlData = (
          <Grid container style={{ marginTop: 0, marginBottom: 0 }} xs={12}>
            <Grid item xs={2}>
              <label>{label}</label>
            </Grid>
            <Grid item xs={10}>
              <Select
                fullWidth
                labelId={name}
                id={name}
                value={value ?? ""}
                label={name}
                name={name}
                onChange={this.onChangeItem}
                size="small"
                autoComplete="off"
                multiple
              >
                {optionLabel.map((item, index) => {
                  return (
                    <MenuItem key={(optionValue[index] ? optionValue[index] : "")} value={(optionValue[index] ? optionValue[index] : "")}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        );
        break;
      case 5:
        htmlData = (
          <Grid container style={{ marginTop: 0, marginBottom: 0 }} xs={12}>
            <Grid item xs={2}>
              <label>{label}</label>
            </Grid>
            <Grid item xs={10}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DesktopDateTimePicker',
                  ]}
                  sx={{
                    width: '100%',
                    mt: 0
                  }}
                >
                  <DesktopDateTimePicker
                    name={name}
                    id={name}
                    formatDensity="-"
                    ampm={false}
                    onChange={(newValue) => {
                      this.onChangeDateItem(name, newValue);
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    timeSteps={
                      {
                        'minutes': '1',
                        'seconds': '1'
                      }
                    }
                    slotProps={
                      {
                        actionBar: {
                          actions: ["today", "accept", "cancel"]
                        },
                        width: 200
                      }
                    }
                    sx={{
                      width: '95%',
                    }}
                    className="iconBgcolor"
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    size="small"
                    value={dayjs(value)}
                    autoComplete="off"
                  />
                </DemoContainer>

              </LocalizationProvider>
            </Grid>
          </Grid>
        );
        break;
      default:
        break;
    }
    return htmlData;
  }

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      form_title,
      tools_label_name,
      tools_label_value,
      tools_type_value,
      tools_type_options_label,
      tools_type_options_value,
      alertMessage,
      dynamicValue
    } = this.state;


    let toolsArray = [];
    for (var i = 0; i < tools_label_name.length; i++) {
      let currentName = tools_label_value[i];//.replace(/\s/g, "");
      let obj = {
        label: tools_label_value[i],
        name: currentName,
        value: (dynamicValue[currentName]) ? dynamicValue[currentName] : "",
        type: tools_type_value[i],
        optionLabel: tools_type_options_label[i],
        optionValue: tools_type_options_value[i],
      };
      toolsArray.push(this.toolsRender(obj))
    }

    return (
      <div>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 0",
              }}
            >
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 0, width: "100%" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12} sx={{ textAlign: 'center' }}>
                      <label><b>Title :</b> </label>
                      <label>{form_title}</label>
                    </Grid>
                  </Grid>
                  <Grid container xs={12}>&nbsp;</Grid>

                  {toolsArray}

                  <Grid container xs={12}>&nbsp;</Grid>

                  {alertMessage ? (
                    <Stack className="error-show" spacing={2}>
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Grid container xs={12}>&nbsp;</Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(ReviewsformData);
