import React, { Component } from "react";
import { BoxDtmf } from "./atoms";
import Endcallbutton from "./Endcallbutton";
import Backbutton from "./Backbutton";

class Dtmf extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let {
      dtmfNumber,
      enterDtmfNumber,
      removeDtmfNumber,
      number,
      onCallEnded,
      opened,
    } = this.props;
    return (
      <BoxDtmf opened={opened} className="dialer-setup">
        <table
          style={{ width: "100%", color: "white", "minHeight": "350px" }}
          border="0"
        >
          <tr>
            <td colSpan={3} style={{ width: "100%", textAlign: "center" }}>
              {number !== "" ? number : "\u00A0"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={3}
              style={{
                width: "100%",
                textAlign: "center",
                color: "#624bff",
                height: "30px",
                background: "#454f5b",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            >
              {dtmfNumber !== "" ? dtmfNumber : "\u00A0"}
            </td>
          </tr>
          <tr>
            <td height="10px"></td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(1)}>
                1
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(2)}>
                2
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(3)}>
                3
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(4)}>
                4
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(5)}>
                5
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(6)}>
                6
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(7)}>
                7
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(8)}>
                8
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(9)}>
                9
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber("*")}>
                *
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber(0)}>
                0
              </div>
            </td>
            <td style={{ width: "33%", textAlign: "center" }}>
              <div className="dial-number" onClick={() => enterDtmfNumber("#")}>
                #
              </div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td
              className="endCall"
              style={{ width: "33%", textAlign: "center" }}
            >
              <Endcallbutton onCallEnded={onCallEnded} />{" "}
            </td>
            <td style={{ width: "66%", textAlign: "center" }}>
              <Backbutton
                number={number}
                removeNumber={() => removeDtmfNumber()}
              />
            </td>
          </tr>
        </table>
      </BoxDtmf>
    );
  }
}

export default Dtmf;
