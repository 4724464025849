import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI, commonDelAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Box, Grid, Button, Typography, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import { Navigate } from "react-router-dom";
// import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from '@mui/icons-material/Preview';
import RemoveIcon from "@mui/icons-material/Remove";
import ReplayIcon from "@mui/icons-material/Replay";
import { withRouter } from "../withRouter";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppConfig from "../../Constants/AppConfig";

var partialState = {};
var gridApiObj;
class ConferenceData extends Component {

  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            editable: false,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            minWidth: 150,
          },
          {
            field: "conference_room_name",
            headerName: "name",
            filter: "agTextColumnFilter",
            tooltipField: "Name",
            cellStyle: { color: "blue" },
            editable: false,
            minWidth: 200,
          },
          {
            field: "participant_pin",
            headerName: "Conference ID",
            filter: "agTextColumnFilter",
            tooltipField: "Conference ID",
            editable: false,
            minWidth: 200,
          },
          {
            field: "start_datetime",
            headerName: "Start Time",
            minWidth: 300,
            filter: "agDateColumnFilter",
            tooltipField: "Email",
          },
          {
            field: "stop_datetime",
            headerName: "Stop Time",
            filter: "agDateColumnFilter",
            tooltipField: "extension",
            minWidth: 200,
          },
        ],
        rowModelType: "serverSide",
        serverSideStoreType: "full",
        enableBrowserTooltips: true,
      },
      paginationPageSize: 50,
      currentPage: 1,
      operationActionText: "",
      open: false,
      operationTitle: "",
      operationAction: "",
      isDelete: false,
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Conference",
          action: "conference",
        },
      ],
      name: ""
    };

    this.rowSelectionObj = [];
  }

  createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 1000);
      },
    };
  };

  createFakeServer = (allData) => {
    return {
      getData: (request) => {
        var rowsForBlock = allData.slice(request.startRow, request.endRow);
        var lastRow = this.getLastRowIndex(request, rowsForBlock);
        return {
          success: true,
          rows: rowsForBlock,
          lastRow: lastRow,
        };
      },
    };
  };

  getLastRowIndex = (request, results) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  onGridReady = async (params) => {
    gridApiObj = params.api;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
    };

    let response = await commonPostAPI("conference", {});
    updateData(response);
  };

  getRecord = async (e) => {
    e.preventDefault();
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("conference", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  getRefresh = async () => {
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("conference", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
    this.rowSelectionObj = [];
  };

  reSet = () => {
    var partialState = {
      name: '',
    };
    this.setState(partialState);
  }

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onSelectionChanged = (params) => {
    const selectedRows = params.api.getSelectedRows();
    this.rowSelectionObj = selectedRows;
  };

  onViewRow = async () => {
    if (typeof this.rowSelectionObj === "undefined") {
      this.setState({
        operationTitle: "Row Selection!",
        operationAction: "Please select record",
      });
      this.handleClickOpen();
      return true;
    } else {
      var count = Object.keys(this.rowSelectionObj).length;
      if (count === 0) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select record!",
        });
        this.handleClickOpen();
        return true;
      } else if (count > 1) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select single record only",
        });
        this.handleClickOpen();
        return true;
      }
    }
    var getID = this.rowSelectionObj.map((row) => {
      return row.conference_room_uuid;
    });
    let id = getID.join();
    this.rowSelectionObj = [];
    this.onOpenAction(`conferenceview/${id}`);
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, operationTitle: "", operationAction: "" });
  };

  onRemoveSelectedConfirm = async () => {
    let response = await commonDelAPI(
      "deleteRecordConferenceByID",
      this.rowSelectionObj
    );

    this.handleClose();
    this.getRefresh();
    return response;
  };

  onRemoveSelected = () => {
    if (typeof this.rowSelectionObj === "undefined") {
      this.setState({
        operationTitle: "Row Selection!",
        operationAction: "Please select record",
      });
      this.handleClickOpen();
      return true;
    } else {
      var count = Object.keys(this.rowSelectionObj).length;
      if (count === 0) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select record!",
        });
        this.handleClickOpen();
        return true;
      } else {
        this.setState({
          operationTitle: "Delete Selection!",
          operationAction: "Want to delete?",
          isDelete: true,
        });
        this.handleClickOpen();
        return true;
      }
    }
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  }

  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: false,
        editable: true,
        floatingFilter: false,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      cacheBlockSize: this.state.paginationPageSize,
      paginationPageSize: this.state.paginationPageSize,
      rowBuffer: 0,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      maxBlocksInCache: 2,
      rowSelection: "multiple",
      pagination: true,
      onGridReady: this.onGridReady,
      onSelectionChanged: this.onSelectionChanged,
    };

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    let { open, operationTitle, operationAction, isDelete } = this.state;
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    return (
      <div className="ag-theme-material gridProperties">
        <box sx={{ minWidth: "100%" }}>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="messageDialog"
          >
            <DialogTitle id="alert-dialog-title">{operationTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {operationAction}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {isDelete === true ? (
                <Button
                  onClick={this.handleClose}
                  className="btn-outline-white"
                >
                  No
                </Button>
              ) : (
                ""
              )}
              {isDelete === true ? (
                <Button
                  onClick={this.onRemoveSelectedConfirm}
                  autoFocus
                  className="btn-primary"
                >
                  Yes
                </Button>
              ) : (
                <Button
                  onClick={this.handleClose}
                  autoFocus
                  className="btn-outline-white"
                >
                  No
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </box>
        <box>
          <Container
            maxWidth="xl"
            sx={{ marginTop: 2 }}
            className="breadcrumbs-section"
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Custombreadcrumb hierarchy={this.state.hierarchy} />
              </Grid>
              <Grid item md={6} xs={12} className="btn-section">
                <Typography
                  variant="h8"
                  gutterBottom
                  sx={{
                    color: "#952424",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                  justify="flex-end"
                >
                  {(sessionStorage.getItem('CONFERENCE') & AppConfig.VIEW_ACTION === AppConfig.VIEW_ACTION) ?
                    <Button
                      startIcon={<PreviewIcon />}
                      variant="contained"
                      size="small"
                      onClick={() => this.onViewRow()}
                    >
                      View
                    </Button> : ""}
                  {(sessionStorage.getItem('CONFERENCE') & AppConfig.ADD_ACTION === AppConfig.ADD_ACTION) ?
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      size="small"
                      onClick={() => this.onOpenAction("conferenceadd")}
                    >
                      Add
                    </Button> : ""}
                  {(sessionStorage.getItem('CONFERENCE') & AppConfig.DELETE_ACTION === AppConfig.DELETE_ACTION) ?
                    <Button
                      startIcon={<RemoveIcon />}
                      variant="contained"
                      size="small"
                      onClick={() => this.onRemoveSelected()}
                    >
                      Remove
                    </Button> : ""}
                  {(sessionStorage.getItem('CONFERENCE') & AppConfig.VIEW_ACTION === AppConfig.VIEW_ACTION) ?
                    <Button
                      startIcon={<ReplayIcon />}
                      variant="contained"
                      size="small"
                      onClick={() => this.getRefresh()}
                    >
                      Reload
                    </Button> : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>{" "}
                    &nbsp;&nbsp;
                    <span>Search</span>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      borderTop: "1px solid #ddd",
                    }}
                  >
                    <Box
                      className="formBox"
                      component="form"
                      sx={{
                        boxShadow: "none",
                        padding: "0 5px",
                        marginTop: "0",
                      }}
                      onSubmit={this.getRecord}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            value={this.state.name}
                            onChange={this.onChangeItem}
                            autoComplete="off"
                            autoFocus
                            type="text"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          &nbsp;
                        </Grid>
                        <Grid item xs={12} md={4}>
                          &nbsp;
                        </Grid>
                        <Grid
                          xs={12}
                          className="btnSections"
                          justifyContent="center"
                          display="flex"
                          alignItems="center"
                          sx={{
                            gap: "20px",
                            padding: "10px 15px",
                          }}
                        >
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              width: "100px",
                            }}
                            size="small"
                          >
                            Search
                          </Button>
                          <Button
                            type="reset"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              width: "100px",
                              padding: "5px 20px",
                            }}
                            size="small"
                            onClick={this.reSet}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Container>
        </box>
        <div style={{ width: "100%", height: '100%' }}>
          <AgGridReact
            id="userGrid"
            className="table-structure"
            gridOptions={gridOptions}
            rowModelType="serverSide"
            serverSideStoreType="full"
            rowClassRules={rowClassRules}
            suppressRowClickSelection={true}
            suppressHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ConferenceData);

