import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Navigate } from "react-router-dom";
import { withRouter } from "../withRouter";
import { Button } from "@mui/material";

let intervalDataQueue = "";
class QueuecallData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            field: "joined_epoch",
            headerName: "Time",
            tooltipField: "Type",
            editable: false,
            cellStyle: { textAlign: "left" },
            minWidth: 200,
          },
          {
            field: "queue",
            headerName: "Queue",
            tooltipField: "Type",
            editable: false,
            cellStyle: { textAlign: "left" },
            minWidth: 200,
          },
          {
            field: "cid_number",
            headerName: "CID Name",
            tooltipField: "CID Name",
            cellStyle: { textAlign: "left" },
            minWidth: 200,
          },
          {
            field: "cid_name",
            headerName: "CID Number",
            tooltipField: "CID Number",
            cellStyle: { textAlign: "left" },
            minWidth: 200,
          },
          {
            field: "state",
            headerName: "state",
            tooltipField: "state",
            cellStyle: { textAlign: "left" },
            minWidth: 200,
          },
          {
            field: "serving_agent",
            headerName: "Agent",
            tooltipField: "Application",
            cellStyle: { textAlign: "left" },
            minWidth: 200,
          },
          {
            field: "queue",
            headerName: "Action",
            tooltipField: "Action",
            cellRenderer: BtnSendBargeRenderer,
            cellStyle: { textAlign: "left" },
            minWidth: 200,
          },
        ],
        enableBrowserTooltips: true,
      },
      rowData: [],
    };
  }

  getRecord = async () => {
    let queue_name = `${this.props.queue_extension}@${sessionStorage.getItem(
      "domain_name"
    )}`;
    let cmd = `json {"command": "callcenter_config","data": {"arguments":"queue list members", "queue_name":"${queue_name}"}} `;
    cmd = `json {"command": "callcenter_config","data": { "arguments": "member list" } } `;

    let responseMemebers = await commonPostAPI("eslcommand", { command: cmd });

    if (responseMemebers && responseMemebers.response && responseMemebers.response.length > 0) {
      let AbandonedList = [];
      let waitingList = [];
      let serviceList = [];
      let finalList = responseMemebers.response;
      if (this.props.queue_extension !== "0") {
        AbandonedList = finalList.filter((item) => {
          return item.state === "Abandoned";
        });

        waitingList = finalList.filter((item) => {
          return item.state === "Waiting" || item.state === "Trying";
        });

        serviceList = finalList.filter((item) => {
          return item.state === "Answered";
        });
      }
      this.setState({
        rowData: finalList,
        abandonedCalls: AbandonedList.length,
        waitingCalls: waitingList.length,
        serviceCalls: serviceList.length,
      });
    } else {
      this.setState({ rowData: [] });
    }
  };

  componentDidMount = async () => {
    this.getRecord();
    intervalDataQueue = setInterval(() => {
      this.getRecord();
    }, 60000);
  };

  componentWillUnmount = () => {
    clearInterval(intervalDataQueue);
  }

  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        resizable: true,
        autoHeight: false,
        editable: true,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      rowData: this.state.rowData,
      pagination: false,
    };

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    let divId = "grid" + Math.random();
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    return (
      <div className="ag-theme-material gridProperties" key={divId}>
        <AgGridReact
          className="table-structure"
          gridOptions={gridOptions}
          rowModelType="clientSide"
          rowClassRules={rowClassRules}
          suppressHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
        />
      </div>
    );
  }
}

export default withRouter(QueuecallData);

class BtnSendBargeRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnBarge = this.btnBarge.bind(this);
    this.btnPickup = this.btnPickup.bind(this);
  }

  btnBarge = async () => {
    let data = this.props.data;
    let user_session_extension = sessionStorage.getItem("extension");
    let domain_name = sessionStorage.getItem("domain_name");
    let cmd = `originate {origination_caller_id_name=eavesdrop,origination_caller_id_number=${data.cid_number}}user/${user_session_extension}@${domain_name} &eavesdrop(${data.session_uuid})`;
    await commonPostAPI("eslcommand", { command: cmd });
  };

  btnPickup = async () => {
    let data = this.props.data;
    let user_session_extension = sessionStorage.getItem("extension");
    let domain_name = sessionStorage.getItem("domain_name");
    let cmd = `uuid_transfer ${data.session_uuid} ${user_session_extension} XML ${domain_name}`;
    await commonPostAPI("eslcommand", { command: cmd });
  };

  render() {
    let { state } = this.props.data;
    let htmlData =
      state !== "Abandoned" ? (
        <div style={{ width: '40px' }}>
          <Button
            size="small"
            className="btn btn-primary"
            sx={{ mb: "5px", mt: "5px", height: "35px" }}
            onClick={this.btnBarge}
          >
            Barge
          </Button>
          &nbsp;
          <Button
            className="btn btn-primary"
            size="small"
            sx={{ mb: "5px", mt: "5px", height: "35px" }}
            onClick={this.btnPickup}
          >
            Pickup
          </Button>
        </div>
      ) : (
        ""
      );
    return htmlData;
  }
}
