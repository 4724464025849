import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Menubar from "./Menubar";
import Phone from "./Dialpad/Phone";

class Private extends Component {
  render() {
    return (
      <>
        {sessionStorage.length > 0 ? (
          <>
            <Menubar />
            <Phone />
          </>
        ) : (
          <Navigate to="/" replace={true} />
        )}
      </>
    );
  }
}

export default Private;
