import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import AppConfig from "../../Constants/AppConfig";
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  username: "",
  password: "",
  id: "",
  email: "",
  outbound_caller_id_number: "",
  status: "true",
  domain_uuid: "",
  domain_name: "",
  extension_uuid: "",
  extension: "",
  forward_all_destination_action: "0",
  forward_all_destination_action_entry: "0",
  forward_busy_destination_action: "0",
  forward_busy_destination_action_entry: "0",
  forward_no_answer_destination_action: "0",
  forward_no_answer_destination_action_entry: "0",
  forward_user_not_registered_destination_action: "0",
  forward_user_not_registered_destination_action_entry: "0",
  userrole: "",
};

class UsereditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        username: "Please enter Username.",
        email: "Please enter Email.",
        password: "Please enter Password.",
        status: "Please enter status.",
        userrole: "Please select user role.",
        department_id: "Please select department.",
      },
      alertMessage: "",
      username: "",
      password: "",
      email: "",
      outbound_caller_id_number: "",
      status: "1",
      domain_uuid: "",
      domain_name: "",
      extension_uuid: "",
      extension: "",
      forward_all_destination_action: "0",
      forward_all_destination_action_entry: "0",
      forward_busy_destination_action: "0",
      forward_busy_destination_action_entry: "0",
      forward_no_answer_destination_action: "0",
      forward_no_answer_destination_action_entry: "0",
      forward_user_not_registered_destination_action: "0",
      forward_user_not_registered_destination_action_entry: "0",
      forwardDestinationList: [],
      busyDestinationList: [],
      answerDestinationList: [],
      registerDestinationList: [],
      userrole: "",
      departmentlist: [],
      department_id: '0',
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "User",
          action: "user",
        },
        {
          label: "Edit User",
          action: "useredit",
        },
      ],
    };
  }

  componentDidMount = async () => {
    let departmentlist = await commonPostAPI("departmentlist", { domain_uuid: sessionStorage.getItem('domain_uuid') });
    this.setState({ departmentlist: departmentlist });

    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.getDataByID(id);
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        id: partialState.id,
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        outbound_caller_id_number: this.state.outbound_caller_id_number,
        status: this.state.status,
        userrole: this.state.userrole,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        domain_name: sessionStorage.getItem("domain_name"),
        extension_uuid: this.state.extension_uuid,
        extension: this.state.extension,
        department_id: this.state.department_id,
        forward_all_destination_action_entry:
          this.state.forward_all_destination_action_entry,
        forward_busy_destination_action_entry:
          this.state.forward_busy_destination_action_entry,
        forward_no_answer_destination_action_entry:
          this.state.forward_no_answer_destination_action_entry,
        forward_user_not_registered_destination_action_entry:
          this.state.forward_user_not_registered_destination_action_entry,
        forward_all_destination_action:
          this.state.forward_all_destination_action,
        forward_busy_destination_action:
          this.state.forward_busy_destination_action,
        forward_no_answer_destination_action:
          this.state.forward_no_answer_destination_action,
        forward_user_not_registered_destination_action:
          this.state.forward_user_not_registered_destination_action,
      };

      let response = await commonPostAPI("updateuser", requestparams);
      if (response.username && response.username !== "") {
        this.setState({ alertMessage: <div>Username already exists.</div> });
      } else {
        this.onOpenAction("user");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onChangeItem = async (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;
    if (e.target.name === "forward_all_destination_action") {
      if (e.target.value !== '6') {
        await this.timeOutActionForwardingChange(e, "1");
      } else {
        this.setState({ forward_all_destination_action_entry: "" });
      }
      await this.timeOutActionForwardingChange(e, "1");
    } else if (e.target.name === "forward_busy_destination_action") {
      await this.timeOutActionForwardingChange(e, "2");
    } else if (e.target.name === "forward_no_answer_destination_action") {
      await this.timeOutActionForwardingChange(e, "3");
    } else if (
      e.target.name === "forward_user_not_registered_destination_action"
    ) {
      await this.timeOutActionForwardingChange(e, "4");
    } else if (e.target.name === "forward_all_destination_action_entry") {
      this.setState({ forward_all_destination_action_entry: e.target.value });
    } else if (e.target.name === "forward_busy_destination_action_entry") {
      this.setState({ forward_busy_destination_action_entry: e.target.value });
    } else if (e.target.name === "forward_no_answer_destination_action_entry") {
      this.setState({
        forward_no_answer_destination_action_entry: e.target.value,
      });
    } else if (
      e.target.name === "forward_user_not_registered_destination_action_entry"
    ) {
      this.setState({
        forward_user_not_registered_destination_action_entry: e.target.value,
      });
    } else {
      switch (e.target.name) {
        case "username":
          errors.username =
            e.target.value.length < 1 ? "Please enter username." : "";
          break;
        case "password":
          errors.password =
            e.target.value.length < 1 ? "Please enter password." : "";
          break;
        case "email":
          errors.email = e.target.value.length < 1 ? "Please enter email." : "";
          break;
        case "outbound_caller_id_number":
          errors.outbound_caller_id_number =
            e.target.value.length < 1 ? "Please enter Outbound Caller ID." : "";
          break;
        case "status":
          errors.status =
            e.target.value.length < 1 ? "Please select Status." : "";
          break;
        case "userrole":
          errors.userrole =
            e.target.value.length < 1 ? "Please select User Role." : "";
          break;
        default:
          break;
      }
      this.setState({ errors, [e.target.name]: e.target.value });
      this.setState(partialState);
    }
  };

  getDataByID = async (id) => {
    let response = await commonPostAPI("getRecordUserByEditID", { id: id });
    let errors = this.state.errors;
    Object.keys(response).forEach(function (key) {
      switch (key) {
        case "username":
          errors.username =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please enter username.";
          break;
        case "password":
          errors.password =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please enter password.";
          break;
        case "email":
          errors.email =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please enter email.";
          break;
        case "user_enabled":
          errors.status =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select status.";
          break;
        case "add_role":
          errors.userrole =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select User Role.";
          break;
        case "department_id":
          errors.department_id =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select Department.";
          break;
        default:
          break;
      }
    });

    let clone_forwardDestinationListNew = [
      ...this.state.forwardDestinationList,
    ];
    let clone_busyDestinationListNew = [...this.state.busyDestinationList];
    let clone_answerDestinationListNew = [...this.state.answerDestinationList];
    let clone_registerDestinationListNew = [
      ...this.state.registerDestinationList,
    ];

    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");

    if (response.forward_all_destination_option !== "0") {
      clone_forwardDestinationListNew = await commonPostAPI(
        "callactionforwarding",
        {
          type: response.forward_all_destination_option,
          domain_uuid: domain_uuid,
          domain_name: domain_name,
        }
      );
    }

    if (response.forward_busy_destination_option !== "0") {
      clone_busyDestinationListNew = await commonPostAPI(
        "callactionforwarding",
        {
          type: response.forward_busy_destination_option,
          domain_uuid: domain_uuid,
          domain_name: domain_name,
        }
      );
    }

    if (response.forward_no_answer_destination_option !== "0") {
      clone_answerDestinationListNew = await commonPostAPI(
        "callactionforwarding",
        {
          type: response.forward_no_answer_destination_option,
          domain_uuid: domain_uuid,
          domain_name: domain_name,
        }
      );
    }

    if (response.forward_user_not_registered_destination_option !== "0") {
      clone_registerDestinationListNew = await commonPostAPI(
        "callactionforwarding",
        {
          type: response.forward_user_not_registered_destination_option,
          domain_uuid: domain_uuid,
          domain_name: domain_name,
        }
      );
    }

    let dbData = {
      username: response.username,
      password: response.password,
      id: response.user_uuid,
      outbound_caller_id_number: response.outbound_caller_id_number,
      email: response.email,
      status: response.user_enabled,
      extension_uuid: response.extension_uuid,
      extension: response.extension,
      forward_all_destination_action: response.forward_all_destination_option,
      forward_all_destination_action_entry: response.forward_all_destination,
      forward_busy_destination_action: response.forward_busy_destination_option,
      forward_busy_destination_action_entry: response.forward_busy_destination,
      forward_no_answer_destination_action:
        response.forward_no_answer_destination_option,
      forward_no_answer_destination_action_entry:
        response.forward_no_answer_destination,
      forward_user_not_registered_destination_action:
        response.forward_user_not_registered_destination_option,
      forward_user_not_registered_destination_action_entry:
        response.forward_user_not_registered_destination,
      forwardDestinationList: (clone_forwardDestinationListNew.length > 0) ? clone_forwardDestinationListNew : [],
      busyDestinationList: (clone_busyDestinationListNew.length > 0) ? clone_busyDestinationListNew : [],
      answerDestinationList: (clone_answerDestinationListNew.length > 0) ? clone_answerDestinationListNew : [],
      registerDestinationList: (clone_registerDestinationListNew.length > 0) ? clone_registerDestinationListNew : [],
      userrole: response.add_role,
      department_id: response.department_id,
    };

    partialState = dbData;
    this.setState(partialState);
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  timeOutActionForwardingChange = async (e, method) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    let response = await commonPostAPI("callactionforwarding", {
      type: value,
      domain_uuid: domain_uuid,
      domain_name: domain_name,
    });

    switch (method) {
      case "1":
        this.setState({
          forwardDestinationList: response,
          forward_all_destination_action_entry: "0",
          forward_all_destination_action: value,
        });
        break;
      case "2":
        this.setState({
          busyDestinationList: response,
          forward_busy_destination_action_entry: "0",
          forward_busy_destination_action: value,
        });
        break;
      case "3":
        this.setState({
          answerDestinationList: response,
          forward_no_answer_destination_action_entry: "0",
          forward_no_answer_destination_action: value,
        });
        break;
      case "4":
        this.setState({
          registerDestinationList: response,
          forward_user_not_registered_destination_action_entry: "0",
          forward_user_not_registered_destination_action: value,
        });
        break;
      default:
        break;
    }
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      username,
      password,
      email,
      outbound_caller_id_number,
      status,
      alertMessage,
      forward_all_destination_action,
      forward_all_destination_action_entry,
      forward_busy_destination_action,
      forward_busy_destination_action_entry,
      forward_no_answer_destination_action,
      forward_no_answer_destination_action_entry,
      forward_user_not_registered_destination_action,
      forward_user_not_registered_destination_action_entry,
      forwardDestinationList,
      busyDestinationList,
      answerDestinationList,
      registerDestinationList,
      userrole,
      extension,
      departmentlist,
      department_id,
    } = this.state;

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Username</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        name="username"
                        value={username ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="text"
                        size="small"
                      />
                      {errors.username.length > 0 ? (
                        <Stack
                          className="error-show"
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          {errors.username}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Password</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        name="password"
                        value={password ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="password"
                        size="small"
                      />
                      {errors.password.length > 0 ? (
                        <Stack
                          className="error-show"
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          {errors.password}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Email</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        name="email"
                        type="text"
                        id="Email"
                        value={email ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        size="small"
                      />
                      {errors.email.length > 0 ? (
                        <Stack
                          className="error-show"
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          {errors.email}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Role</label>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Select
                            fullWidth
                            labelId="userrole"
                            id="userrole"
                            value={
                              userrole
                            }
                            label="userrole"
                            name="userrole"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="1">Admin</MenuItem>
                            <MenuItem value="2">Supervisor</MenuItem>
                            <MenuItem value="3">Agent</MenuItem>
                            <MenuItem value="4">User</MenuItem>
                          </Select>
                          {errors.userrole.length > 0 ? (
                            <Stack
                              className="error-show"
                              sx={{ width: "100%" }}
                              spacing={2}
                            >
                              {errors.userrole}
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Outbound Caller ID</label>
                      <TextField
                        margin="normal"
                        name="outbound_caller_id_number"
                        type="text"
                        id="outbound_caller_id_number"
                        value={outbound_caller_id_number ?? ""}
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <label>Status</label>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Select
                            labelId="status"
                            fullWidth
                            id="status"
                            value={status}
                            label="Status"
                            name="status"
                            onChange={this.onChangeItem}
                          >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">In Active</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={3}>
                      <label>Extension</label>
                      <Grid item xs={12}><h4>{extension}</h4></Grid>
                    </Grid>

                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={6}>
                      <label>Call Forward</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_all_destination_action"
                            id="forward_all_destination_action"
                            value={forward_all_destination_action}
                            label="forward_all_destination_action"
                            name="forward_all_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                            <MenuItem value="6">Custom</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          {
                            (forward_all_destination_action === '6') ?
                              <TextField
                                margin="normal"
                                required
                                id="forward_all_destination_action_entry"
                                name="forward_all_destination_action_entry"
                                value={forward_all_destination_action_entry ?? ""}
                                onChange={this.onChangeItem}
                                autoComplete="off"
                                type="text"
                                size="small"
                              />
                              :
                              <Select
                                fullWidth
                                labelId="forward_all_destination_action_entry"
                                id="forward_all_destination_action_entry"
                                value={forward_all_destination_action_entry}
                                label="forward_all_destination_action_entry"
                                name="forward_all_destination_action_entry"
                                onChange={this.onChangeItem}
                                size="small"
                                autoComplete="off"
                              >
                                <MenuItem value="0">Select</MenuItem>
                                {forwardDestinationList.map((item) => {
                                  return (
                                    <MenuItem key={item.value} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                          }

                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <label>On Busy</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_busy_destination_action"
                            id="forward_busy_destination_action"
                            value={forward_busy_destination_action}
                            label="forward_busy_destination_action"
                            name="forward_busy_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_busy_destination_action_entry"
                            id="forward_busy_destination_action_entry"
                            value={forward_busy_destination_action_entry}
                            label="forward_busy_destination_action_entry"
                            name="forward_busy_destination_action_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {busyDestinationList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={6}>
                      <label>No Answer</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_no_answer_destination_action"
                            id="forward_no_answer_destination_action"
                            value={forward_no_answer_destination_action}
                            label="forward_no_answer_destination_action"
                            name="forward_no_answer_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_no_answer_destination_action_entry"
                            id="forward_no_answer_destination_action_entry"
                            value={forward_no_answer_destination_action_entry}
                            label="forward_no_answer_destination_action_entry"
                            name="forward_no_answer_destination_action_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {(answerDestinationList.length > 0) ? answerDestinationList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            }) : ""}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <label>Not Registered</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_user_not_registered_destination_action"
                            id="forward_user_not_registered_destination_action"
                            value={
                              forward_user_not_registered_destination_action
                            }
                            label="forward_user_not_registered_destination_action"
                            name="forward_user_not_registered_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_user_not_registered_destination_action_entry"
                            id="forward_user_not_registered_destination_action_entry"
                            value={
                              forward_user_not_registered_destination_action_entry
                            }
                            label="forward_user_not_registered_destination_action_entry"
                            name="forward_user_not_registered_destination_action_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="off"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {registerDestinationList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3.1}>
                      <label>Department</label>
                      <Select
                        fullWidth
                        labelId="department_id"
                        id="department_id"
                        value={department_id}
                        label="department_id"
                        name="department_id"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {departmentlist.map((item) => {
                          return (
                            <MenuItem key={item.department_id} value={item.department_id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                  {alertMessage ? (
                    <Stack
                      className="error-show"
                      sx={{ width: "100%" }}
                      spacing={2}
                    >
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}

                  <Grid container justifyContent="flex-end">
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("user")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withRouter(UsereditData);
