import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import { withRouter } from "../withRouter";
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  ring_group_name: "",
  ring_group_strategy: "",
  ring_group_timeout: "",
  ring_group_timeout_entry: "",
  ring_group_enabled: "",
  user_name: "",
  users: "",
  domain_uuid: "",
  domain_name: "",
  ring_group_extension: "",
  ring_group_uuid: "",
  ring_group_greeting: "",
  ring_group_call_timeout: "",
  userType: "3",
};

class RinggroupeditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        ring_group_name: "Please enter name.",
        ring_group_strategy: "",
        ring_group_timeout: "",
        ring_group_timeout_entry: "",
        ring_group_enabled: "",
        ring_group_call_timeout: ""
      },
      alertMessage: "",
      ring_group_name: "",
      ring_group_strategy: "simultaneous",
      ring_group_timeout: "1",
      ring_group_enabled: true,
      ring_group_timeout_entry: "hangup:",
      ring_group_uuid: "",
      ring_group_extension: "",
      ring_group_call_timeout: 0,
      user_name: "",
      users: "",
      userList: [],
      timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      ring_group_greeting: "0",
      ring_group_greetingList: [],
      userType: "3",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Ring Group",
          action: "ringgroup",
        },
        {
          label: "Edit Ring Group",
          action: "ringgroupedit",
        },
      ],
    };
  }

  timeOutActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({
        timeOutEntryList: response,
        ring_group_timeout_entry: "0",
      });
    } else {
      this.setState({
        timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
        ring_group_timeout_entry: "0",
      });
    }
  };

  componentDidMount = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let responseList = await commonPostAPI("recordinglist", {
      domain_uuid: domain_uuid,
    });
    this.setState({ ring_group_greetingList: responseList });

    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.getDataByID(id);
  };

  saveData = async (event) => {
    event.preventDefault();

    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      const finalUser = this.state.userList.filter((item) => {
        if (this.state.users.includes(item.username)) {
          return true;
        } else {
          return false;
        }
      });

      let requestparams = {
        ring_group_uuid: this.state.ring_group_uuid,
        ring_group_extension: this.state.ring_group_extension,
        ring_group_name: this.state.ring_group_name,
        ring_group_strategy: this.state.ring_group_strategy,
        ring_group_timeout: this.state.ring_group_timeout,
        ring_group_timeout_entry: this.state.ring_group_timeout_entry,
        ring_group_enabled: this.state.ring_group_enabled,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        domain_name: sessionStorage.getItem("domain_name"),
        ring_group_call_timeout: this.state.ring_group_call_timeout,
        users: finalUser,
        timeOutEntryList: this.state.timeOutEntryList,
        ring_group_greeting: this.state.ring_group_greeting,
        userType: this.state.userType,
      };
      if (
        this.state.ring_group_timeout !== "1" &&
        this.state.ring_group_timeout_entry === "0"
      ) {
        this.setState({ alertMessage: <div>Please select entry.</div> });
      } else {
        let response = await commonPostAPI("updateRinggroups", requestparams);
        if (response.ring_group_name && response.ring_group_name !== "") {
          this.setState({ alertMessage: <div>Name already exists.</div> });
        } else {
          this.onOpenAction("ringgroup");
        }
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onChangeUserTypeList = async (role) => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let response = await commonPostAPI("userslist", {
      domain_uuid: domain_uuid,
      userType: role,
    });

    let obj = {
      user_name: ["user_1"],
      users: ["0"],
      userList: (response && response.length > 0) ? response : [],
    };
    this.setState(obj);
  }

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    if (e.target.name === "ring_group_timeout") {
      this.timeOutActionChange(e);
    }

    if (e.target.name === "userType") {
      this.onChangeUserTypeList(e.target.value);
    }

    let errors = this.state.errors;
    switch (e.target.name) {
      case "ring_group_name":
        errors.ring_group_name =
          e.target.value.length < 1 ? "Please enter name." : "";
        break;
      case "ring_group_strategy":
        errors.ring_group_strategy =
          e.target.value.length < 1 ? "Please select strategy." : "";
        break;
      case "ring_group_enabled":
        errors.ring_group_enabled =
          e.target.value.length < 1 ? "Please select Enabled." : "";
        break;
      case "ring_group_timeout":
        errors.ring_group_timeout =
          e.target.value.length < 1 ? "Please select Timeout Action." : "";
        break;
      case "ring_group_timeout_entry":
        errors.ring_group_timeout_entry =
          e.target.value.length < 1
            ? "Please select timeout action entry."
            : "";
        break;
      case "ring_group_call_timeout":
        errors.ring_group_call_timeout =
          e.target.value.length < 1
            ? "Please enter timeout."
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  getDataByID = async (id) => {
    let response = await commonPostAPI("getRecordRinggroupsByID", { id: id });
    let errors = this.state.errors;
    Object.keys(response).forEach(function (key) {
      switch (key) {
        case "ring_group_name":
          errors.ring_group_name =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please enter name.";
          break;
        case "ring_group_strategy":
          errors.ring_group_strategy =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select strategy.";
          break;
        case "ring_group_enabled":
          errors.ring_group_enabled =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select Enabled.";
          break;
        case "timeout_action_type":
          errors.ring_group_timeout =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select Timeout Action";
          break;
        case "timeout_action_entry":
          errors.ring_group_timeout_entry =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select action entry.";
          break;
        case "ring_group_call_timeout":
          errors.ring_group_call_timeout =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please enter timeout.";
          break;
        default:
          break;
      }
    });
    if (response.timeout_action_type !== "1") {
      const domain_uuid = sessionStorage.getItem("domain_uuid");
      const domain_name = sessionStorage.getItem("domain_name");
      let responseList = await commonPostAPI("callaction", {
        type: response.timeout_action_type,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({ timeOutEntryList: responseList });
    }

    let usersCurrent = response.users.split(":");
    let index = 0;
    let user_nameCurrent = response.users.split(":").map((item) => {
      index++;
      return `user_${index}`;
    });

    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let response1 = await commonPostAPI("userslist", {
      domain_uuid: domain_uuid,
      userType: response.add_role,
    });

    var dbData = {
      ring_group_name: response.ring_group_name,
      ring_group_strategy: response.ring_group_strategy,
      ring_group_timeout: response.timeout_action_type,
      ring_group_timeout_entry: response.timeout_action_entry,
      ring_group_enabled: response.ring_group_enabled,
      users: usersCurrent,
      user_name: user_nameCurrent,
      ring_group_extension: response.ring_group_extension,
      ring_group_uuid: response.ring_group_uuid,
      ring_group_call_timeout: response.ring_group_call_timeout,
      userType: response.add_role,
      userList: response1,
      ring_group_greeting:
        response.ring_group_greeting !== "" &&
          response.ring_group_greeting !== null
          ? response.ring_group_greeting
          : "0",
    };


    partialState = dbData;

    this.setState(partialState);
  };

  onChangeUserOptionAction = async (i, e) => {
    let { users } = this.state;
    let oldUsers = [...users];
    oldUsers[i] = e.target.value;
    this.setState({
      users: oldUsers,
    });
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  addUserAction = () => {
    let { users, user_name } = this.state;
    let userRowCount = users.length;
    let userlabel = `user_${userRowCount}`;
    users.push("0");
    let newUsers = users;
    user_name.push(userlabel);
    let newUser_name = user_name;
    this.setState({
      user_name: newUser_name,
      users: newUsers,
    });
  };

  removeUserAction = (index) => {
    this.setState({
      users: this.state.users.filter(function (items, idx) {
        return idx !== index;
      }),
      user_name: this.state.user_name.filter(function (items, idx) {
        return idx !== index;
      }),
    });
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      ring_group_name,
      ring_group_strategy,
      ring_group_enabled,
      ring_group_timeout,
      alertMessage,
      user_name,
      users,
      userList,
      ring_group_timeout_entry,
      timeOutEntryList,
      ring_group_greeting,
      ring_group_greetingList,
      ring_group_call_timeout,
      userType,
    } = this.state;

    let userArray = [];
    let index = 0;

    for (var i = 0; i < users.length; i++) {
      index = index + 1;
      let currentIndex = i;
      userArray.push(
        <Grid container xs={12}>
          <Grid Item xs={12}>
            <table>
              <tr>
                <td>{index}</td>
                <td width={200}>
                  <Select
                    fullWidth
                    labelId={users[i]}
                    value={users[i]}
                    id={user_name[i]}
                    name={user_name[i]}
                    onChange={this.onChangeUserOptionAction.bind(this, i)}
                    size="small"
                    autoComplete="off"
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {userList.map((item) => {
                      return (
                        <MenuItem key={item.extension} value={item.username}>
                          {item.username}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </td>
                <td>
                  {i === 0 ? (
                    <Button
                      type="button"
                      fullWidth
                      className="btn btn-primary"
                      sx={{
                        mt: 0,
                        mb: 0,
                        height: "40px",
                        width: "40px",
                        minWidth: "40px",
                        fontSize: "22px",
                      }}
                      size="small"
                      onClick={() => this.addUserAction()}
                    >
                      +
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      fullWidth
                      className="btn btn-outline-white"
                      sx={{
                        mt: 0,
                        mb: 0,
                        height: "40px",
                        width: "40px",
                        minWidth: "40px",
                        fontSize: "22px",
                      }}
                      size="small"
                      onClick={() => this.removeUserAction(currentIndex)}
                    >
                      -
                    </Button>
                  )}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Name</label>
                      <TextField
                        margin="0"
                        required
                        fullWidth
                        id="ring_group_name"
                        name="ring_group_name"
                        value={ring_group_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="text"
                        size="small"
                      />
                      {errors.ring_group_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.ring_group_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Greeting
                      </label>
                      <Select
                        fullWidth
                        labelId="ring_group_greeting"
                        id="ring_group_greeting"
                        value={ring_group_greeting}
                        label="ring_group_greeting"
                        name="ring_group_greeting"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {ring_group_greetingList.map((item) => (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Enabled
                      </label>
                      <Select
                        fullWidth
                        labelId="ring_group_enabled"
                        id="ring_group_enabled"
                        value={ring_group_enabled}
                        name="ring_group_enabled"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="true">Active</MenuItem>
                        <MenuItem value="false">In Active</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Strategy
                      </label>
                      <Select
                        fullWidth
                        labelId="ring_group_strategy"
                        id="ring_group_strategy"
                        value={ring_group_strategy}
                        label="ring_group_strategy"
                        name="ring_group_strategy"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="simultaneous">Simultaneous</MenuItem>
                        <MenuItem value="sequence">Sequence</MenuItem>
                        <MenuItem value="rollover">Rollover</MenuItem>
                        <MenuItem value="random">Random</MenuItem>
                      </Select>

                      {errors.ring_group_strategy.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.ring_group_strategy}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>
                        Timeout Action
                      </label>
                      <Select
                        fullWidth
                        labelId="ring_group_timeout"
                        id="ring_group_timeout"
                        value={ring_group_timeout}
                        label="ring_group_timeout"
                        name="ring_group_timeout"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="1">End Call</MenuItem>
                        {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
                        <MenuItem value="3">User</MenuItem>
                        <MenuItem value="4">Ringgroup</MenuItem>
                        <MenuItem value="5">IVR Menu</MenuItem>
                        <MenuItem value="6">Callcenter Queue</MenuItem>
                        <MenuItem value="7">Recording</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Action Entry
                      </label>
                      <Select
                        fullWidth
                        labelId="ring_group_timeout_entry"
                        id="ring_group_timeout_entry"
                        value={ring_group_timeout_entry}
                        label="ring_group_timeout_entry"
                        name="ring_group_timeout_entry"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="off"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {timeOutEntryList.map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {errors.ring_group_timeout_entry.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.ring_group_timeout_entry}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item md={3}>
                      <label>Timeout</label>
                      <TextField
                        margin="0"
                        required
                        fullWidth
                        id="ring_group_call_timeout"
                        name="ring_group_call_timeout"
                        value={ring_group_call_timeout ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="off"
                        type="number"
                        size="small"
                      />
                      {errors.ring_group_call_timeout.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.ring_group_call_timeout}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>User Role</label>
                      <Select
                        fullWidth
                        labelId="userType"
                        id="userType"
                        value={userType}
                        label="userType"
                        name="userType"
                        onChange={this.onChangeItem}
                        size="small"
                        sx={{ mt: 1 }}
                        autoComplete="off"
                      >
                        <MenuItem value="1">Admin</MenuItem>
                        <MenuItem value="2">User</MenuItem>
                        <MenuItem value="3">Both</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <label>Users</label>
                      {userArray}
                    </Grid>
                  </Grid>
                  <Grid Item xs={4} sx={{ mt: 5 }}>
                    {alertMessage ? (
                      <Stack className="error-show" spacing={2}>
                        {alertMessage}
                      </Stack>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table>
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("ringgroup")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withRouter(RinggroupeditData);
